import "./Search.css";
import { React, useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";

function Search(props) {
    const [hideSearch, setHideSearch] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);

    useEffect(() => {
        if (!props.busca) {
            props.setProductsQuery(props.products);
            return;
        }

        clearTimeout(searchTimeout);

        setSearchTimeout(
            setTimeout(() => {
                let productsBusca = props.products.filter((e) => {
                    return (
                        e.sku
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .includes(
                                props.busca
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                            ) ||
                        e.name
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .includes(
                                props.busca
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                            )
                    );
                });

                props.setProductsQuery(productsBusca);
            }, 200)
        );
    }, [props.busca, props.products]);

    return (
        <div className={`search-bar ${props.hidden ? "hidden" : ""}`}>
            <div className={`align-center search-children`}>{props.children}</div>
            <input
                className="align-center"
                placeholder={window.innerWidth < 768 ? "Nome ou SKU do produto" : "Pesquise por nome ou sku do produto"}
                value={props.busca}
                onChange={(e) => {
                    props.setBusca(e.target.value);
                }}
                onFocus={() => setHideSearch(true)}
            />

            <div className={`${hideSearch ? "hidden" : ""}`}>
                <TypeAnimation
                    className={`align-center search-bar-placeholder`}
                    sequence={[1500, "Pesquise por nome ou sku do produto", 500, () => setHideSearch(true)]}
                    // wrapper="span"
                    speed={{ type: "keyStrokeDelayInMs", value: 30 }}
                />
            </div>

            <div className="order-overlay align-center">
                <div className={`order-successfull ${props.orderSuccesfull ? "show" : ""}`}>Pedido realizado com sucesso!</div>
            </div>
        </div>
    );
}

export default Search;
