import "./Ask.css";

import { useEffect, useState, useRef } from "react";

export default function AdminAsk(props) {
    const [orders, setOrders] = useState([]);
    const [updateTimeout, setUpdateTimeout] = useState(null);
    const [cancelNextUpdate, setCancelNextUpdate] = useState(false);
    const cancelNextUpdateRef = useRef("");

    const [photosModal, setPhotosModal] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [productModal, setProductModal] = useState(false);

    const fetchOrders = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(
                    "/api/orders/getproducts?status=1",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();
                if (!cancelNextUpdateRef.current) {
                    setOrders(data);
                }
                setCancelNextUpdate(false);
                return resolve("ok");
            } catch (error) {
                reject("error");
                console.error(error);
            }
        });
    };

    function autoUpdate() {
        clearTimeout(updateTimeout);
        setUpdateTimeout(
            setTimeout(() => {
                fetchOrders().then(autoUpdate).catch(autoUpdate);
            }, 10000)
        );
    }

    useEffect(() => {
        fetchOrders().then(autoUpdate).catch(autoUpdate);
        return () => clearTimeout(updateTimeout);
    }, []);

    useEffect(() => {
        cancelNextUpdateRef.current = cancelNextUpdate;
    }, [cancelNextUpdate]);

    async function setProductStatus(status, product) {
        try {
            setCancelNextUpdate(true);
            await fetch("/api/orders/updateproduct", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: product, data: { status: status } }),
            });
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <div
                className={`asked-product-photos-bg ${
                    photosModal ? "shown" : ""
                }`}
                onClick={() => {
                    setPhotosModal(false);
                    setTimeout(() => setProductModal(null), 200);
                }}
            >
                <div
                    className="asked-product-photos"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <img
                        className="asked-product-modal-photo"
                        src={
                            productModal
                                ? `/uploads/products/${productModal.product.photos[photoIndex]}`
                                : ""
                        }
                    />
                    {productModal && photoIndex !== 0 ? (
                        <div
                            className="asked-photo-previous"
                            onClick={() =>
                                setPhotoIndex((photoIndex) => photoIndex - 1)
                            }
                        ></div>
                    ) : (
                        ""
                    )}
                    {productModal &&
                    photoIndex !== productModal.product.photos.length - 1 ? (
                        <div
                            className="asked-photo-next"
                            onClick={() =>
                                setPhotoIndex((photoIndex) => photoIndex + 1)
                            }
                        ></div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="asked-products-container">
                Produtos solicitados pro 2º andar
                <div className="asked-products">
                    {orders.map((e, i) => {
                        return (
                            <div className="asked-product">
                                <img
                                    src={`/uploads/thumbs/${e.product.photos[0]}`}
                                    alt={e.sku}
                                    onClick={() => {
                                        setProductModal(e);
                                        setPhotosModal(true);
                                        setPhotoIndex(0);
                                    }}
                                />
                                <div className="asked-product-sku">
                                    {e.product.sku}
                                </div>
                                <div className="asked-product-order asked">
                                    {e.orderId.toString().padStart(6, 0)}
                                </div>
                                <div
                                    className="asked-product-confirm"
                                    onClick={() => {
                                        setProductStatus(4, e.id);
                                        let newOrders = orders.slice();
                                        newOrders.splice(i, 1);
                                        setOrders(newOrders);
                                    }}
                                >
                                    Confirmar
                                </div>
                                <div
                                    className="asked-product-confirm burn"
                                    onClick={() => {
                                        setProductStatus(3, e.id);
                                        let newOrders = orders.slice();
                                        newOrders.splice(i, 1);
                                        setOrders(newOrders);
                                    }}
                                >
                                    Solicitar gravação
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
