import React, { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "../../../components/Loader/Loader";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";

import "./Users.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function AdminUsers() {
    const { adminUsers, adminGroups, adminProducts } = useContext(UserContext);

    const [users, setUsers] = useState([]);

    const [modalAdd, setModalAdd] = useState(0);
    const [modalAddLoading, setModalAddLoading] = useState(false);

    const [modalConfirm, setModalConfirm] = useState(false);

    const [modalProduct, setModalProduct] = useState({});

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [search, setSearch] = useState("");

    const [modalWhatsapp, setModalWhatsapp] = useState(false);
    const [modalAistem, setModalAistem] = useState(false);

    const [modalCredits, setModalCredits] = useState(false);

    const [creditsConfirm, setCreditsConfirm] = useState(false);

    const [creditsAdd, setCreditsAdd] = useState(0);
    const [creditsReason, setCreditsReason] = useState("");

    const [messageLoading, setMessageLoading] = useState(false);

    const [message, setMessage] = useState("");
    const [links, setLinks] = useState([]);

    const handleWhatsapp = async () => {
        if (!message) return alert("Preencha a mensagem");

        setMessageLoading(true);

        await fetch("/api/users/sendwhatsapp", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                users: selectedUsers,
                message: message,
            }),
        });

        setMessageLoading(false);

        setMessage("");
        setModalWhatsapp(false);
        alert("Mensagem enviada com sucesso!");
    };

    const handleAistem = async () => {
        if (!message) return alert("Preencha a mensagem");

        if (links.some((link) => !link.text || !link.url)) return alert("Complete todos os links ou remova-os!");

        setMessageLoading(true);

        await fetch("/api/gpt/sendaistem", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                users: selectedUsers,
                message: message,
                links: links,
            }),
        });

        setMessageLoading(false);

        setMessage("");
        setLinks([]);
        setModalAistem(false);
        alert("Mensagem enviada com sucesso!");
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();

        if (!modalProduct.name) return alert("Preencha o nome");
        if (!modalProduct.email) return alert("Preencha o email");
        if (!modalProduct.credits && modalProduct.credits !== 0) return alert("Preencha um um valor de créditos válido");

        setModalAddLoading(true);

        let url = "/api/users/add";

        if (modalAdd == 2) {
            url = "/api/users/edit";
        }

        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify(modalProduct),
            });

            const data = await response.json();

            if (response.status === 500) {
                alert(data.message);
                setModalAddLoading(false);
                return;
            }

            setModalAddLoading(false);
            setModalProduct({});
            setModalAdd(0);
            // fetchUsers();
        } catch (error) {
            setModalAddLoading(false);
            alert(error.message);
            console.error(error);
        }
    };

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        // const fetchGroups = async () => {
        //     try {
        //         const response = await fetch("/api/products/getgroups", {
        //             method: "GET",
        //         });
        //         const data = await response.json();
        //         setGroups(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchGroups();
        setGroups(adminGroups);
    }, [adminGroups]);

    // const fetchUsers = async () => {
    //     try {
    //         const response = await fetch("/api/users/users", {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "Bearer " + Cookies.get("jwt"),
    //             },
    //         });
    //         const data = await response.json();
    //         setUsers(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    useEffect(() => {
        // fetchUsers();
        if (search) {
            let users = adminUsers;
            let src = search.toLowerCase();
            users = users.filter(
                (u) =>
                    u?.email?.toLowerCase()?.includes(src) ||
                    u?.name?.toLowerCase()?.includes(src) ||
                    groups
                        .find((g) => g.id == u.group)
                        ?.name?.toLowerCase()
                        ?.includes(src)
            );
            setUsers(users);
        } else setUsers(adminUsers);
    }, [adminUsers, search]);

    return (
        <>
            <div className={`admin-order-search admin-user-search`}>
                <div className="admin-order-search-container">
                    <div className={`admin-order-selected-btns ${selectedUsers.length ? "shown" : ""}`}>
                        <div className={`admin-selected-orders`}>
                            <div>
                                <strong>{selectedUsers.length}</strong> Selec.
                            </div>
                        </div>
                        <div
                            className={`admin-selected-btn`}
                            onClick={() => {
                                setModalWhatsapp(true);
                            }}
                        >
                            Enviar <br />
                            Whatsapp
                        </div>

                        <div
                            className={`admin-selected-btn`}
                            onClick={() => {
                                setModalAistem(true);
                            }}
                        >
                            Enviar <br />
                            AI-stem
                        </div>
                    </div>
                    <div className="admin-order-search-input">
                        <span
                            className="admin-order-check"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                let newSelUsers = [...selectedUsers];
                                if (users.every((u) => selectedUsers.includes(u.id))) newSelUsers = [];
                                else newSelUsers = users.map((u) => u.id);
                                setSelectedUsers(newSelUsers);
                            }}
                        >
                            <div
                                className={`admin-order-checked ${
                                    users.every((u) => selectedUsers.includes(u.id)) ? "checked" : ""
                                }`}
                            ></div>
                        </span>
                        <input
                            placeholder="Pesquise por nome ou e-mail"
                            value={search}
                            onChange={(ev) => {
                                setSearch(ev.target.value);
                                // setOnlySelected(false);
                            }}
                        />
                        <div className="admin-search-qtty">
                            <div>{users.length} Usuários</div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`admin-modal-bg ${modalCredits ? "shown" : ""}`}
                onClick={() => {
                    setModalProduct({});
                    setMessageLoading(false);
                    setCreditsConfirm(false);
                    setCreditsAdd(0);
                    setCreditsReason("");
                    setModalCredits(false);
                }}
            >
                <div className={`admin-confirm-modal admin-credits-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className={`admin-credits-confirm ${creditsConfirm ? "shown" : ""}`}>
                        <div>
                            Confirma a adição de <strong>R$ {creditsAdd}</strong>?<br />
                            Essa ação será irreversível.
                        </div>
                        <div>
                            <br />
                            Saldo atual: <strong>R$ {modalProduct.credits}</strong>
                            <br />
                            Saldo após: <strong>R$ {Number(modalProduct.credits) + Number(creditsAdd)}</strong>
                        </div>
                        <div className="admin-credits-confirm-buttons">
                            <div className="admin-credits-button" onClick={() => setCreditsConfirm(false)}>
                                Não
                            </div>
                            <div
                                className="admin-credits-button yes"
                                onClick={async () => {
                                    setCreditsConfirm(false);
                                    setMessageLoading(true);
                                    try {
                                        await fetch("/api/users/addcredits", {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify({
                                                userId: modalProduct.id,
                                                value: creditsAdd,
                                                reason: creditsReason,
                                            }),
                                        });
                                        // await fetchUsers();
                                        setModalCredits(false);
                                        setMessageLoading(false);
                                        setCreditsAdd(0);
                                        setCreditsReason("");
                                    } catch (err) {
                                        alert("Ocorreu um erro de conexão, por favor tente novamente em instantes.");
                                        setMessageLoading(false);
                                    }
                                }}
                            >
                                Sim
                            </div>
                        </div>
                    </div>
                    <div className={`admin-whatsapp-loading ${messageLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>
                    <div className="admin-add-credits-title">
                        Adicionando saldo para <div className="admin-add-credits-email">{modalProduct.email}</div>
                    </div>
                    <div className="admin-add-credits-title">Saldo atual: R$ {modalProduct.credits}</div>
                    <label>
                        Valor a adicionar/estornar
                        <input
                            value={"R$ " + creditsAdd}
                            onChange={(ev) => {
                                setCreditsAdd(ev.target.value.replace(/\D/g, ""));
                            }}
                        />
                    </label>
                    <label>
                        Motivo (aparecerá no extrato do usuário)
                        <input
                            placeholder="Motivo do estorno/adição"
                            value={creditsReason}
                            onChange={(ev) => {
                                setCreditsReason(ev.target.value);
                            }}
                        />
                    </label>
                    <div
                        className="admin-whatsapp-send"
                        onClick={() => {
                            if (creditsAdd <= 0) {
                                return alert("O valor de saldo deve ser maior do que R$ 0 !!");
                            }

                            if (!creditsReason) {
                                return alert("O motivo não pode estar em branco! Escreva a razão dessa adição de saldo.");
                            }
                            setCreditsConfirm(true);
                        }}
                    >
                        Adicionar
                    </div>
                </div>
            </div>
            <div className={`admin-modal-bg ${modalWhatsapp ? "shown" : ""}`} onClick={() => setModalWhatsapp(false)}>
                <div className={`admin-confirm-modal admin-whatsapp-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className={`admin-whatsapp-loading ${messageLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>
                    <div className="admin-whatsapp">
                        Enviar Whatsapp para {selectedUsers.length} usuários
                        <textarea className="whatsapp-input" value={message} onChange={(ev) => setMessage(ev.target.value)} />
                    </div>
                    <div className="admin-whatsapp-send" onClick={handleWhatsapp}>
                        Enviar
                    </div>
                </div>
            </div>
            <div className={`admin-modal-bg ${modalAistem ? "shown" : ""}`} onClick={() => setModalAistem(false)}>
                <div className={`admin-confirm-modal admin-whatsapp-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className={`admin-whatsapp-loading ${messageLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>
                    <div className="admin-whatsapp">
                        Enviar mensagem AI-stem para {selectedUsers.length} usuários
                        <textarea className="whatsapp-input" value={message} onChange={(ev) => setMessage(ev.target.value)} />
                        <div
                            className="add-aistem-addlink"
                            onClick={() => {
                                if (links.length > 2) return;
                                setLinks([...links, { text: "", url: "" }]);
                            }}
                        >
                            + Adicionar Link
                        </div>
                        <div className="admin-aistem-links">
                            {links.map((link, i) => {
                                return (
                                    <div className="admin-aistem-link" key={i}>
                                        <label>
                                            Título do botão
                                            <input
                                                placeholder="Título do botão"
                                                value={link.text}
                                                onChange={(ev) => {
                                                    let newLinks = [...links];
                                                    newLinks[i].text = ev.target.value;
                                                    setLinks(newLinks);
                                                }}
                                            />
                                        </label>
                                        <label>
                                            URL
                                            <input
                                                placeholder="Não esquecer o https:// no início!!"
                                                value={link.url}
                                                onChange={(ev) => {
                                                    let newLinks = [...links];
                                                    newLinks[i].url = ev.target.value;
                                                    setLinks(newLinks);
                                                }}
                                            />
                                        </label>
                                        <span
                                            class="trash"
                                            onClick={() => {
                                                let newLinks = [...links];
                                                newLinks.splice(i, 1);
                                                setLinks(newLinks);
                                            }}
                                        >
                                            <img src="/img/tampalixeira.svg" />
                                            <img src="/img/lixeira.svg" />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="admin-whatsapp-send" onClick={handleAistem}>
                        Enviar
                    </div>
                </div>
            </div>
            <div className={`admin-modal-bg ${modalConfirm ? "shown" : ""}`} onClick={() => setModalConfirm(false)}>
                <div className="admin-confirm-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="admin-modal-title">Tem certeza que deseja banir {modalProduct.email}?</div>
                    <div className="admin-modal-confirm-btns">
                        <div className="admin-modal-confirm-btn" onClick={() => setModalConfirm(false)}>
                            Não
                        </div>
                        <div
                            className="admin-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                fetch("/api/users/ban", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        id: modalProduct.id,
                                    }),
                                })
                                    .then(() => {
                                        setUsers(users.filter((e) => e.id !== modalProduct.id));
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                                setModalConfirm(false);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div className={`admin-modal-bg ${modalAdd != 0 ? "shown" : ""}`} onMouseDown={() => setModalAdd(false)}>
                {modalAddLoading ? (
                    <div className="admin-modal-loading">
                        <Loader />
                    </div>
                ) : (
                    ""
                )}
                <div className="admin-add-modal" onMouseDown={(ev) => ev.stopPropagation()}>
                    {modalProduct && modalAdd != 0 ? (
                        <>
                            <form className="admin-user-form" onSubmit={handleSubmit} id="add-form">
                                <div className="admin-user-section">
                                    <div
                                        className={`admin-user-btn admin-ban-btn`}
                                        onClick={() => {
                                            // setModalProduct(user);
                                            setModalConfirm(true);
                                        }}
                                    >
                                        Banir
                                    </div>
                                    <div className="admin-user-title">Dados pessoais</div>
                                    <label>
                                        Nome
                                        <input
                                            type="text"
                                            placeholder="Nome do usuário"
                                            value={modalProduct.name}
                                            onChange={(e) =>
                                                setModalProduct({
                                                    ...modalProduct,
                                                    name: e.target.value,
                                                })
                                            }
                                            // maxLength={100}
                                            // disabled={modalAdd === 2}
                                        />
                                    </label>
                                    <label>
                                        E-mail
                                        <input
                                            type="text"
                                            placeholder="E-mail do usuário"
                                            value={modalProduct.email}
                                            className="product-name"
                                            onChange={(e) =>
                                                setModalProduct({
                                                    ...modalProduct,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </label>
                                </div>

                                <div className="admin-user-section">
                                    <div className="admin-user-title">Grupo</div>
                                    <div className="admin-user-groups">
                                        <div
                                            className={`admin-user-group ${modalProduct.group ? "" : "selected"}`}
                                            onClick={() =>
                                                setModalProduct({
                                                    ...modalProduct,
                                                    group: null,
                                                })
                                            }
                                        >
                                            Nenhum
                                        </div>
                                        {groups.map((group) => {
                                            return (
                                                <div
                                                    className={`admin-user-group ${
                                                        modalProduct.group === group.id ? "selected" : ""
                                                    }`}
                                                    onClick={() =>
                                                        setModalProduct({
                                                            ...modalProduct,
                                                            group: group.id,
                                                        })
                                                    }
                                                >
                                                    {group.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="admin-user-section">
                                    <div className="admin-user-title">Whitelist Produtos</div>
                                    <div className="admin-whitelist">
                                        {(modalProduct.whitelist || []).map((prod) => {
                                            let product = adminProducts.find((p) => p.id === prod);
                                            return (
                                                <div
                                                    className="admin-whitelist-product"
                                                    onClick={() => {
                                                        setModalProduct({
                                                            ...modalProduct,
                                                            whitelist: modalProduct.whitelist.filter((e) => e !== prod),
                                                        });
                                                    }}
                                                >
                                                    <img src={"/uploads/thumbs/" + product?.photos[0]} />
                                                    <div className="admin-whitelist-sku">{product.sku}</div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="admin-user-groups">
                                        <ReactTags
                                            classNames={{
                                                // tags: "admin-product-tags",
                                                tagInputField: "admin-product-tags-input",
                                                selected: "admin-tag-container",
                                                tag: "admin-product-tag",
                                                remove: "admin-tag-remove",
                                            }}
                                            suggestions={adminProducts.map((prod) => {
                                                return {
                                                    id: prod.sku,
                                                    text: prod.sku,
                                                    _id: prod.id,
                                                };
                                            })}
                                            tags={modalProduct.tags || []}
                                            delimiters={[188, 13]}
                                            handleDelete={(i) => {}}
                                            handleAddition={(tag) => {
                                                // alert(tag._id);

                                                if (
                                                    !tag._id ||
                                                    !adminProducts.find((e) => e.id === tag._id) ||
                                                    (modalProduct.whitelist && modalProduct.whitelist.includes(tag._id))
                                                )
                                                    return;

                                                setModalProduct({
                                                    ...modalProduct,
                                                    whitelist: [...(modalProduct.whitelist || []), tag._id],
                                                });
                                            }}
                                            handleDrag={(tag, currPos, newPos) => {}}
                                            inputFieldPosition="inline"
                                            placeholder="Pesquisar por sku"
                                            autofocus={false}
                                        />
                                    </div>
                                </div>

                                <div className="admin-user-section">
                                    <div className="admin-user-title">Blacklist Produtos</div>
                                    <div className="admin-whitelist">
                                        {(modalProduct.blacklist || []).map((prod) => {
                                            let product = adminProducts.find((p) => p.id === prod);
                                            return (
                                                <div
                                                    className="admin-whitelist-product"
                                                    onClick={() => {
                                                        setModalProduct({
                                                            ...modalProduct,
                                                            blacklist: modalProduct.blacklist.filter((e) => e !== prod),
                                                        });
                                                    }}
                                                >
                                                    <img src={"/uploads/thumbs/" + product?.photos[0]} />
                                                    <div className="admin-whitelist-sku">{product.sku}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="admin-user-groups">
                                        <ReactTags
                                            classNames={{
                                                // tags: "admin-product-tags",
                                                tagInputField: "admin-product-tags-input",
                                                selected: "admin-tag-container",
                                                tag: "admin-product-tag",
                                                remove: "admin-tag-remove",
                                            }}
                                            suggestions={adminProducts.map((prod) => {
                                                return {
                                                    id: prod.sku,
                                                    text: prod.sku,
                                                    _id: prod.id,
                                                };
                                            })}
                                            tags={modalProduct.tags || []}
                                            delimiters={[188, 13]}
                                            handleDelete={(i) => {}}
                                            handleAddition={(tag) => {
                                                // alert(tag._id);

                                                if (
                                                    !tag._id ||
                                                    !adminProducts.find((e) => e.id === tag._id) ||
                                                    (modalProduct.blacklist && modalProduct.blacklist.includes(tag._id))
                                                )
                                                    return;

                                                setModalProduct({
                                                    ...modalProduct,
                                                    blacklist: [...(modalProduct.blacklist || []), tag._id],
                                                });
                                            }}
                                            handleDrag={(tag, currPos, newPos) => {}}
                                            inputFieldPosition="inline"
                                            placeholder="Pesquisar por sku"
                                            autofocus={false}
                                        />
                                    </div>
                                </div>

                                {/* <div
                                    className="product-data-section"
                                    style={{ flexBasis: 1 }}
                                >
                                    <div className="product-data-title">
                                        Estoque
                                    </div>
                                    <input
                                        type="number"
                                        step="1"
                                        placeholder="Estoque"
                                        value={modalProduct.stock}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                stock: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="product-data-section description-section">
                                    <div className="product-data-title">
                                        Descrição
                                    </div>
                                    <textarea
                                        placeholder="Descrição"
                                        value={modalProduct.description}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                description: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div> */}
                            </form>
                            <input
                                className="product-save-btn"
                                type="submit"
                                value={modalAdd == 1 ? "Criar usuário" : "Salvar alterações"}
                                form="add-form"
                            />
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="admin-users-container">
                <div className="admin-users-list">
                    {users.map((user) => (
                        <div
                            className="admin-user"
                            key={user.id}
                            onClick={() => {
                                setModalProduct(user);
                                setModalAdd(2);
                            }}
                        >
                            <span
                                className={`admin-user-new ${
                                    new Date(user.createdAt).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7 ? "shown" : ""
                                }`}
                            >
                                Novo!
                            </span>
                            <span
                                className="admin-order-check"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    const newSelUsers = [...selectedUsers];
                                    if (selectedUsers.includes(user.id)) newSelUsers.splice(newSelUsers.indexOf(user.id), 1);
                                    else newSelUsers.push(user.id);
                                    setSelectedUsers(newSelUsers);
                                }}
                            >
                                <div className={`admin-order-checked ${selectedUsers.includes(user.id) ? "checked" : ""}`}></div>
                            </span>
                            <div className="admin-user-name">{user.name}</div>
                            <CopyDiv className="admin-user-email" value={user.email}>
                                {user.email}
                            </CopyDiv>
                            <div className="admin-user-group-name">{groups.find((g) => g.id == user.group)?.name}</div>
                            <div
                                className="admin-user-credits"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    setModalProduct(user);
                                    setModalCredits(true);
                                }}
                            >
                                Saldo
                                <span className="admin-user-credits-value">{user.credits}</span>
                            </div>

                            <div className="admin-user-signed">
                                Cadastro em
                                <span className="admin-signed-date">{new Date(user.createdAt).toLocaleDateString()}</span>
                            </div>

                            {/* <div
                                className="admin-user-btn"
                                style={{ color: "#5555ff" }}
                            >
                                Conta
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
