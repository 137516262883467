import React, { useEffect, useRef, useState } from "react";
import "./RecentOrders.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import Loader from "../../../components/Loader/Loader.js";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";

import { useReactToPrint } from "react-to-print";

import marketplaces from "../../../config/marketplaces.json";

let theTimeout = null;

export default function RecentMaterials() {
    const { user, adminOrders, adminProducts, adminBoxes, adminGroups, adminUsers, adminMaterials } = useContext(UserContext);

    const [products, setProducts] = useState([]);
    const [hideInfo, setHideInfo] = useState(false);

    const [selected, setSelected] = useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (selected) {
            clearTimeout(theTimeout);
            theTimeout = setTimeout(() => {
                fetch("/api/users/setstockdata", {
                    method: "POST",
                    body: JSON.stringify(selected),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            }, 500);
        } else {
            setSelected(user?.stockData || {});
        }
    }, [selected]);

    useEffect(() => {
        if (selected) {
            setSelected(user?.stockData);
        }
    }, [user]);

    function getSortMethod() {
        var _args = Array.prototype.slice.call(arguments);
        return function (a, b) {
            for (var x in _args) {
                var ax = a[_args[x].substring(1)];
                var bx = b[_args[x].substring(1)];
                var cx;

                ax = typeof ax == "string" ? ax.toLowerCase() : ax / 1;
                bx = typeof bx == "string" ? bx.toLowerCase() : bx / 1;

                if (_args[x].substring(0, 1) == "-") {
                    cx = ax;
                    ax = bx;
                    bx = cx;
                }
                if (ax != bx) {
                    return ax < bx ? -1 : 1;
                }
            }
        };
    }

    useEffect(() => {
        if (!adminOrders.length || !adminMaterials) return;
        if (products.length) return;
        const newOrders = adminOrders.filter((order) => {
            return (
                order.marketplace !== 82 &&
                [0, 1, 5].includes(order.status) &&
                new Date(order.createdAt).getTime() > Date.now() - 10 * 24 * 60 * 60 * 1000
            );
        });

        // const orderIds = adminOrders.map((o) => o.userId);
        // const filteredUsers = adminUsers.filter((u) => orderIds.includes(u.id));

        console.log(adminProducts);

        let newProducts = adminMaterials.map((e) => {
            let productsHave = adminProducts.filter((p) => {
                return p.materials?.includes(e.id);
            });

            return {
                ...e,
                productsHave: productsHave,
                stockReady: productsHave.reduce((prev, curr) => {
                    return prev + curr?.stockReady;
                }, 0),
                recentOrders: newOrders.reduce(
                    (prev, curr) =>
                        prev + curr.order_products.filter((o) => productsHave.find((p) => o.productId === p.id)).length,
                    0
                ),
                allOrders: adminOrders.reduce(
                    (prev, curr) =>
                        prev + curr.order_products.filter((o) => productsHave.find((p) => o.productId === p.id)).length,
                    0
                ),
                twoMonthOrders: adminOrders
                    .filter((order) => {
                        return (
                            order.marketplace !== 82 &&
                            [0, 1, 5].includes(order.status) &&
                            new Date(order.createdAt).getTime() > Date.now() - 60 * 24 * 60 * 60 * 1000
                        );
                    })
                    ?.reduce(
                        (prev, curr) =>
                            prev + curr.order_products.filter((o) => productsHave.find((p) => o.productId === p.id)).length,
                        0
                    ),
                // topSellers: [],
            };
        });

        newProducts.sort(getSortMethod("-recentOrders"));

        setProducts(newProducts);
    }, [adminOrders, adminProducts, adminUsers]);

    return (
        <>
            {Object.keys(selected || {})?.length ? (
                <div className="report-print-btn" onClick={handlePrint}>
                    Imprimir relatório
                </div>
            ) : (
                ""
            )}
            <div className="admin-material-report" ref={componentRef}>
                <img src="/img/navbar/mini-logo.svg" className="report-logo" />
                <div className="admin-material-title">Relatório de Compra de Materiais</div>
                <div className="admin-material-title">{new Date().toLocaleString("pt-BR")}</div>
                <div className="admin-material-report-container">
                    {Object.entries(selected || {}).map(([materialId, qtty]) => {
                        const material = products?.find((m) => m.id == materialId);
                        if (
                            material?.recentOrders -
                                Number(qtty?.stock ? material?.stockReady || 0 : 0) -
                                Number(qtty?.stock || 0) +
                                Number(qtty?.add || 0) <
                            1
                        )
                            return;
                        return (
                            <div className="material-report-item">
                                <img
                                    className="material-report-photo"
                                    src={`https://orizom.com.br/uploads/internal/${material?.photo}`}
                                />
                                <div className="material-report-sku">{material?.name}</div>
                                <div className="material-report-text">{material?.text}</div>

                                <div className="material-report-qtty">
                                    QTDE:{" "}
                                    <span>
                                        {(qtty?.stock !== undefined && qtty?.stock !== ""
                                            ? material?.recentOrders - material?.stockReady
                                            : 0) -
                                            Number(qtty?.stock || 0) +
                                            Number(qtty?.add || 0)}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={`admin-recentorders ${hideInfo ? "hide-info" : ""}`}>
                <div className={`recent-order-loading ${adminOrders.length ? "" : "shown"}`}>
                    <Loader />
                </div>
                {products.map((product, i) => {
                    // console.log("rerender");
                    const timeinmilisec = new Date(product.createdAt).getTime() - Date.now();
                    const days = Math.round((timeinmilisec / (1000 * 60 * 60 * 24)) * -1);

                    return (
                        <div
                            className={`admin-recentorder ${
                                product.twoMonthOrders === 0 && days > 60 && product.stock > 0 ? "blink" : ""
                            }`}
                            key={product.id}
                        >
                            <div className="recentorder-number">{i + 1}</div>
                            <div className="admin-recentorder-image">
                                <img
                                    className="report-img"
                                    src={"https://orizom.com.br/uploads/internal/" + product.photo}
                                    alt=""
                                />
                                {/* <div>
                                    
                                    {product?.productsHave?.map((ae) => {
                                        return (
                                            <div>
                                                {ae?.sku}
                                                <br />
                                                <br />
                                            </div>
                                        );
                                    })}
                                </div> */}
                                <CopyDiv className="recentorder-sku" value={product.name}>
                                    {product.name}
                                </CopyDiv>
                                <img
                                    className="recent-expanded recent-material"
                                    src={"https://orizom.com.br/uploads/internal/" + product.photo}
                                    alt=""
                                />
                            </div>

                            <div className="admin-recentorder-orders">
                                Prontos<div>(Starbox)</div>
                                <div className="admin-recentorder-value">{product.stockReady}</div>
                            </div>

                            <div className="admin-recentorder-orders">
                                Vnd. (10d)
                                <div className="admin-recentorder-value">{product.recentOrders}</div>
                            </div>

                            <div className="admin-recentorder-orders" style={{ color: "#c7a600" }}>
                                Vnd. (60d)
                                <div className="admin-recentorder-value">{product.twoMonthOrders}</div>
                            </div>

                            <div className="admin-recentorder-orders" style={{ color: "#1567d2" }}>
                                Vnd. total
                                <div className="admin-recentorder-value">{product.allOrders}</div>
                            </div>

                            <div className={`admin-recentorder-orders`}>
                                Estoque <div>atual</div>
                                <div className="admin-recentorder-value">{product.stock}</div>
                                <input
                                    type="number"
                                    value={selected[product.id]?.stock || ""}
                                    onChange={(ev) => {
                                        // alert(ev.target.value);

                                        if (ev.target.value === "" && !selected[product.id]?.add) {
                                            let newSelected = Object.fromEntries(Object.entries(selected || {}));
                                            delete newSelected[product.id];
                                            setSelected(newSelected);
                                            return;
                                        }

                                        if (ev.target.value >= 0)
                                            setSelected({
                                                ...selected,
                                                [product.id]: { ...(selected[product.id] || {}), stock: ev.target.value },
                                            });
                                    }}
                                />
                            </div>
                            <div className={`admin-recentorder-orders`}>
                                Adicionar <div>(opcional)</div>
                                <div className="admin-recentorder-value">{product.stock}</div>
                                <input
                                    type="number"
                                    value={selected[product.id]?.add || ""}
                                    onChange={(ev) => {
                                        // alert(ev.target.value);

                                        if (ev.target.value === "" && !selected[product.id]?.stock) {
                                            let newSelected = Object.fromEntries(Object.entries(selected || {}));
                                            delete newSelected[product.id];
                                            setSelected(newSelected);
                                            return;
                                        }

                                        if (ev.target.value >= 0)
                                            setSelected({
                                                ...selected,
                                                [product.id]: { ...(selected[product.id] || {}), add: ev.target.value },
                                            });
                                    }}
                                />
                            </div>
                            {selected[product.id] ? (
                                <div className="admin-recentorder-orders" style={{ color: "#1567d2" }}>
                                    Total a<div>comprar</div>
                                    <div className="admin-recentorder-value">
                                        {Math.max(
                                            (selected[product.id]?.stock !== undefined && selected[product.id]?.stock !== ""
                                                ? product?.recentOrders - product?.stockReady
                                                : 0) -
                                                Number(selected[product.id]?.stock || 0) +
                                                Number(selected[product.id]?.add || 0),

                                            0
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
