import './CopyDiv.css';
import { useState } from 'react';

function CopyDiv(props) {
    const [blink, setBlink] = useState(false);
    return(<div style={props.style} className={`copydiv ${blink ? 'copied' : ''} ${props.className || ''}`} onClick={(ev) => {
      ev.stopPropagation();
      if (props.value) navigator.clipboard.writeText(props.value);
      setBlink(true);
      setTimeout(() => setBlink(false), 1000);
    }}> 
      {props.children}
    </div>
    );
  }

export default CopyDiv;