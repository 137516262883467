import "../../../components/AIstemChat/AIstemChat.css";
import "../../Admin/Users/Users.css";

import { useEffect, useState, useRef } from "react";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import Einstein from "../../../components/Einstein/Einstein";
import Loader from "../../../components/Loader/Loader.js";

export default function AistemMessages(props) {
    const { messages, adminUsers } = useContext(UserContext);

    const [message, setMessage] = useState("");
    const [links, setLinks] = useState([]);
    const [messageLoading, setMessageLoading] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    const [modalAistem, setModalAistem] = useState(false);

    const handleAistem = async () => {
        if (!message) return alert("Preencha a mensagem");

        if (links.some((link) => !link.text || !link.url)) return alert("Complete todos os links ou remova-os!");

        setMessageLoading(true);

        await fetch("/api/users/sendaistem", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                users: [selectedUser],
                message: message,
                links: links,
            }),
        });

        setMessageLoading(false);

        setMessage("");
        setLinks([]);
        setModalAistem(false);
        alert("Mensagem enviada com sucesso!");
    };

    return (
        <>
            <div className={`admin-modal-bg ${modalAistem ? "shown" : ""}`} onMouseDown={() => setModalAistem(false)}>
                <div className={`admin-confirm-modal admin-whatsapp-modal`} onMouseDown={(ev) => ev.stopPropagation()}>
                    <div className={`admin-whatsapp-loading ${messageLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>
                    <div className="admin-whatsapp">
                        Enviar mensagem AI-stem para {adminUsers.find((u) => u.id === selectedUser)?.email}
                        <textarea className="whatsapp-input" value={message} onChange={(ev) => setMessage(ev.target.value)} />
                        <div
                            className="add-aistem-addlink"
                            onClick={() => {
                                if (links.length > 2) return;
                                setLinks([...links, { text: "", url: "" }]);
                            }}
                        >
                            + Adicionar Link
                        </div>
                        <div className="admin-aistem-links">
                            {links.map((link, i) => {
                                return (
                                    <div className="admin-aistem-link" key={i}>
                                        <label>
                                            Título do botão
                                            <input
                                                placeholder="Título do botão"
                                                value={link.text}
                                                onChange={(ev) => {
                                                    let newLinks = [...links];
                                                    newLinks[i].text = ev.target.value;
                                                    setLinks(newLinks);
                                                }}
                                            />
                                        </label>
                                        <label>
                                            URL
                                            <input
                                                placeholder="Não esquecer o https:// no início!!"
                                                value={link.url}
                                                onChange={(ev) => {
                                                    let newLinks = [...links];
                                                    newLinks[i].url = ev.target.value;
                                                    setLinks(newLinks);
                                                }}
                                            />
                                        </label>
                                        <span
                                            class="trash"
                                            onClick={() => {
                                                let newLinks = [...links];
                                                newLinks.splice(i, 1);
                                                setLinks(newLinks);
                                            }}
                                        >
                                            <img src="/img/tampalixeira.svg" />
                                            <img src="/img/lixeira.svg" />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="admin-whatsapp-send" onClick={handleAistem}>
                        Enviar
                    </div>
                </div>
            </div>
            <div className={`einstein-dialog einstein-dialog-admin`}>
                {messages.map((m) => {
                    return (
                        <div className={`einstein-dialog-row dialog-${m.data.type} shown admin-dialog-row`}>
                            {m.data.type === "einstein" ? (
                                <div className="aistem-to-username">
                                    AI-stem para {adminUsers.find((u) => u.id === m.userId)?.email}
                                </div>
                            ) : (
                                // <Einstein className="welcome-einsten" start={true} alreadyStarted={true} />
                                <>
                                    <div
                                        className="aistem-username"
                                        onClick={() => {
                                            setMessage(
                                                `E aí! Você me fez essa pergunta: "${m.data.content}", e naquele momento não fui preciso na minha resposta.\nAgora consigo reformular uma resposta mais assertiva à sua duvida, se liga só:\n`
                                            );
                                            setSelectedUser(m.userId);
                                            setModalAistem(true);
                                        }}
                                    >
                                        <strong>{adminUsers.find((u) => u.id === m.userId)?.name.slice(0, 30)}</strong>
                                        <div>{adminUsers.find((u) => u.id === m.userId)?.email}</div>
                                    </div>
                                    {/* <img src={adminUsers.find((u) => u.id === m.userId)?.photo || "/img/default-icon.svg"} /> */}
                                </>
                            )}
                            <div className="einstein-dialog-row-text">
                                <span>
                                    {m.data.content
                                        .replace(/!?\[([^\[\]]*)\]\((.*?)\).?/gm, "")
                                        .replace(/(\【[^\】]*\】)/g, "")
                                        .replace(/\n\n+/g, "\n")
                                        .replace(/\**/g, "")}
                                </span>
                                {m.createdAt ? (
                                    <div className="message-time">{new Date(m.createdAt).toLocaleTimeString().slice(0, 5)}</div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
