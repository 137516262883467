import ConfettiExplosion from "react-confetti-explosion";
import "./NavbarAdmin.css";
import { Link } from "react-router-dom";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

function Navbar(props) {
    const { newUser, setNewUser } = useContext(UserContext);

    return (
        <div className="navbar navbar-admin">
            {newUser ? (
                <div className="navbar-admin-newuser">
                    <div className="newuser-content">
                        Temos um novo franqueado!
                        <div className="newuser-name">{newUser?.email}</div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {newUser ? (
                <ConfettiExplosion
                    key={Date.now()}
                    zIndex={1000000}
                    style={{
                        position: "fixed",
                        top: "50%",
                        right: "50%",
                    }}
                    width={2000}
                    force={2.0}
                    particleSize={16}
                    particleCount={200}
                    duration={5000}
                    wind={1000}
                    colors={["#b9d5f8", "#8bb8f3", "#5d9cef", "#2e80ea", "#1566d1", "#1050a2", "#0c3974", "#072246"]}
                    onComplete={() => setNewUser(null)}
                />
            ) : (
                <></>
            )}
            <img className="drop-logo" src="/img/logo-orizom.svg" alt="Logo da Orizom Drop" />
            Admin
            <div>
                <br />
            </div>
            <ul className="lateral-menu">
                <li>
                    <Link className={props.location.pathname == "/admin" ? "current-page" : ""} to="/admin">
                        Dashboard
                    </Link>
                </li>

                <li>
                    Estatísticas<span className="lateral-arrow"></span>
                    <Link
                        to="/admin/vendas10dias"
                        className={`${props.location.pathname === "/admin/vendas10dias" ? "current-page" : ""} lateral-sub`}
                    >
                        Vendas 10 dias
                    </Link>
                    <Link
                        to="/admin/relatorio-materiais"
                        className={`${
                            props.location.pathname === "/admin/relatorio-materiais" ? "current-page" : ""
                        } lateral-sub`}
                    >
                        Relatório Materiais
                    </Link>
                </li>

                <li>
                    <Link className={props.location.pathname == "/admin/orders" ? "current-page" : ""} to="/admin/orders">
                        Vendas<span className="lateral-arrow"></span>
                    </Link>
                    <Link
                        to="/admin/coleta"
                        className={`${props.location.pathname === "/admin/coleta" ? "current-page" : ""} lateral-sub`}
                    >
                        Aguard. Coleta
                    </Link>
                    <Link
                        to="/admin/allorders"
                        className={`${props.location.pathname === "/admin/allorders" ? "current-page" : ""} lateral-sub`}
                    >
                        Concluídas
                    </Link>
                    <Link
                        to="/admin/cancelamentos"
                        className={`${props.location.pathname === "/admin/cancelamentos" ? "current-page" : ""} lateral-sub`}
                    >
                        Cancel. Solicitado
                    </Link>
                    <Link
                        to="/admin/gravacoes"
                        className={`${props.location.pathname === "/admin/gravacoes" ? "current-page" : ""} lateral-sub`}
                    >
                        Gravações
                    </Link>
                    <Link
                        to="/admin/solicitacoes"
                        className={`${props.location.pathname === "/admin/solicitacoes" ? "current-page" : ""} lateral-sub`}
                    >
                        Solicitações
                    </Link>
                </li>
                <li>
                    <Link className={props.location.pathname == "/admin/products" ? "current-page" : ""} to="/admin/products">
                        Produtos<span className="lateral-arrow"></span>
                    </Link>
                    <Link
                        to="/admin/grupos"
                        className={`${props.location.pathname === "/admin/grupos" ? "current-page" : ""} lateral-sub`}
                    >
                        Grupos
                    </Link>
                    <Link
                        to="/admin/caixas"
                        className={`${props.location.pathname === "/admin/caixas" ? "current-page" : ""} lateral-sub`}
                    >
                        Caixas
                    </Link>
                    <Link
                        to="/admin/materiais"
                        className={`${props.location.pathname === "/admin/materiais" ? "current-page" : ""} lateral-sub`}
                    >
                        Materiais
                    </Link>
                    {/* <Link
                        to="/admin/categorias"
                        className={`${
                            props.location.pathname === "/admin/categorias"
                                ? "current-page"
                                : ""
                        } lateral-sub`}
                    >
                        Categorias
                    </Link> */}
                </li>
                {/* <li><Link className={props.location.pathname == '/admin/categories' || props.location.pathname == '/' ? 'current-page' : ''} to='/admin/categories'>Categorias</Link></li> */}
                <li>
                    <Link className={props.location.pathname == "/admin/users" ? "current-page" : ""} to="/admin/users">
                        Usuários
                    </Link>
                </li>
                <li>
                    <Link className={props.location.pathname == "/admin/aistem" ? "current-page" : ""} to="/admin/aistem">
                        AI-stem<span className="lateral-arrow"></span>
                    </Link>
                    <Link
                        to="/admin/mensagens-aistem"
                        className={`${props.location.pathname === "/admin/mensagens-aistem" ? "current-page" : ""} lateral-sub`}
                    >
                        Mensagens
                    </Link>
                    <Link
                        className={`${props.location.pathname === "/admin/aistem-screen" ? "current-page" : ""} lateral-sub`}
                        to="/admin/aistem-screen"
                    >
                        Tela Cheia
                    </Link>
                </li>
                {/* <li>
                    <Link
                        className={
                            props.location.pathname == "/admin/config" ||
                            props.location.pathname == "/"
                                ? "current-page"
                                : ""
                        }
                        to="/admin/config"
                    >
                        Configurações
                    </Link>
                </li> */}
                {/* <li>
                    <Link
                        className={
                            props.location.pathname == "/admin/credits" ||
                            props.location.pathname == "/"
                                ? "current-page"
                                : ""
                        }
                        to="/admin/credits"
                    >
                        Créditos
                    </Link>
                </li> */}
            </ul>
            <div className="navbar-user-container">
                <div className="navbar-user">
                    <img src={props.user.photo ? props.user.photo : "/img/default-icon.svg"} className="navbar-user-img" />
                    <div className="navbar-username-container">
                        <div className="navbar-username">{props.user.name.split(" ")[0]}</div>
                        <div className={`navbar-account-type navbar-account-admin`}>Administrador</div>
                    </div>
                </div>
                <ul className={`navbar-config navbar-config-admin`}>
                    <li>
                        <a href="#" onClick={() => props.setConfigModal(true)}>
                            <img className="config-icon" src="/img/config-icon.svg" alt="" />
                            Impressoras
                        </a>
                    </li>
                    <li>
                        <a href="/admin/login">
                            <img className="config-icon" src="/img/logout-icon.svg" />
                            Sair
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
