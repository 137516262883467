import "./Einstein.css";
import { React, useEffect, useState } from "react";

function Einstein(props) {
    const [logo, setLogo] = useState(false);
    const [logoText, setLogoText] = useState(false);
    const [start, setStart] = useState(false);
    const [final, setFinal] = useState(false);

    useEffect(() => {
        if (props.type === "login") {
            const updateLogo = () => {
                setLogo(true);
                setTimeout(() => setLogoText(true), 1000);
                setTimeout(() => setLogoText(false), 4000);
                setTimeout(() => setLogo(false), 5000);
                setTimeout(() => {
                    setStart(false);
                    setTimeout(() => setStart(true), 100);
                    setTimeout(() => setLogo(true), 1000);
                    setTimeout(() => setFinal(true), 2000);
                }, 6000);
            };

            setTimeout(updateLogo, 2000);
        }

        if (props.type === "landing") {
            const updateLogo = () => {
                setLogo(true);
                setTimeout(() => setLogoText(true), 500);
            };

            setTimeout(updateLogo, 800);
        }

        if (props.type === "say") {
            setTimeout(() => setLogo(true), 1000);
            // const updateLogo = () => {
            //     setLogo(true);
            //     // setTimeout(() => setLogoText(true), 1000);
            //     // setTimeout(() => setLogoText(false), 4000);
            //     // setTimeout(() => setLogo(false), 5000);
            //     // setTimeout(() => {
            //     //     setStart(false);
            //     //     setTimeout(() => setStart(true), 100);
            //     //     setTimeout(() => setLogo(true), 1000);
            //     //     setTimeout(() => setFinal(true), 2000);
            //     // }, 6000);
            // };
            // setTimeout(updateLogo, 3000);
        }
    }, []);

    useEffect(() => {
        if (props.start) setLogo(true);
        else setLogo(false);
    }, [props.start]);

    return (
        <div
            className={`einstein ${props.alreadyStarted ? "already-started" : ""} ${logo ? "logo" : ""} ${start ? "start" : ""} ${
                logoText ? "text" : ""
            } ${final ? "final" : ""} ${props.className || ""}`}
            onClick={props.onClick}
        >
            <div className="einstein-inner">
                <span className="ball" />
                <span className="ball" />
                <span className="ball" />
                <span className="ball" />
                <div className="mouth-outer">
                    <div className="mouth" />
                </div>
                <div className="tongue" />
            </div>
            <div className="einstein-text">AI-stem</div>
            <div className="logo">
                <img src="/img/logo-orizom.svg" />
            </div>
        </div>
    );
}

export default Einstein;
