import React, { useEffect, useState } from "react";
import "./Materials.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function Materials() {
    const { adminProducts, adminMaterials, setAdminMaterials, adminGroups } = useContext(UserContext);

    const [groups, setGroups] = useState([]);
    const [modal, setModal] = useState(0);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [loadingPhoto, setLoadingPhoto] = useState(false);

    const [modalGroup, setModalGroup] = useState({});

    useEffect(() => {
        // const fetchGroups = async () => {
        //     try {
        //         const response = await fetch("/api/products/getboxes", {
        //             method: "GET",
        //         });
        //         const data = await response.json();
        //         setGroups(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchGroups();
        setGroups(adminMaterials);
    }, [adminMaterials]);

    const saveGroup = async () => {
        if (!modalGroup.name?.trim()) return alert("insira o SKU");
        if (!modalGroup.photo) return alert("insira a foto");

        const findMaterial = adminMaterials.find((m) => m?.name?.trim() === modalGroup?.name?.trim());

        if (findMaterial) {
            if (modal === 1) return alert("SKU ja existe");
            if (findMaterial.id !== modalGroup?.id) return alert("SKU ja existe");
        }

        const response = await fetch(`/api/products/${modal === 1 ? "addmaterial/" : "editmaterial/"}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(modalGroup),
        });
        setModal(0);
        const data = await response.json();

        setAdminMaterials(data);
        // setGroups(data);
    };

    const handleFileChange = async (e) => {
        e.preventDefault();
        // setLoadingPhoto(true);
        // Enviar a foto ao servidor
        const formData = new FormData();
        formData.append("photo", e.target.files[0]);

        fetch("/api/products/uploadimage", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.photo);
                setModalGroup({ ...modalGroup, photo: data.photo.filename });
                setLoadingPhoto(false);
                e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload da foto");
                setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    return (
        <>
            <div className={`admin-modal-bg ${modalConfirm ? "shown" : ""}`} onClick={() => setModalConfirm(false)}>
                <div className="admin-confirm-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="admin-modal-title">Tem certeza?</div>
                    <div className="admin-modal-confirm-btns">
                        <div className="admin-modal-confirm-btn" onClick={() => setModalConfirm(false)}>
                            Não
                        </div>
                        <div
                            className="admin-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                setModalConfirm(false);
                                const response = await fetch("/api/products/removematerial", {
                                    method: "post",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        id: modalGroup.id,
                                    }),
                                });
                                const data = await response.json();
                                // setGroups(data);
                                setAdminMaterials(data);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`new-group-modal-bg ${modal ? "shown" : ""}`}
                onMouseDown={() => {
                    setModal(0);
                    setModalGroup({});
                }}
            >
                <div className="new-group-modal" onMouseDown={(ev) => ev.stopPropagation()}>
                    <div className="new-group-name">
                        SKU INTERNO:
                        <input
                            value={modalGroup.name || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    name: ev.target.value,
                                });
                            }}
                            maxLength={30}
                        />
                    </div>
                    <div className="new-group-name">
                        Descrição (opcional):
                        <textarea
                            value={modalGroup.text || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    text: ev.target.value,
                                });
                            }}
                        />
                    </div>

                    <label className={`admin-product-photo`}>
                        Foto
                        {modalGroup.photo ? (
                            <img src={`/uploads/internal/${modalGroup.photo}`} />
                        ) : (
                            <>
                                <span>+</span>Adicionar
                            </>
                        )}
                        <input className="add-photo-btn" type="file" accept="image/*" multiple onChange={handleFileChange} />
                    </label>

                    <div className="add-material-btns">
                        {modal !== 1 ? (
                            <div
                                className="material-remove"
                                onClick={(ev) => {
                                    setModalConfirm(true);
                                }}
                            >
                                Remover
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="save-material" onClick={saveGroup}>
                            {modal === 1 ? "Criar" : "Alterar"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin-groups-container">
                <div
                    className="admin-group-add"
                    onClick={() => {
                        setModalGroup({});
                        setModal(1);
                    }}
                >
                    <div className="add-group-plus">+</div>
                    <div>Criar novo material</div>
                </div>
                {groups.map((group) => {
                    console.log(group);
                    return (
                        <div
                            className="admin-material"
                            key={group.id}
                            onClick={() => {
                                setModalGroup(group);
                                setModal(2);
                            }}
                        >
                            <div className="admin-material-sku">{group.name}</div>
                            <img src={`/uploads/internal/${group.photo}`} />
                            {/* <div
                                className="admin-material-remove"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    setModalGroup(group);
                                    setModalConfirm(true);
                                }}
                            >
                                Remover
                            </div> */}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
