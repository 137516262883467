import "./AIstem.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import useLongPress from "../../../util/useLongPress.js";

import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Einstein from "../../Einstein/Einstein";
import { TypeAnimation } from "react-type-animation";
import CopyDiv from "../../CopyDiv/CopyDiv";

import { UserContext } from "../../../App.js";

import Cookies from "js-cookie";
import { notEmpty } from "../../../util/utils.js";
import { getConfig } from "../../../util/config.js";
import { Link } from "react-router-dom";

let aistemTimeout;
let notificationInterval;

// const audio = new Audio();

// const accessKey = "sucHvS0gxIFe7d0WfX8JEbF8KvqzlHLrqWetxtrvBOrmEzl7hzNs1Q==";

const AdminAistem = (props) => {
    const {
        user,
        setUser,
        orders,
        integrations,
        messages,
        setMessages,
        hidden,
        setHidden,
        setNotificationList,
        notificationListRef,
    } = useContext(UserContext);

    const messagesRef = useRef([]);

    useEffect(() => {
        messagesRef.current = messages;
        // console.log(messages);
    }, [messages]);

    const fullscreen = useRef(false);

    useEffect(() => {
        fullscreen.current = props.fullscreen;
    }, [props.fullscreen]);

    const [audio] = useState(new Audio());
    const [beep1] = useState(new Audio("/AIstem/aistemsound1.mp3"));
    const [beep2] = useState(new Audio("/AIstem/aistemsound2.mp3"));
    const [beep3] = useState(new Audio("/AIstem/aistemsound3.mp3"));
    const [beep4] = useState(new Audio("/AIstem/aistemsound4.mp3"));

    useEffect(() => {
        beep1.volume = getConfig(user, "aistemVolume") / 100;
        beep2.volume = getConfig(user, "aistemVolume") / 100;
        beep3.volume = getConfig(user, "aistemVolume") / 100;
    }, [user]);

    const [lastMessage, setLastMessage] = useState();
    const [waiting, setWaiting] = useState(false);

    // const [keywordDetections, setKeywordDetections] = useState([]);
    // const { keywordDetection, isLoaded, isListening, error, init, start, stop, release } = usePorcupine();

    const [images, setImages] = useState(null);
    const [links, setLinks] = useState(null);
    const [content, setContent] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [logoStage, setLogoStage] = useState(0);
    // const [refresh, setRefresh] = useState(Date.now());

    const [speaking, setSpeaking] = useState(false);
    const [speakingAudio, setSpeakingAudio] = useState(false);
    const [aistemOpen, setAistemOpen] = useState(false);

    const [mute, setMute] = useState(Cookies.get("mute") === "true");

    useEffect(() => {
        Cookies.set("mute", mute, { path: "/", expires: 10 * 365 * 24 * 60 * 60 });
    }, [mute]);

    const commands = [
        {
            command: "Einstein",
            callback: (comm, spokenPhrase, test) => {
                aistemCommand(spokenPhrase);
            },
            isFuzzyMatch: true,
            fuzzyMatchingThreshold: 0.1,
            // matchInterim: true,
            // bestMatchOnly: true,
        },
        {
            command: ["para", "pare", "cala a boca", "cale a boca", "silêncio"],
            callback: ({ resetTranscript }) => {
                resetTranscript();
                if (speaking || speakingAudio) beep3.play((e) => {}).catch();
                audio.pause();
                audio.currentTime = 0;
                setSpeaking(false);
                setSpeakingAudio(false);
            },
        },
        {
            command: ["mudo", "mutar", "ativar som"],
            callback: ({ resetTranscript }) => {
                if (!mute) beep3.play((e) => {}).catch();
                else beep2.play((e) => {}).catch();
                setMute((mute) => !mute);
                resetTranscript();
                audio.pause();
                audio.currentTime = 0;
                setSpeaking(false);
                setSpeakingAudio(false);
            },
        },
    ];

    const { transcript, browserSupportsSpeechRecognition, resetTranscript, browserSupportsContinuousListening } =
        useSpeechRecognition({ commands });

    useEffect(() => {
        // alert(browserSupportsContinuousListening);
        if (browserSupportsContinuousListening && !mute)
            SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
    }, []);

    const [listening, setListening] = useState(false);
    const [listeningManual, setListeningManual] = useState(false);

    const busyRef = useRef(false);

    useEffect(() => {
        busyRef.current = listening || listeningManual || waiting || speaking || speakingAudio;
    }, [listening, listeningManual, waiting, speaking, speakingAudio]);

    useEffect(() => {
        notificationInterval = setInterval(async () => {
            if (busyRef.current) return;
            if (!notificationListRef.current?.length) return;
            // alert(notificationListRef.current[0].text);

            try {
                setLastMessage({ response: notificationListRef.current[0]?.text });
                if (notificationListRef.current[0]?.url) {
                    if (fullscreen.current)
                        setFullImage("https://orizom.com.br/uploads/products/" + notificationListRef.current[0]?.photo);
                    await playAudio({ audio: "/uploads/audio/" + notificationListRef.current[0]?.url, internal: true });
                }
            } catch {
                setSpeaking(false);
                setTimeout(() => setSpeaking(false), 2000);
            }

            setNotificationList((notList) => notList.slice(1));
        }, 1000 * 60);
        return () => clearInterval(notificationInterval);
    }, []);

    useEffect(() => {
        if (transcript?.toLowerCase().includes("einstein") && !waiting && !listeningManual && !mute) {
            audio.pause();
            audio.currentTime = 0;
            setSpeaking(false);
            setSpeakingAudio(false);
            if (!listening) beep2.play((e) => {}).catch((e) => {});
            setListening(true);
            clearTimeout(aistemTimeout);
            aistemTimeout = setTimeout(() => setListening(false), 4001);
        } else {
            setListening(false);
            if (listening) resetTranscript();
        }
    }, [transcript, listening]);

    const playAudio = async (gptResponse) => {
        setSpeakingAudio(true);
        // audio.src = `https://orizom.cloud${gptResponse.audio}`;
        audio.src =
            window.location.protocol === "http:" ? `http://192.168.18.2:5000${gptResponse.audio}` : `${gptResponse.audio}`;
        audio.volume = 1;

        beep4.play((e) => {}).catch((e) => {});

        setTimeout(() => {
            audio
                .play((e) => {})
                .catch((e) => {
                    setSpeaking(false);
                    setSpeakingAudio(false);
                });
        }, 1000);

        audio.addEventListener("ended", function () {
            audio.currentTime = 0;
            setSpeaking(false);
            setSpeakingAudio(false);
            setTimeout(
                () => {
                    setLastMessage(null);
                    if (gptResponse.internal) {
                        setTimeout(() => setFullImage(null), 5000);
                    }
                },
                gptResponse.internal ? 1000 : ["close", "navigateTo"].includes(gptResponse?.function?.functionName) ? 2000 : 12000
            );
        });
    };

    const aistemCommand = async (spokenPhrase, ignoreEinstein) => {
        if (waiting || mute) return resetTranscript();
        clearTimeout(aistemTimeout);
        resetTranscript();
        // alert(spokenPhrase);
        if (transcript?.toLowerCase().includes("einstein") || ignoreEinstein || listeningManual) {
            let text = spokenPhrase.replace(/^einstein\s?/i, "");

            if (!text && !listeningManual) {
                // beep2.play();
                return setListeningManual(true);
            }

            beep1.play((e) => {}).catch((e) => {});

            if (
                ["nada", "nada não", "pare", "parar", "chega", "cale a boca", "cala a boca", "cala boca"].includes(
                    text.toLowerCase()
                )
            ) {
                return;
            }

            text = text.replace(/Starbucks/gi, "Starbox");
            text = text.replace(/Horizonte/gi, "Orizom");
            text = text.replace(/Horizon/gi, "Orizom");
            text = text.replace(/Einstein/gi, "AI-stem");
            text = text.replace(/\bEster\b/gi, "AI-stem");

            setWaiting(true);
            try {
                const response = await fetch("/api/gpt/admcreatemessage", {
                    method: "POST",
                    body: JSON.stringify({
                        userMessage: text,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setWaiting(false);

                const gptResponse = await response.json();

                try {
                    switch (gptResponse?.function?.functionName) {
                        case "openUrl":
                            document.elementFromPoint(1, 1).click();
                            window.open(gptResponse?.function?.url, "_blank").focus();
                            break;
                        case "close":
                            document.elementFromPoint(1, 1).click();
                            break;
                        // case "openOrder":
                        //     document.elementFromPoint(1, 1).click();
                        //     let order = Object.values(orders).find((o) => o.id == gptResponse?.function?.order);
                        //     if (order) {
                        //         navigate("pedidos");
                        //         setTimeout(() => {
                        //             modals.current.setModalOrderObj(order);
                        //             modals.current.setModalOrder(true);
                        //             if (gptResponse?.function?.plp || gptResponse?.function?.nfe) {
                        //                 setTimeout(() => {
                        //                     if (gptResponse?.function?.plp) modals.current.setModalPlp(true);
                        //                     else modals.current.setModalNfe(true);
                        //                 }, 300);
                        //             }
                        //         }, 200);
                        //     }
                        //     break;
                        default:
                            break;
                    }
                } catch {}
                setTimeout(async () => {
                    try {
                        setLastMessage(gptResponse);
                        resetTranscript();
                        if (gptResponse.audio) {
                            await playAudio(gptResponse);
                        }
                    } catch {
                        setSpeaking(false);
                        setTimeout(() => setSpeaking(false), 2000);
                    }
                }, 0);
            } catch (err) {
                console.error(err);
                setLastMessage({
                    response: `${
                        user.name.split(" ")[0]
                    }, eu peço desculpas, parece que eu estou com alguns problemas para me conectar ao banco de dados da IA nesse momento... Poderia tentar novamente daqui a pouco?`,
                });
            }
        }
    };

    //     {
    //         command: "Einstein *",
    //         callback: (food) => alert(food),
    //         isFuzzyMatch: true,
    //         fuzzyMatchingThreshold: 0.2,
    //     },

    useEffect(() => {
        if (listeningManual) {
            clearTimeout(aistemTimeout);
            aistemTimeout = setTimeout(() => {
                setListeningManual(false);
                setListening(false);
                beep3.play((e) => {}).catch((e) => {});
                if (transcript) {
                    resetTranscript();
                    aistemCommand(transcript, true);
                }
            }, 3000);
        }

        // SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
        // document.addEventListener("keydown", escFunction, false);
        // return () => document.removeEventListener("keydown", escFunction, false);
    }, [listeningManual, transcript]);

    const onMicLongPress = async () => {
        // alert("mudo");
        setMute(!mute);
        if (mute) {
            if (browserSupportsContinuousListening) SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
        } else {
            SpeechRecognition.stopListening();
            setListening(false);
            setListeningManual(false);
        }
    };

    const onMicClick = async () => {
        if (mute) {
            setMute(false);
        }
        if (!browserSupportsSpeechRecognition)
            return alert(
                "Seu navegador não é compatível com o reconhecimento de voz! Sugerimos o uso do Google Chrome, Edge e Safari. \nAinda é possível conversar normalmente pelo chat."
            );

        resetTranscript();

        if (!browserSupportsContinuousListening) SpeechRecognition.startListening({ language: "pt-BR" });

        // SpeechRecognition.startListening({ language: "pt-BR" });
        beep2.play((e) => {}).catch((e) => {});
        setListeningManual(true);
        setLastMessage(null);
        audio.pause();
        audio.currentTime = 0;
        setSpeaking(false);
    };

    const minLongPressEvent = useLongPress(onMicLongPress, onMicClick, { delay: 500 });

    // useEffect(() => {
    //     if (transcript && !listening) {
    //         resetTranscript();

    //         if (
    //             ["nada", "nada não", "pare", "parar", "chega", "cale a boca", "cala a boca", "cala boca"].includes(
    //                 transcript.toLowerCase()
    //             )
    //         ) {
    //             return;
    //         }

    //         let text = transcript;

    //         text = text.replace(/Starbucks/gi, "Starbox");
    //         text = text.replace(/Horizon/gi, "Orizom");
    //         text = text.replace(/Einstein/gi, "AI-stem");

    //         setMessages([
    //             {
    //                 id: Date.now(),
    //                 createdAt: Date.now(),
    //                 type: "user",
    //                 content: text,
    //             },
    //             ...messagesRef.current,
    //         ]);

    //         // props.addMessage({
    //         //     type: "user",
    //         //     content: text,
    //         // });
    //         setReply(text);
    //     }
    // }, [transcript, listening]);

    useEffect(() => {
        setImages(null);
        setLinks(null);
        setContent(null);

        let textContent = lastMessage?.response || lastMessage?.content;

        if (textContent) {
            let messageLinks = [...textContent?.matchAll(/(?=[^!]\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi)];
            let messageImages = [...textContent?.matchAll(/(?=!\[(!\[.+?\]\(.+?\)|.+?)]\(([^\)]+)\))/gi)];

            if (messageLinks?.length)
                setLinks(
                    messageLinks.map((e) => {
                        return {
                            text: e[1],
                            url: e[2],
                        };
                    })
                );

            if (messageImages?.length) {
                setImages(
                    messageImages.map((e) => {
                        return {
                            text: e[1],
                            url: e[2],
                        };
                    })
                );
                setTimeout(() => {
                    setFullImage(messageImages[0][2]);
                    setTimeout(() => {
                        setFullImage(null);
                    }, 15000);
                }, 500);
            }

            let cont = "";
            cont = textContent?.replace(/!?\[([^\[\]]*)\]\((.*?)\)\.?/gm, "");
            // content = content.replace(/\d+\s*[-\\.)]?\s+/gi, "");
            cont = cont.replace(/ *\【[^】]*\】\.? */g, "");
            cont = cont.replace(/\n\n+/g, "\n");
            cont = cont.replace(/\**/g, "");
            // content = props.message.content;
            setContent(cont);
        }
    }, [lastMessage]);

    useEffect(() => {
        if (speakingAudio) {
            setLogoStage(-1);
            setSpeaking(true);
            setTimeout(() => {
                setLogoStage(0);
                setTimeout(() => {
                    setLogoStage(1);
                }, 1800);
            }, 600);
        } else {
            setTimeout(() => {
                setSpeaking(false);
            }, 2000);
        }

        if (!speakingAudio && logoStage === 1) {
            setTimeout(() => {
                setLogoStage(2);
            }, 200);
        }
    }, [speakingAudio]);

    const [fullImage, setFullImage] = useState(null);

    return (
        <>
            <div
                className={`image-modal-bg image-modal-bg-aistem ${fullImage ? "shown" : ""}`}
                onClick={() => setFullImage(null)}
            >
                <div className="image-modal" onClick={(ev) => ev.stopPropagation()}>
                    <img src={fullImage?.replace("thumbs", "products") + `?v=${Date.now()}`} alt="imagem" />
                </div>
            </div>

            <div
                className={`aistem-mini-bg ${props.fullscreen ? "fullscreen" : ""} ${lastMessage || aistemOpen ? "shown" : ""}`}
                onClick={() => {
                    if (user.screening) setAistemOpen(false);
                    setLastMessage(null);
                    audio.pause();
                    audio.currentTime = 0;
                    setSpeaking(false);
                    setSpeakingAudio(false);
                }}
            />
            {props.fullscreen ? (
                <>
                    <Link to="/admin" className="aistem-fullscreen-back">
                        Voltar
                    </Link>
                    <div
                        className={`aistem-mini fullscreen aistem-background-listening ${lastMessage ? "transformed" : ""} ${
                            listening || listeningManual ? "shown" : ""
                        }`}
                    />
                </>
            ) : (
                <></>
            )}
            <div
                className={`aistem-mini ${props.fullscreen ? "fullscreen" : ""} ${
                    listening || listeningManual ? "listening" : ""
                } ${lastMessage ? "transformed" : ""} ${speaking ? "speaking" : ""} 
                ${hidden ? "hidden" : ""} ${mute ? "muted" : ""}`}
                {...(props.fullscreen ? minLongPressEvent : {})}
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <div className="aistem-mini-row">
                    <img
                        src={`/AIstem/aistem_start2.gif?v=${logoStage}`}
                        className={`aistem-gif ${logoStage >= 0 && speaking ? "shown" : ""} shownfirst`}
                    />
                    <img src={`/AIstem/aistem.gif`} className={`aistem-gif ${logoStage >= 1 && speaking ? "shown" : ""}`} />
                    <img
                        src={`/AIstem/aistem_end.gif?v=${logoStage}`}
                        className={`aistem-gif ${logoStage >= 2 && speaking ? "shown" : ""}`}
                    />

                    <Einstein className={`aistem-mini-logo`} start={!waiting} />

                    <div className="aistem-mini-text">
                        {listening || listeningManual ? (
                            <TypeAnimation
                                key={1}
                                sequence={["Estou te ouvindo!"]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        ) : waiting ? (
                            <TypeAnimation
                                key={2}
                                sequence={["Estou pensando..."]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        ) : (
                            <TypeAnimation
                                key={3}
                                sequence={[
                                    `${
                                        new Date().getHours() < 12
                                            ? "Bom dia"
                                            : new Date().getHours() < 18
                                            ? "Boa tarde"
                                            : "Boa noite"
                                    }, ${user.name.split(" ")[0]}!`,
                                ]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 25,
                                }}
                            />
                        )}
                    </div>

                    {true ? (
                        <div
                            className={`aistem-mic-btn ${mute ? "muted" : ""} ${
                                (listening || listeningManual) && transcript ? "speaking" : ""
                            }`}
                            {...minLongPressEvent}
                            onClick={(ev) => {
                                ev.stopPropagation();
                            }}
                        >
                            {(listening || listeningManual) && transcript ? (
                                <div className="aistem-user-speaking">
                                    <span className="blue" />
                                    <span className="red" />
                                    <span className="yellow" />
                                    <span className="green" />
                                </div>
                            ) : (
                                <img src="/img/mic.svg" />
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                {lastMessage && !aistemOpen ? (
                    <div className="aistem-speak">
                        <div className="aistem-dialog-text">
                            {/* {transcript} */}
                            {true ? (
                                <TypeAnimation
                                    key={content}
                                    sequence={[
                                        400,
                                        content,
                                        100,
                                        () => {
                                            setShowButtons(true);
                                            // if (props.message.onLoad) {
                                            //     props.message.onLoad();
                                            // }
                                            // if (props.message.resolve) props.message.resolve();
                                        },
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 1,
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {lastMessage?.buttons ? (
                                <div className={`einstein-dialog-buttons`}>
                                    {lastMessage?.buttons?.map((button, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`einstein-button ${showButtons ? "shown" : ""}

                                    `}
                                                onClick={button.action}
                                            >
                                                {button.text}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                            {links ? (
                                <div className={`einstein-dialog-links`}>
                                    {[...(links || [])].map((button, i) => {
                                        if (button.copy) {
                                            return (
                                                <CopyDiv
                                                    key={i}
                                                    value={button.copy}
                                                    className={`einstein-button ${showButtons ? "shown" : ""} clickable`}
                                                >
                                                    {button.text}
                                                </CopyDiv>
                                            );
                                        } else {
                                            return (
                                                <a
                                                    key={i}
                                                    href={button.url}
                                                    target="_blank"
                                                    className={`einstein-button ${showButtons ? "shown" : ""} clickable`}
                                                >
                                                    {button.text}
                                                </a>
                                            );
                                        }
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}

                            {images ? (
                                <div className={`einstein-dialog-links`} onClick={(ev) => ev.stopPropagation()}>
                                    {[...(images || [])].map((button, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`einstein-button ${showButtons ? "shown" : ""} clickable`}
                                                onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    setFullImage(button.url);
                                                }}
                                            >
                                                {button.text}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {/* <AIstemChat
                    setFirstStepsModal={setFirstStepsModal}
                    setFullImage={setFullImage}
                    setLastMessage={setLastMessage}
                    setWaiting={setWaiting}
                    audio={audio}
                    setReply={setReply}
                    reply={reply}
                    setSpeaking={setSpeakingAudio}
                    setAistemOpen={setAistemOpen}
                    resetTranscript={resetTranscript}
                /> */}
            </div>
        </>
    );
};

export default AdminAistem;
