import React, { useEffect, useRef, useState } from "react";
import "./RecentOrders.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import Loader from "../../../components/Loader/Loader.js";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";

import marketplaces from "../../../config/marketplaces.json";

export default function RecentOrders() {
    const { adminOrders, adminProducts, adminBoxes, adminGroups, adminUsers } = useContext(UserContext);

    const [products, setProducts] = useState([]);
    const [hideInfo, setHideInfo] = useState(false);

    // function getSortMethod() {
    //     var _args = Array.prototype.slice.call(arguments);
    //     return function (a, b) {
    //         for (var x in _args) {
    //             var ax = a[_args[x].substring(1)];
    //             var bx = b[_args[x].substring(1)];
    //             var cx;

    //             ax = typeof ax == "string" ? ax.toLowerCase() : ax / 1;
    //             bx = typeof bx == "string" ? bx.toLowerCase() : bx / 1;

    //             if (_args[x].substring(0, 1) == "-") {
    //                 cx = ax;
    //                 ax = bx;
    //                 bx = cx;
    //             }
    //             if (ax != bx) {
    //                 return ax < bx ? -1 : 1;
    //             }
    //         }
    //     };
    // }

    useEffect(() => {
        // if (!adminOrders.length || !adminUsers.length || !adminProducts.length) return;
        // if (products.length) return;
        // const newOrders = adminOrders.filter((order) => {
        //     return (
        //         order.marketplace !== 82 &&
        //         [0, 1, 5].includes(order.status) &&
        //         new Date(order.createdAt).getTime() > Date.now() - 10 * 24 * 60 * 60 * 1000
        //     );
        // });

        // const orderIds = adminOrders.map((o) => o.userId);
        // const filteredUsers = adminUsers.filter((u) => orderIds.includes(u.id));

        // let newProducts = adminProducts.map((e) => {
        //     return {
        //         ...e,
        //         recentOrders: newOrders.reduce(
        //             (prev, curr) => prev + curr.order_products.filter((o) => o.productId === e.id).length,
        //             0
        //         ),
        //         uniqueOrders: newOrders.reduce(
        //             (prev, curr) =>
        //                 prev + (curr.order_products.length === 1 && curr.order_products[0].productId === e.id ? 1 : 0),
        //             0
        //         ),
        //         allOrders: adminOrders.reduce(
        //             (prev, curr) => prev + curr.order_products.filter((o) => o.productId === e.id).length,
        //             0
        //         ),
        //         twoMonthOrders: adminOrders
        //             .filter((order) => {
        //                 return (
        //                     order.marketplace !== 82 &&
        //                     [0, 1, 5].includes(order.status) &&
        //                     new Date(order.createdAt).getTime() > Date.now() - 60 * 24 * 60 * 60 * 1000
        //                 );
        //             })
        //             ?.reduce((prev, curr) => prev + curr.order_products.filter((o) => o.productId === e.id).length, 0),
        //         // topSellers: [],
        //     };
        // });

        // newProducts = newProducts.map((p) => {
        //     if (p.allOrders === 0) return p;
        //     const topSellers = filteredUsers
        //         ?.map((u) => {
        //             const places = adminOrders
        //                 .filter((o) => o.userId === u.id && o.order_products.some((op) => op.productId === p.id))
        //                 .reduce((acc, o) => ((acc[o.marketplace] = (acc[o.marketplace] || 0) + 1), acc), {});

        //             const place = Object.entries(places).length ? Object.entries(places).sort((a, b) => b[0] - a[0])[0][0] : 0;

        //             // console.log(place);
        //             // const place = Math.max.apply(null, Object.values(places));
        //             // const place = 0;

        //             return {
        //                 id: u.id,
        //                 email: u.email,
        //                 total: adminOrders
        //                     .filter((o) => o.userId === u.id)
        //                     ?.reduce(
        //                         (prev, curr) =>
        //                             prev +
        //                             curr.order_products.filter((o) => {
        //                                 return o.productId === p.id;
        //                             }).length,
        //                         0
        //                     ),
        //                 place: place,
        //             };
        //         })
        //         .sort((a, b) => b.total - a.total)
        //         .slice(0, 3);
        //     return {
        //         ...p,
        //         topSellers: topSellers,
        //         topTotal: topSellers.reduce((prev, curr) => prev + curr.total, 0),
        //     };
        // });

        // newProducts.sort(getSortMethod("-recentOrders", "+stock"));

        // setProducts(newProducts);

        if (products?.length) return;

    const fetchOrders = async () => {
            try {
                const response = await fetch(
                    "/api/products/getrecentorders",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();

                if (data) setProducts(data);
            } catch (error) {
                console.error(error);
            }
    };

    fetchOrders();

    }, []);

    return (
        <>
            <div className={`admin-recentorders ${hideInfo ? "hide-info" : ""}`}>
                <div className="hide-info-btn">
                    <div
                        className={`eye ${hideInfo ? "" : "open"}`}
                        onClick={() => {
                            setHideInfo(!hideInfo);
                        }}
                    >
                        <div className="eye__base">
                            <div className="eye__base__view">
                                <div className="eye__base__view__iris" />
                                <div className="eye__base__view__pupil" />
                            </div>
                        </div>
                        <div className="eye__lid">
                            <div className="eye__lid__mask" />
                            <div className="eye__lid__lashes">
                                <div className="eye__lid__lashes__line" />
                                <div className="eye__lid__lashes__hair" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`recent-order-loading ${products?.length ? "" : "shown"}`}>
                    <Loader />
                </div>
                {products.map((product, i) => {
                    console.log("rerender");
                    const timeinmilisec = new Date(product.createdAt).getTime() - Date.now();
                    const days = Math.round((timeinmilisec / (1000 * 60 * 60 * 24)) * -1);

                    return (
                        <div
                            className={`admin-recentorder ${
                                product.twoMonthOrders === 0 && days > 60 && product.stock > 0 ? "blink" : ""
                            }`}
                            key={product.id}
                        >
                            <div className="recentorder-number">{i + 1}</div>
                            <div className="admin-recentorder-image">
                                <img src={"/uploads/thumbs/" + product.photos[0]} alt="" />
                                <CopyDiv className="recentorder-sku" value={product.sku}>
                                    {product.sku}
                                </CopyDiv>
                                <img className="recent-expanded" src={"/uploads/thumbs/" + product.photos[0]} alt="" />
                            </div>
                            <div
                                className={`admin-recentorder-orders ${
                                    product.stockReady < Math.round(product.uniqueOrders * 0.5) ? "out" : "notout"
                                }`}
                            >
                                Prontos
                                <div className="admin-recentorder-value">
                                    {product.stockReady}
                                    <span className="recentorder-pending">/{Math.round(product.uniqueOrders * 0.5)}</span>
                                </div>
                            </div>
                            <div className="admin-recentorder-orders">
                                Vnd. (10d)
                                <div className="admin-recentorder-value">{product.recentOrders}</div>
                            </div>
                            <div className="admin-recentorder-orders">
                                Unic. (10d)
                                <div className="admin-recentorder-value">{product.uniqueOrders}</div>
                            </div>
                            <div className="admin-recentorder-orders" style={{ color: "#c7a600" }}>
                                Vnd. (60d)
                                <div className="admin-recentorder-value">{product.twoMonthOrders}</div>
                            </div>
                            <div className={`admin-recentorder-orders`} style={{ color: "#1567d2" }}>
                                Dias on
                                <div className="admin-recentorder-value">{days}</div>
                            </div>
                            <div className="admin-recentorder-orders" style={{ color: "#1567d2" }}>
                                Vnd. total
                                <div className="admin-recentorder-value">{product.allOrders}</div>
                            </div>
                            <div className="admin-recentorder-orders" style={{ color: "#1567d2" }}>
                                Média/dia
                                <div className="admin-recentorder-value">
                                    {`${Math.round((product.allOrders / days) * 100) / 100}`.replace(".", ",")}
                                </div>
                            </div>
                            <div className={`admin-recentorder-orders`}>
                                Top Franqueados
                                <div className={`admin-recentorder-value top-sellers`}>
                                    {!product.topSellers ? (
                                        <div className="no-sales">Produto nunca vendido</div>
                                    ) : (
                                        product.topSellers?.map((u) => {
                                            if (u.total === 0) return <div></div>;
                                            return (
                                                <div key={u.id} className="top-seller">
                                                    <span className="top-seller-total">{u.total}</span>
                                                    <span className="top-seller-percent">
                                                        ({Math.round((u.total / product.topTotal) * 100)}%)
                                                    </span>
                                                    <span className="top-seller-email">{u.email}</span>
                                                    <img
                                                        className="top-sellet-place"
                                                        src={
                                                            marketplaces[u.place]?.img ||
                                                            marketplaces[marketplaces[u.place]?.parentId]?.img
                                                        }
                                                        alt={marketplaces[u.place]?.name}
                                                    />
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>

                            <div className={`admin-recentorder-orders ${product.stock <= 0 ? "out" : "notout"}`}>
                                Estoque
                                <div className="admin-recentorder-value">{product.stock}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
