import React, { useEffect, useState, useRef, useContext } from "react";

import RenderIfVisible from "react-render-if-visible";

import "./Meli.css";
import Loader from "../../components/Loader/Loader.js";
import CopyDiv from "../../components/CopyDiv/CopyDiv.js";

import { TypeAnimation } from "react-type-animation";
import calcPrice from "../../util/calcPrice.js";

import marketplaces_ from "../../config/marketplaces.json";
import Einstein from "../../components/Einstein/Einstein.js";

import { UserContext } from "../../App.js";

const marketplaces = Object.fromEntries(Object.entries(marketplaces_).filter((e) => e[1].showBling));

const shuffle = (array) => {
    if (!array) return [];
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const headers = [
    { label: "ID", key: "id" },
    { label: "Código", key: "sku" },
    { label: "Descrição", key: "title" },
    { label: "Unidade", key: "un" },
    { label: "NCM", key: "ncm" },
    { label: "Origem", key: "origin" },
    { label: "Preço", key: "price" },
    { label: "Situação", key: "situation" },
    { label: "Estoque", key: "stock" },
    { label: "Peso líquido(Kg)", key: "weight" },
    { label: "Peso bruto(Kg)", key: "weight" },
    { label: "GTIN/EAN", key: "ean" },
    { label: "Largura do produto", key: "width" },
    { label: "Altura do produto", key: "height" },
    { label: "Profundidade do produto", key: "length" },
    { label: "Marca", key: "brand" },
    { label: "Descrição curta", key: "description" },
    { label: "URL Imagens Externas", key: "photos" },
    { label: "Unidade de medida", key: "metricUnit" },
];

export default function Meli(props) {
    const { user, integrations, messages } = useContext(UserContext);

    const [products, setProducts] = useState([]);
    const [productsQuery, setProductsQuery] = useState([]);

    const [search, setSearch] = useState("");
    const [hideSearch, setHideSearch] = useState(false);

    const [config, setConfig] = useState({});

    const [loading, setLoading] = useState(false);

    const [modalConfig, setModalConfig] = useState(false);
    const [modalConfigConfirm, setModalConfigConfirm] = useState(false);

    const [startingSync, setStartingSync] = useState(false);
    const [endingSync, setEndingSync] = useState(false);

    const [integrationsArray, setIntegrationsArray] = useState([]);

    useEffect(() => {
        if (!products?.length) return;
        if (messages[0]?.function?.functionName === "changeMercadoLivreTitle") {
            let newProducts = products.slice();
            let productIndex = newProducts.findIndex((p) => p.id === messages[0]?.function?.productId);
            if (newProducts[productIndex]) newProducts[productIndex].title = messages[0]?.function?.newTitle;
            setProducts(newProducts);
        }
    }, [messages]);

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await fetch("/api/mercadolivre/products", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();

            setTimeout(() => {
                setProducts(data);
                setProductsQuery(data);
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        // alert(integrations.length);
        setIntegrationsArray(Object.values(integrations));
        if (!Object.values(config).length && Object.values(integrations).length)
            setConfig(Object.values(integrations).find((e) => e.type === 1)?.config || {});
    }, [integrations]);

    useEffect(() => {
        if (!search) {
            setProductsQuery(products);
        } else {
            let productsSearch = products.filter((e) => {
                return (
                    e.product.sku
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            search
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        ) ||
                    e.idMeli?.toLowerCase().includes(
                        search
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                    ) ||
                    e.title
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(
                            search
                                .toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        )
                );
            });

            setProductsQuery(productsSearch);
        }
    }, [search, products]);

    return (
        <>
            <div
                className={`sheet-config-modal-bg ${modalConfig ? "shown" : ""}`}
                onClick={() => {
                    setModalConfig(false);
                }}
            >
                <div className="sheet-config-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="sheet-config-modal-section prices-section">
                        Precificação (por venda)
                        <div className="sheet-config-prices">
                            <div className="sheet-config-percent">
                                Lucro:{" "}
                                <input
                                    value={(config?.profit || 0) + "%"}
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("%")) value = Number(e.target.value.slice(0, -1));
                                        else value = Number(e.target.value.replace("%", ""));
                                        if (Number(value) > 100) value = 100;
                                        setConfig({
                                            ...config,
                                            profit: value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="sheet-config-percent">
                                Imposto:{" "}
                                <input
                                    value={(config?.tax || 0) + "%"}
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("%")) value = Number(e.target.value.slice(0, -1));
                                        else value = Number(e.target.value.replace("%", ""));
                                        if (Number(value) > 60) value = 60;
                                        setConfig({
                                            ...config,
                                            tax: value,
                                        });
                                    }}
                                />
                            </div>
                            <div className="sheet-config-percent">
                                <span>
                                    Valor fixo:
                                    <span className="sheet-percent-info">
                                        ?
                                        <span className="sheet-percent-info-text">
                                            <strong>Campo opcional: </strong> você pode inserir custos fixos (aluguel, contas,
                                            etc) divididos pela projeção de vendas (mês)!
                                            <br />
                                            Ex: R$1000 ÷ 300 vendas = <strong>R$3,33</strong>
                                        </span>
                                    </span>
                                </span>
                                <input
                                    value={"R$ " + (config?.fixed || 0)}
                                    onChange={(e) => {
                                        let value;
                                        if (!e.target.value.includes("R$ ")) value = Number(e.target.value.slice(0, -1));
                                        else value = Number(e.target.value.replace("R$ ", ""));
                                        if (Number(value) > 60) value = 60;
                                        setConfig({
                                            ...config,
                                            fixed: value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sheet-config-buttons">
                        <div className="sheet-config-messages">
                            {Number(config?.profit) < 40 ? (
                                <div className="sheet-config-error">Risco de prejuízo em caso de devolução!</div>
                            ) : (
                                <></>
                            )}
                            {Number(config?.profit) > 60 ? (
                                <div className="sheet-config-message">Valor ideal para central de promoções!</div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            className="sheet-config-button"
                            onClick={() => {
                                if (!config?.profit) return alert("O lucro não pode ser 0.");
                                if (Number(config?.profit) < 10) return alert("O lucro mínimo é 10%");
                                setModalConfigConfirm(true);
                            }}
                        >
                            Salvar
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`sheet-config-modal-bg ${modalConfigConfirm ? "shown" : ""}`}
                onClick={() => {
                    setModalConfigConfirm(false);
                }}
            >
                <div className="sheet-config-modal" onClick={(ev) => ev.stopPropagation()}>
                    Deseja realmente salvar? A sincronização pode levar alguns minutos.
                    <div className="sheet-config-buttons">
                        <div
                            className="sheet-config-button no"
                            onClick={() => {
                                setModalConfigConfirm(false);
                            }}
                        >
                            Cancelar
                        </div>
                        <div
                            className="sheet-config-button yes"
                            onClick={() => {
                                setModalConfig(false);
                                setModalConfigConfirm(false);

                                fetch("/api/mercadolivre/updateconfig", {
                                    method: "PATCH",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(config),
                                });
                            }}
                        >
                            Confirmar
                        </div>
                    </div>
                </div>
            </div>

            <div className={`sheet-products ${integrationsArray.find((e) => e.type === 1)?.syncing ? "syncing" : ""}`}>
                <Loader hidden={products.length} className="sheet-loading" />
                {/* <div style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(products, ' ', 4)}</div> */}
                {!endingSync &&
                    !startingSync &&
                    productsQuery.map((e, i) => {
                        return (
                            <SheetProduct
                                user={user}
                                config={config}
                                product={e}
                                name={e.title}
                                index={i}
                                products={products}
                                setProducts={setProducts}
                                key={e.id}
                            />
                        );
                    })}
            </div>
            <div className={`sheet-products-syncing ${startingSync ? "shown" : ""}`}>
                {startingSync ? (
                    <>
                        <Einstein type="say" />
                        <TypeAnimation
                            className="syncing-say"
                            sequence={[1500, "Estou iniciando a sincronização de seus produtos com o bling!"]}
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 10,
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className={`sheet-products-syncing ${endingSync ? "shown" : ""}`}>
                {endingSync ? (
                    <>
                        <Einstein type="say" />
                        <TypeAnimation
                            className="syncing-say"
                            sequence={[1500, "Já terminei de sincronizar os seus produtos!"]}
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 10,
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className={`sheet-products-syncing ${integrationsArray.find((e) => e.type === 1)?.syncing ? "shown" : ""}`}>
                <Loader />
                <div className="syncing-title">Sincronização em andamento</div>
                <div className="syncing-bar">
                    <div
                        className="syncing-bar-inner"
                        style={{
                            width: `${
                                ((integrationsArray.find((e) => e.type === 1)?.syncingData?.qtty || 1) /
                                    (integrationsArray.find((e) => e.type === 1)?.syncingData?.total || 100)) *
                                100
                            }%`,
                        }}
                    />
                </div>
                <div className="syncing-qtty">
                    {integrationsArray.find((e) => e.type === 1)?.syncingData?.qtty || 0} de{" "}
                    {integrationsArray.find((e) => e.type === 1)?.syncingData?.total || 0} produtos
                </div>
            </div>
            <div
                className={`order-search-bar ${
                    products.length && !integrationsArray.find((e) => e.type === 1)?.syncing ? "" : "hidden"
                }`}
            >
                <div className="order-search">
                    <input
                        placeholder="SKU, título, ou código"
                        onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchParams({'q': e.target.value});
                        }}
                        onFocus={() => setHideSearch(true)}
                    />
                </div>
                {products.length ? (
                    <div className={`order-search-placeholder ${hideSearch ? "hidden" : ""}`}>
                        <TypeAnimation
                            sequence={[1500, "Pesquise por sku, nome do produto, ou código no place"]}
                            // wrapper="span"
                            speed={{ type: "keyStrokeDelayInMs", value: 25 }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div
                className={`sheet-buttons-container ${
                    products.length && !integrationsArray.find((e) => e.type === 1)?.syncing ? "" : "hidden"
                }`}
            >
                <div className={`sheet-buttons ${loading ? "loading" : ""}`}>
                    <div
                        className="meli-calculator"
                        onClick={() => {
                            setModalConfig(true);
                        }}
                    >
                        <img src={"/img/sheet/calculator.svg"} />
                    </div>
                </div>
            </div>
        </>
    );
}

function SheetProduct(props) {
    const [name, setName] = useState("");
    const [reloading, setReloading] = useState(false);
    const [newLoaded, setNewLoaded] = useState(false);

    useEffect(() => {
        setName(props.product?.title || "Erro ao gerar esse título");
    }, [props.config, props.product?.title]);

    const product = props.product.product;

    const price = calcPrice(
        0,
        product.price,
        props.config?.profit ? props.config?.profit / 100 || 0 : 0.4,
        props.config?.tax ? props.config?.tax / 100 || 0 : 0,
        props.config?.fixed ? props.config?.fixed || 0 : 0
    );

    return (
        <RenderIfVisible
            defaultHeight={64}
            visibleOffset={2000}
            rootElementClass={`sheet-product ${product.stock < 1 ? "sold-out" : ""} ${
                !props.product.idMeli ? "integration-error" : ""
            }`}
            style={{ animationDelay: `${props.index * 300}ms` }}
        >
            <span
                className={`sheet-product-new ${
                    new Date(product.createdAt).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7 ? "shown" : ""
                }`}
            >
                Novo!
            </span>
            <div className="sheet-product-img">
                <img src={"/uploads/thumbs/" + product.photos[0]} />
                <CopyDiv className="sheet-product-sku" value={product.sku}>
                    {product.sku}
                </CopyDiv>
                <img
                    src={"/uploads/thumbs/" + product.photos[0]}
                    className="sheet-product-img-large"
                    style={{
                        top: props.products.length - 1 == props.index ? "-120px" : "-20px",
                    }}
                />
            </div>
            <div
                className={`sheet-product-name-container ${reloading ? "reloading" : ""} ${
                    name?.trim().length < 60 - 10 ? "toosmall" : ""
                } ${name?.trim().length < 60 / 3 ? "tootoosmall" : ""} ${props.product.data?.sold_quantity > 0 ? "locked" : ""}`}
            >
                <textarea
                    className="sheet-product-name"
                    maxlength={60}
                    spellcheck="false"
                    value={name}
                    onChange={(ev) => {
                        setName(ev.target.value.replace(/  /gi, " "));
                    }}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            ev.preventDefault();
                            return ev.target.blur();
                        }
                    }}
                    onBlur={(ev) => {
                        if (name?.trim().length < 60 / 3) return;
                        updateTitles(
                            ev.target.value,
                            props.config.marketplace || 0,
                            props.product,
                            props.products,
                            props.setProducts
                        );
                        console.log("teste");
                    }}
                />
                <div className="sheet-product-name-length">
                    {name?.trim().length < 60 / 3 ? (
                        <span className="sheet-product-toosmall" key="1">
                            Insira mais caracteres, pois não será salvo!
                        </span>
                    ) : (
                        <span className="sheet-product-toosmall" key="2">
                            Título curto, baixas chances de venda!
                        </span>
                    )}
                    {name?.trim().length}
                </div>
                <div className="name-notsaved">Não salvo!</div>
                <div className="sheet-product-name-overlay">
                    {reloading && !newLoaded ? (
                        <TypeAnimation
                            key={0}
                            sequence={[name, ""]}
                            preRenderFirstString={true}
                            // wrapper="span"
                            deletionSpeed={{
                                type: "keyStrokeDelayInMs",
                                value: 2,
                            }}
                        />
                    ) : newLoaded ? (
                        <TypeAnimation
                            key={1}
                            sequence={[name, 500, () => setReloading(false)]}
                            // wrapper="span"
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 2,
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="sheet-name-reload">
                    <img
                        src={props.product.data?.sold_quantity > 0 ? "/img/sheet/lock.svg" : "/img/reload.svg"}
                        onClick={async () => {
                            setNewLoaded(false);
                            setReloading(true);
                            let newName = props.product.product.titles
                                ? props.product.product.titles[Math.floor(props.product.product.titles.length * Math.random())]
                                : "";
                            let maxLength = 60;
                            //     marketplaces[props.config.marketplace || 0]
                            //         .maxLength;
                            newName = newName?.slice(0, maxLength) || props.product.product.name || "";
                            // if (newName.length < maxLength - 9) {
                            //     let tags = props.product.product.tags2;
                            //     for (let tag of shuffle(tags)) {
                            //         let nameTag = `${newName} ${tag.text}`;
                            //         if (nameTag.length <= maxLength)
                            //             newName = nameTag;
                            //     }
                            // }
                            // const response = await fetch("/api/bling/generatetitle", {
                            //     method: "POST",
                            //     headers: {
                            //         "Content-Type": "application/json",
                            //     },
                            //     body: JSON.stringify({
                            //         // products: selected,
                            //         id: props.product.product.id,
                            //         marketplace: props.config.marketplace,
                            //     }),
                            // });
                            // const newName = await response.json();
                            // updateTitles(
                            //     newName.title,
                            //     props.config.marketplace || 0,
                            //     props.product,
                            //     props.products,
                            //     props.setProducts
                            // );

                            updateTitles(newName, 0, props.product, props.products, props.setProducts);

                            // updateTitles(
                            //     ev.target.value,
                            //     props.config.marketplace || 0,
                            //     props.product,
                            //     props.products,
                            //     props.setProducts
                            // );

                            setTimeout(() => {
                                setName(newName);
                                setTimeout(() => setNewLoaded(true), 500);
                            }, 1000);
                        }}
                    />
                </div>
            </div>
            <div value={props.product.idMeli} className="meli-product-number">
                <CopyDiv value={props.product.idMeli || "MLB000000000"}>{props.product.idMeli || "MLB000000000"}</CopyDiv>
                <div className="meli-menu">
                    <a href={props.product.data?.permalink} target="_blank">
                        <img src="/img/meli/eye.svg" />
                    </a>
                    <a
                        href={
                            props.product.idMeli
                                ? `https://www.mercadolivre.com.br/anuncios/${props.product.idMeli}/modificar/`
                                : null
                        }
                        target="_blank"
                    >
                        <img src="/img/meli/pencil.svg" />
                    </a>
                </div>
            </div>
            {/*{props.product.stock < 1 ? <div className='sheet-sold-out-container'><img className='sheet-sold-out' src='/img/esgotado2.svg'/><span className='sheet-sold-out-text'>Esgotado!</span></div> : ''}*/}
            <CopyDiv value={(Math.floor(price) + 0.99).toString().replace(".", ",")} className="sheet-product-price">
                {product.stock < 1 ? <span className="sheet-sold-out-text">Esgotado!</span> : ""}
                <small>R$ </small>
                {Math.round(price)}
            </CopyDiv>
            <div>
                <img className="sheet-menu" src={"/img/sheet/menu.svg"} />
                <div className={`meli-menu`}>
                    <a href={props.product.data?.permalink} target="_blank">
                        <img src="/img/meli/eye.svg" />
                    </a>
                    <a
                        href={
                            props.product.idMeli
                                ? `https://www.mercadolivre.com.br/anuncios/${props.product.idMeli}/modificar/`
                                : null
                        }
                        target="_blank"
                    >
                        <img src="/img/meli/pencil.svg" />
                    </a>
                </div>
            </div>
        </RenderIfVisible>
    );
}

async function updateTitles(title, marketplace, product, products, setProducts) {
    if (product.title === title) return;

    let newProducts = products.slice();
    newProducts[products.findIndex((e) => e.id === product.id)].title = title;
    setProducts(newProducts);

    try {
        // alert(JSON.stringify(titles));
        const response = await fetch("/api/mercadolivre/updatetitle", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: title,
                product: product.productId,
            }),
        });

        // alert(await response.text());
    } catch (error) {
        console.error(error);
    }
}
