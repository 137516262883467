import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import VoiceWidget from "../components/AIstem/AIstem.js";

export default function NavbarLayout(props) {
    return (
        <>
            <VoiceWidget />
            <Navbar navbarOpen={props.navbarOpen} setNavbarOpen={props.setNavbarOpen} />
            <div className="content">
                <Outlet />
            </div>
        </>
    );
}
