import './Loader.css';
import { React, useState } from 'react';

function Loader(props) {
    const [hide, setHide] = useState(false);
    if (props.hide) {
        setTimeout(() => setHide(true), props.hide);
    }
    return(
        <div className={`loader ${props.hidden || hide ? 'hidden' : ''} ${props.className || ''}`}>
            <span className='mouth-outer'><span className='mouth'></span></span>
            <span className='eye1'></span>
            <span className='eye2'></span>
        </div>
    );
  }

export default Loader;