import { useRef, useState } from "react";

function isEmpty(value) {
    return value == null || (typeof value === "string" && value.trim().length === 0);
}

function notEmpty(value) {
    return isEmpty(value) ? false : value;
}

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export { isEmpty, notEmpty, useStateAndRef, sleep };
