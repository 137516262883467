import "./Aistem.css";
import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactFlow, { useNodesState, useEdgesState, addEdge, Controls, Background, updateEdge, Handle, Position } from "reactflow";
import "reactflow/dist/base.css";
import Loader from "../../../components/Loader/Loader";

const nodeDefaults = {
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
};

// const initialNodes = [
//     { id: "1", position: { x: 0, y: 0 }, data: { label: "Orizom" }, ...nodeDefaults },
//     { id: "2", position: { x: 100, y: 100 }, data: { label: "2" }, ...nodeDefaults },
//     { id: "3", position: { x: 100, y: 200 }, data: { label: "3" }, ...nodeDefaults },
// ];
// const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];

export default function Aistem(props) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showText, setShowText] = useState(false);

    const edgeUpdateSuccessful = useRef(true);

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch("/api/gpt/getconfig", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.json();
                if (data.nodes) setNodes(data.nodes);
                if (data.edges) setEdges(data.edges);

                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const onConnect = useCallback(
        (params) => {
            console.log(params);
            if (edges.find((e) => e.target === params.target)) return;
            setEdges((eds) => addEdge({ ...params }, eds));
        },
        [setEdges, edges]
    );

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true;
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    }, []);

    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            setEdges((eds) => eds.filter((e) => e.id !== edge.id));
        }

        edgeUpdateSuccessful.current = true;
    }, []);

    const onNodeClick = useCallback((ev, data) => {
        setSelected(data);
        console.log(data);
    }, []);

    // console.log(nodes);
    // console.log(edges);

    let startNodes = nodes.filter((n) => !edges.find((e) => e.target === n.id));

    let text = useRef("");

    (async () => {
        text.current = "";
        let startnum = 1;
        for (let node of startNodes) {
            text.current += `${startnum} - ${node.data.label}\n${node.data?.content || ""}${
                node.data?.url ? `\n(Link demonstrativo: "${node.data?.url}")` : ""
            }${node.data?.image ? `\n(Imagem demonstrativa: "https://orizom.com.br/${node.data?.image}")` : ""}\n\n`;
            function recursive(nodeRecursive, startnum, num) {
                let newNodes = nodes.filter((n) => edges.find((e) => e.target === n.id && e.source === nodeRecursive.id));
                if (newNodes.length)
                    newNodes.forEach((n, i) => {
                        text.current += `${startnum}.${i + 1} - ${n.data.label}\n${n.data?.content || ""}${
                            n.data?.url ? `\n(Link demonstrativo: "${n.data?.url}")` : ""
                        }${n.data?.image ? `\n(Imagem demonstrativa: "https://orizom.com.br/${n.data?.image}")` : ""}\n\n`;
                        let newNodes2 = nodes.filter((n2) => edges.find((e) => e.target === n2.id && e.source === n.id));
                        if (newNodes2.length) recursive(n, `${startnum}.${i + 1}`, num + 1);
                    });
            }
            recursive(node, startnum, 1);
            startnum++;
        }
    })();

    const handleFileChange = async (e) => {
        e.preventDefault();
        // setLoadingPhoto(true);
        // Enviar a foto ao servidor
        const formData = new FormData();
        formData.append("photo", e.target.files[0]);

        fetch("/api/products/uploadimage", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.photo);
                // setModalGroup({ ...modalGroup, icon: data.photo.filename });
                setNodes((nodes) =>
                    nodes.map((n) => {
                        if (n.id === selected.id)
                            return { ...n, data: { ...n.data, image: "uploads/internal/" + data.photo.filename } };
                        else return n;
                    })
                );
                // setLoadingPhoto(false);
                e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload da foto");
                // setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    return (
        <>
            <div className={`aistem-flow-modal-bg aistem-flow-loading ${loading ? "shown" : ""}`}>
                <Loader />
            </div>
            <div className={`aistem-flow-modal-bg ${selected ? "shown" : ""}`} onMouseDown={() => setSelected(null)}>
                <div className="aistem-flow-modal" onMouseDown={(ev) => ev.stopPropagation()}>
                    <div
                        className="aistem-flow-remove"
                        onClick={() => {
                            setNodes((nodes) => nodes.filter((n) => n.id !== selected.id));
                            setEdges((edges) => edges.filter((e) => e.source !== selected.id && e.target !== selected.id));
                            setSelected(null);
                        }}
                    >
                        Remover
                    </div>
                    <div className="aistem-flow-separator">
                        Nome
                        <input
                            value={nodes.find((e) => e.id === selected?.id)?.data.label || ""}
                            onChange={(ev) => {
                                setNodes((nodes) =>
                                    nodes.map((n) => {
                                        if (n.id === selected.id) return { ...n, data: { ...n.data, label: ev.target.value } };
                                        else return n;
                                    })
                                );
                            }}
                        />
                    </div>
                    <div className="aistem-flow-separator">
                        Conteúdo
                        <textarea
                            value={nodes.find((e) => e.id === selected?.id)?.data.content || ""}
                            onChange={(ev) => {
                                setNodes((nodes) =>
                                    nodes.map((n) => {
                                        if (n.id === selected.id) return { ...n, data: { ...n.data, content: ev.target.value } };
                                        else return n;
                                    })
                                );
                            }}
                        />
                    </div>
                    <div className="aistem-flow-separator">
                        Link
                        <input
                            value={nodes.find((e) => e.id === selected?.id)?.data.url || ""}
                            onChange={(ev) => {
                                setNodes((nodes) =>
                                    nodes.map((n) => {
                                        if (n.id === selected.id) return { ...n, data: { ...n.data, url: ev.target.value } };
                                        else return n;
                                    })
                                );
                            }}
                        />
                    </div>
                    <div className="aistem-flow-separator">
                        Imagem
                        <label className={`aistem-flow-image`}>
                            {nodes.find((e) => e.id === selected?.id)?.data.image ? (
                                <img src={`/` + nodes.find((e) => e.id === selected?.id)?.data.image || ""} />
                            ) : (
                                <>
                                    <span>+</span>Adicionar
                                </>
                            )}
                            <input className="add-photo-btn" type="file" accept="image/*" onChange={handleFileChange} />
                        </label>
                        {/* <input
                            value={nodes.find((e) => e.id === selected?.id)?.data.image || ""}
                            onChange={(ev) => {
                                setNodes((nodes) =>
                                    nodes.map((n) => {
                                        if (n.id === selected.id) return { ...n, data: { ...n.data, image: ev.target.value } };
                                        else return n;
                                    })
                                );
                            }}
                        /> */}
                    </div>
                </div>
            </div>
            <div className="aistem-flow-menu">
                <div
                    className="aistem-flow-btn"
                    onClick={() => {
                        setNodes((nds) => [
                            ...nds,
                            {
                                id: `${Number(nds[nds.length - 1]?.id || 0) + 1}`,
                                position: { x: 10, y: 10 },
                                data: { label: "Em branco" },
                                ...nodeDefaults,
                            },
                        ]);
                    }}
                >
                    Adicionar
                </div>
                <div
                    className={`aistem-flow-btn flow-show-text`}
                    onClick={() => {
                        setShowText((text) => !text);
                    }}
                >
                    Mostrar texto
                </div>
                <div
                    className="aistem-flow-btn"
                    onClick={async () => {
                        setLoading(true);
                        try {
                            const response = await fetch("/api/gpt/saveconfig", {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ nodes: nodes, edges: edges, text: text.current }),
                            });

                            setLoading(false);
                        } catch (error) {
                            alert("Erro ao salvar, verifique sua conexão!");
                            setLoading(false);
                            console.error(error);
                        }
                    }}
                >
                    Salvar
                </div>
            </div>
            <div className="aistem-flow" style={{ width: "100%", height: "100%" }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    onNodeClick={onNodeClick}
                    fitView
                >
                    <Controls />
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
            <div className={`test ${showText ? "shown" : ""}`}>
                <strong>Texto gerado a partir do fluxograma</strong>
                <br />
                <br />
                {text.current}
            </div>
        </>
    );
}
