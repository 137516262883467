import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import "./EntrancePayment.css";

export default function EntrancePayment(props) {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.user.paid) {
            navigate("/");
        }
    }, [props.user]);

    return (
        <>
            <div className="admin-login-page">
                <div className="entrance-payment">
                    Estamos quase lá!
                    <br />
                    Faça o pagamento da franquia para continuar seu cadastro!
                    <a
                        href={`${window.location.protocol}//${
                            window.location.hostname == "localhost"
                                ? `${window.location.hostname}:5000`
                                : window.location.hostname
                        }/api/mercadopago/paymentlink`}
                        target="_blank"
                        className="entrance-payment-btn"
                    >
                        Realizar pagamento
                    </a>
                    <a href="/logout" className="entrance-payment-leave">
                        Sair
                    </a>
                </div>
            </div>
        </>
    );
}
