const terms = `	Aqui estão delineadas as normas, prerrogativas, obrigações e demais informações que devem ser observadas pelos respectivos contratantes, a Orizom Technologies Inc. (estabelecida no Brasil, inscrita no Cadastro Nacional de Pessoas Jurídicas do Ministério da Fazenda (CNPJ/MF) sob n. 29.563.690/0001-79  “Orizom”) e o Franqueado, seja este uma Pessoa Física ou Jurídica, esteja ele dentro ou fora do território brasileiro. Os Termos representam um instrumento eletrônico que configura um contrato entre as partes. Para acessar a plataforma, o franqueado afirma ter lido e compreendido os Termos. Ao clicar em 'Aceito os Termos de Uso', ele expressa sua concordância inequívoca com todas as disposições deste contrato. O ato de aceitar equivale à assinatura eletrônica do contrato. 

	A Orizom tem o direito de atualizar seus Termos a qualquer momento a afim da melhora do serviço prestado.
Os franqueados serão informados das atualizações por meio da Inteligência Artificial AI-stem, integrada à plataforma. 
A forma que serão informados poderá ser via Whatsapp, na própria página inicial da plataforma ou por email, onde o franqueado deverá ler, reler e se certificar que faz sentido pra si e posteriormente aceitar ou não por meio de um botão de aceite.
Em caso do não aceite das novas regras atualizadas, o franqueado deve entrar em contato de imediato com o time da Orizom, para que seja narrada vossas considerações. 
Neste período a plataforma ficará funcional por completo tendo um prazo determinado para cumprimento ou não das novas normas.
 
Informações gerais

	A Orizom é uma empresa que tem por finalidade fabricar produtos de sua marca (bem como comercializar produtos de marcas terceiras) e disponibilizar através da modalidade de negócio de “franquia online” para que sejam comercializados por pessoas e empresas que se franquear-se. 
Para tal se faz necessário o uso de uma plataforma tecnológica e online que espelha todo o estoque da Orizom, de forma virtual, através de fotos e informações dos produtos.
Este ambiente possibilita a aquisição dos produtos onde, uma vez comprado pelo franqueado, são revisados, embalados e posteriormente despachados através de transportadoras vinculadas aos marketplaces (Mercado Livre, Shopee, Magalu, etc...) que o franqueado vir a ofertar tais produtos!
Esse método de trabalho é denominado de “dropshipping” ou “venda sem estoque”! 
Modalidade logística essa que permite ao franqueado comercializar os produtos Orizom e terceiros sem a necessidade de ter um estoque físico! 

Resumindo:
1 - O franqueado anuncia o produto da plataforma Orizom! 
2 – Ao vender o produto anunciado por ele, realiza a compra na plataforma da Orizom!
3 - A Orizom executa toda parte de tratamento do pedido e deixa pronto para ser coletado pela transportadora parceira do franqueado!  
4 – A transportadora parceira recolhe o pacote e realiza o translado do mesmo até a casa do cliente do franqueado!  

A Orizom não trabalha na modalidade de Atacado, não podendo o franqueado comprar na plataforma Orizom com essa finalidade.
A franquia custa R$1.599,90 e tem validade de 1 ano, podendo ser renovado ou não pelo franqueado através do pagamento de renovação.
A renovação pode ocorrer alterações de preço dependendo do período que for renovado.
Isso dependerá de vários fatores como econômicos do país bem como de atualizações de regras da Orizom.
O franqueado é toda pessoa física ou jurídica que comercializa produtos Orizom bem como produtos disponibilizados pela Orizom em sua plataforma.
O franqueado deve ter, no mínimo, 18 anos de idade para franquear-se à Orizom.
O franqueado é responsável pelos canais de venda que ofertar bem como pela gestão dos clientes e pelo negócio como um todo, uma vez que todo o cadastro é feito com os dados do franqueado e não pelos dados da Orizom.
O AI-stem é a inteligência artificial generativa da Orizom.
Ele realiza otimizações baseado nos dados inseridos do franqueado, no ato do cadastro bem como em todo momento de uso da plataforma, coletando os dados, analizando e criando subsídios para otimizar o uso por parte do franqueado, pelo como servindo como ferramenta de parâmetros de tomada de decisão por parte do franqueado.
O AI-stem por ser uma nova tecnologia, 100% criada pela Orizom, está em constante aprendizado e pode ocorrer falhas.
Periodicamente o AI-stem passa por atualizações, onde todas elas são avisadas pela o franqueado.
O AI-stem, as vezes, poderá fazer 100% do processo, desde o cadastramento da oferta até a captura da venda, e posterior desconto do valor do produto vendido no saldo em carteira do franqueado.
Porém para esse mecanismo funcionar, o franqueado executa, previamente, configurações de como deseja que seja o margem de lucro, o formato dos títulos bem como outras configurações para melhor funcionalidade da inteligência artificical AI-stem.
O uso de terceiros pela conta de franqueado é de TOTAL responsabilidade do fraqueado.
É de TOTAL responsabilidade do franqueado manter um saldo em carteira, para que assim seja descontado automaticamente o valor do produto vendido por ele e capturado pelo AI-stem.
Por falta de saldo, todo pedido ficará “parado” no processo logístico da Orizom, acarretando assim problemas de reputações nas plataformas que o franqueado vir ofertar, bem como atrasos em decorrência de saldo insuficiente.
É de TOTAL responsabilidade do franqueado averiguar se todas as informações contidas nas ofertas estão de acordo com as estabelecidas pela orizom através da plataforma.
Qualquer devolução por parte do cliente por equívocos de informações, o custo de devolução é de total responsabilidade do franqueado!
Rescisão contratual por parte da Orizom
A Orizom poderá, a seu critério, rescindir este contrato em caso de:

1 - O franqueado não cumprir as regras destes Termos de Uso; 
2 – O franqueado por 30 dias (corridos) ficar sem saldo em carteira, não sendo possível o desconto do valor dos produtos pela plataforma bem como o envio destes, prejudicando assim a reputação da marca Orizom nos canais de venda ofertados pelo franqueado;
3 - Inatividade total* por parte do franqueado no período de 30 dias (corridos) ou mais.
**Inatividade total = O franqueado não acessar a plataforma durante 30 dias(corridos) sem nenhum aviso prévio, como doença, viagem ou fatores importantes que impeçam, periodicamente, a execução da franquia.

Ocorrendo a rescisão contratual por parte da Orizom, valores em carteira são serão devolvidos em espécie, tendo o franqueado que realizar vendas correspondente ao valor em carteira, para assim retirar os valores pela plataforma que estiver ofertando, como por exemplo Mercado Livre, Shopee, Magalu, etc...
Os valores em carteira jamais serão convertidos em produtos para serem enviados para o endereço do franqueado.
Rescisão contratual por parte do franqueado

O franqueado poderá, rescindir este contrato em caso de:
1 – Inatividade por parte da Orizom no tocante às suas obrigações por 7 dias (corridos), sem aviso prévio;
2 – Impossibilidade física ou psíquica por parte do franqueado;
3 – Desistência por qualquer natureza.

Havendo saldo em carteira, o franqueado terá que converter em vendas nas plataformas que ofertar para esgotamento integral do saldo em carteira.
O valor pago pela franquia, em nenhuma circunstância, será estornado para o franqueado, devido o mesmo ter usufruído do serviço independente do período.

SEGURANÇA DA PLATAFORMA
Para garantir um ambiente on-line mais seguro (não existindo ambiente online 100% seguro), a Orizom adota todos os protocolos possíveis de prevenções como por exemplo ataques de hackers, vírus ou quaisquer outros meios virtuais danosos.
Utilizamos, como sistema de pagamento, o Mercado Pago, hoje sendo talvez a melhor e mais segura plataforma de pagamento do Brasil.
Nela fica totalmente criptografado seus dados e a proteção dos mesmos.

Propriedade intelectual da Orizom!
A marca Orizom, suas variáveis de logomarcas, a tecnologia AI-stem, as imagens dos produtos orizom, são de propriedade da Orizom Tecnologies inc. 
Ocorrendo violação dos citados acima, de imediato será solicitado judicialmente reparação dos danos devidos. 
Fica proibido a utilização do CNPJ, razão social e nome fantasia da Orizom para qualquer finalidade, sem aviso prévio e posterior autorização da Orizom. 
A Orizom jamais se responsabilizará pelo uso indevido, por parte do franqueado, de outras imagens não ofertadas na plataforma, marcas, logotipos e quaisquer conteúdo de terceiros. 
É proibido a distribuição ou reprodução de tudo que a Orizom disponibilizar por parte do franqueado, sem aviso prévio e posterior autorização. 

Última atualização: 21/06/2024`;

const privacy = `Utilizamos suas informações para desenvolver mecanismos que simplificam o seu cotidiano. Contamos com seus dados para proporcionar uma experiência sob medida, permitindo que desfrute dos nossos serviços e aprimorando-os continuamente. Caso as informações fornecidas não sejam precisas, a qualidade da experiência oferecida fica comprometida. Caso forneça dados incorretos ou falsos, os serviços prestados, como o AI-stem, não atingirão seu máximo propósito, e há a possibilidade de rescisão contratual com a franquia Orizom, devido a não conformidade com os Termos de Uso.

Se for menor de idade, é desaconselhável compartilhar suas informações pessoais, pois não possui capacidade legal para contratar nossos serviços.

Você tem o direito de acessar, atualizar, corrigir e manter a confidencialidade de suas informações pessoais.

A preservação da privacidade online demanda uma colaboração entre empresas e usuários. Implementamos rigorosas medidas de segurança para resguardar suas informações, contudo, é crucial que você também zele por sua privacidade.

Processamos seus dados com responsabilidade para oferecer serviços personalizados.
Zelamos pela segurança de seus dados pessoais com os mais elevados padrões de proteção. Não negociamos suas informações.

Última atualização: 29/12/2023`;

export { terms, privacy };
