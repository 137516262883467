import React, { useEffect, useState } from "react";
import "./Groups.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function Groups() {
    const { adminProducts, adminBoxes, adminGroups } = useContext(UserContext);

    const [groups, setGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [modal, setModal] = useState(0);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [modalGroup, setModalGroup] = useState({});

    useEffect(() => {
        // const fetchGroups = async () => {
        //     try {
        //         const response = await fetch("/api/products/getgroups", {
        //             method: "GET",
        //         });
        //         const data = await response.json();
        //         setGroups(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchGroups();
        setGroups(adminGroups);
    }, [adminGroups]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch("/api/products/all", {
                    method: "GET",
                });
                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchProducts();
    }, []);

    const saveGroup = async () => {
        if (!modalGroup.name) return alert("insira o nome");
        const response = await fetch(
            `/api/products/${modal === 1 ? "addgroup/" : "editgroup/"}`,
            {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(modalGroup),
            }
        );
        setModal(0);
        const data = await response.json();
        setGroups(data);
    };

    return (
        <>
            <div
                className={`admin-modal-bg ${modalConfirm ? "shown" : ""}`}
                onClick={() => setModalConfirm(false)}
            >
                <div
                    className="admin-confirm-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="admin-modal-title">Tem certeza?</div>
                    <div className="admin-modal-confirm-btns">
                        <div
                            className="admin-modal-confirm-btn"
                            onClick={() => setModalConfirm(false)}
                        >
                            Não
                        </div>
                        <div
                            className="admin-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                setModalConfirm(false);
                                const response = await fetch(
                                    "/api/products/removegroup",
                                    {
                                        method: "post",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            id: modalGroup.id,
                                        }),
                                    }
                                );
                                const data = await response.json();
                                setGroups(data);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`new-group-modal-bg ${modal ? "shown" : ""}`}
                onMouseDown={() => {
                    setModal(0);
                    setModalGroup({});
                }}
            >
                <div
                    className="new-group-modal"
                    onMouseDown={(ev) => ev.stopPropagation()}
                >
                    <div className="new-group-name">
                        Nome:
                        <input
                            value={modalGroup.name || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    name: ev.target.value,
                                });
                            }}
                        />
                    </div>
                    Produtos: (
                    {
                        products?.filter((p) =>
                            modalGroup?.products?.includes(p.id)
                        )?.length
                    }
                    )
                    <div className="group-products">
                        {products.map((e) => {
                            return (
                                <div
                                    key={e.id}
                                    className={`group-product ${
                                        modalGroup.products &&
                                        modalGroup.products.includes(e.id)
                                            ? "selected"
                                            : ""
                                    }
                                    ${
                                        e.orders <= 0 && e.id < 3010
                                            ? "noorders"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        if (!modalGroup.products) {
                                            setModalGroup({
                                                ...modalGroup,
                                                products: [e.id],
                                            });
                                        } else {
                                            if (
                                                !modalGroup.products.includes(
                                                    e.id
                                                )
                                            ) {
                                                setModalGroup({
                                                    ...modalGroup,
                                                    products: [
                                                        ...modalGroup.products,
                                                        e.id,
                                                    ],
                                                });
                                            } else {
                                                setModalGroup({
                                                    ...modalGroup,
                                                    products:
                                                        modalGroup.products.filter(
                                                            (p) => p !== e.id
                                                        ),
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <div className="group-orders-number">
                                        {e.orders}
                                    </div>
                                    <img
                                        className="group-img"
                                        src={`/uploads/thumbs/${e.photos[0]}`}
                                    />
                                    <div className="group-sku">{e.sku}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="add-group-btns">
                        <div className="save-group" onClick={saveGroup}>
                            {modal === 1 ? "Criar" : "Alterar"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin-groups-container">
                {groups.map((group) => {
                    console.log(group);
                    return (
                        <div className="admin-group" key={group.id}>
                            <div>{group.name}</div>
                            <div
                                className="admin-group-edit"
                                onClick={() => {
                                    setModalGroup(group);
                                    setModal(2);
                                }}
                            >
                                Alterar
                            </div>
                            <div
                                className="admin-group-remove"
                                onClick={() => {
                                    setModalGroup(group);
                                    setModalConfirm(true);
                                }}
                            >
                                Remover
                            </div>
                        </div>
                    );
                })}
                <div
                    className="admin-group-add"
                    onClick={() => {
                        setModalGroup({});
                        setModal(1);
                    }}
                >
                    <div className="add-group-plus">+</div>
                    <div>Criar novo grupo</div>
                </div>
            </div>
        </>
    );
}
