import { isEmpty } from "./utils.js";

const userConfigs = {
    aistemNotification: {
        type: "bool",
        default: true,
        name: "Notificações do AI-stem",
        text: "Habilita as notificações do AI-stem.",
    },
    aistemOrderNotification: {
        type: "bool",
        default: true,
        name: "Notificar novos pedidos",
        text: "Habilita a notificação ao capturar um pedido.",
        dependsOn: "aistemNotification",
    },
    beerNotification: {
        type: "bool",
        default: true,
        name: "Som de sexta-feira",
        text: "Habilita um som de notificação diferenciado de escolha do AI-stem toda sexta-feira.",
        dependsOn: "aistemOrderNotification",
    },
    aistemVolume: { type: "slider", range: [0, 100], default: 100, name: "Volume da voz do AI-stem" },
};

function getConfig(user, config) {
    return isEmpty(user?.config?.[config]) ? userConfigs[config]?.default : user?.config?.[config];
}

export { getConfig, userConfigs };
