import React, { useLayoutEffect, useRef } from "react";

function TextArea(props) {
  const textbox = useRef(null);

  function adjustHeight() {
    setTimeout(() => {
        textbox.current.style.height = 'inherit';
        textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    }, 0);
  }

  useLayoutEffect(adjustHeight, []);

  return (
    <textarea
        value={props.value || ''}
        ref={textbox}
        className={props.className}
        onChange={(ev) => {
            adjustHeight();
            props.onChange && props.onChange(ev);
        }}
        onFocus={(ev) => {
            props.onFocus && props.onFocus(ev);
        }}
        style={{
            resize: "none"
        }}
    />
  );
}

export default TextArea;
