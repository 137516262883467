import { useContext, useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import NavbarAdmin from "../components/Admin/NavbarAdmin/NavbarAdmin";
import "./AdminLayout.css";

import { UserContext } from "../App.js";
import AdminAistem from "../components/Admin/AIstem/AIstem.js";

export default function AdminLayout(props) {
    const { plpPrinter, setPlpPrinter, etqPrinter, setEtqPrinter, zebraPrint } = useContext(UserContext);

    const testLabel = `^XA ^CF0,60 ^FO90,100^FDOrizom Etiqueta de Teste^FS ^FO50,250^GB700,3,3^FS ^CFA,30 ^FO50,300^FDCarlos Alberto^FS ^FO50,340^FDRua Manoel Vilaboim, 79^FS ^FO50,380^FDFrancisco Morato^FS ^FO50,420^FDSP - Brasil^FS ^CFA,15 ^FO600,300^GB150,150,3^FS ^FO638,340^FDTeste^FS ^FO638,390^FDOrizom^FS ^FO50,500^GB700,3,3^FS ^BY5,2,270 ^FO70,550^BC^FDORIZOM123^FS ^FO50,900^GB700,250,3^FS ^FO400,900^GB3,250,3^FS ^CF0,40 ^FO100,960^FDOrizom teste^FS ^FO100,1010^FDOrizom teste^FS ^FO100,1060^FDOrizom teste^FS ^CF0,190 ^FO420,955^FDORZ^FS ^XZ`;
    const etqTestLabel = `^XA ^CF0,30 ^FO10,20^FDOrizom Etiqueta de Teste^FS  ^BY2,2,80 ^FO25,80^BC^FDORIZOM123^FS^XZ`;

    const [configModal, setConfigModal] = useState(false);
    const [allPrinters, setAllPrinters] = useState({});

    const [BrowserPrint, setBrowserPrint] = useState(null);

    const [starbox, setStarbox] = useState(Cookies.get("starbox") !== "false");

    useEffect(() => {
        const script1 = document.createElement("script");
        script1.src = "/js/BrowserPrint-3.1.250.min.js";
        // script1.async = true;

        const script2 = document.createElement("script");
        script2.src = "/js/pdf/pdf.js";
        // script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);

        setTimeout(() => {
            setBrowserPrint(window.BrowserPrint);
        }, 1000);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    useEffect(() => {
        try {
            if (!BrowserPrint) return;
            // const BrowserPrint = window.BrowserPrint;
            BrowserPrint.getLocalDevices((devices) => {
                setAllPrinters(devices);
                if (!plpPrinter || (plpPrinter && !devices.printer.find((e) => e.uid == plpPrinter.uid))) {
                    if (Cookies.get("plpPrinter") && devices.printer.find((e) => e.uid == Cookies.get("plpPrinter"))) {
                        setPlpPrinter(devices.printer.find((e) => e.uid == Cookies.get("plpPrinter")));
                    } else {
                        setPlpPrinter(devices.printer[0]);
                    }
                }
                if (!etqPrinter || (etqPrinter && !devices.printer.find((e) => e.uid == etqPrinter.uid))) {
                    if (Cookies.get("etqPrinter") && devices.printer.find((e) => e.uid == Cookies.get("etqPrinter"))) {
                        setEtqPrinter(devices.printer.find((e) => e.uid == Cookies.get("etqPrinter")));
                    } else {
                        setEtqPrinter(devices.printer[0]);
                    }
                }
            });
        } catch (err) {
            console.error(err);
        }
    }, [configModal, BrowserPrint]);

    return (
        <>
            <div className={`admin-config-modal-bg ${configModal ? "shown" : ""}`} onClick={() => setConfigModal(false)}>
                <div className="admin-config-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="admin-autoprint-config">
                        <div
                            className={`switch ${starbox ? "checked" : ""}`}
                            onClick={() => {
                                setStarbox(!starbox);
                                Cookies.set("starbox", starbox ? "false" : "true", { expires: 9999 });
                            }}
                        >
                            <span className="slider" />
                        </div>
                        Imprimir automaticamente na STARBOX
                    </div>
                    <div className="admin-config-printers">
                        <div
                            className="admin-printer-test"
                            onClick={() => {
                                plpPrinter.send(testLabel);
                            }}
                        >
                            Testar
                        </div>
                        <div className="admin-config-printers-title">Impressora da PLP/NFe (10 x 15cm)</div>
                        {allPrinters.printer
                            ? allPrinters.printer.map((printer) => {
                                  return (
                                      <div
                                          className={`admin-config-printer ${
                                              plpPrinter && allPrinters.printer && printer.uid === plpPrinter.uid
                                                  ? "selected"
                                                  : ""
                                          }`}
                                          key={printer.uid}
                                          onClick={() => {
                                              setPlpPrinter(printer);
                                              Cookies.set("plpPrinter", printer.uid, { expires: 2000 });
                                          }}
                                      >
                                          <div>{printer.name}</div>
                                          <div>{printer.manufacturer}</div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                    <div className="admin-config-printers">
                        <div
                            className="admin-printer-test"
                            onClick={() => {
                                etqPrinter.send(etqTestLabel);
                            }}
                        >
                            Testar
                        </div>
                        <div className="admin-config-printers-title">Impressora das Etiquetas (2,5 x 4cm)</div>
                        {allPrinters.printer
                            ? allPrinters.printer.map((printer) => {
                                  return (
                                      <div
                                          className={`admin-config-printer ${
                                              etqPrinter && allPrinters.printer && printer.uid === etqPrinter.uid
                                                  ? "selected"
                                                  : ""
                                          }`}
                                          key={printer.uid}
                                          onClick={() => {
                                              setEtqPrinter(printer);
                                              Cookies.set("etqPrinter", printer.uid, { expires: 2000 });
                                          }}
                                      >
                                          <div>{printer.name}</div>
                                          <div>{printer.manufacturer}</div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
            </div>
            <AdminAistem fullscreen={props.fullscreen} />
            <NavbarAdmin location={useLocation()} user={props.user} setConfigModal={setConfigModal} />
            <div className="content">
                <Outlet context={[plpPrinter, etqPrinter, zebraPrint]} />
            </div>
        </>
    );
}
