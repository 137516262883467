const allCategories = [
    {
        id: 0,
        name: "Relógio",
        blingName: "RELOGIOS",
        ncm: 91022900,
        fields: [
            0, // Marca
            1, // Modelo
            9, // Modelo detalhado
            12, // Gênero
            13, // Cor da caixa
            14, // Material da correia
            15, // Tipo de fecho
            16, // Peso (kg)
            17, // Tipo de movimento
            18, // Tipos de tela
            19, // Materiais da caixa
            20, // É resistente à água
            21, // É adequado para mergulho
            22, // Profundidade de resistência à água (m)
            23, // Tipo de bateria
            55, // Cor das agulhas
            56, // Cor dos subdials
            57, // Cor da luz
            62, // Cor do dial de horas
            63, // Cor do dial de minutos/segundos
        ],
        mlCode: "MLB26426",
        attributes: {
            name: "Cor da correia",
            value_name: "Único",
        },
    },
    {
        id: 1,
        name: "Bonés",
        blingName: "BONES",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            8, // Tamanho
            12, // Gênero
            27, // Cor principal
            28, // Tipo de chapéu
            29, // Temporada de lançamento
            30, // Ano de lançamento
            31, // Com fechamento ajustável
            58, // Com materiais recicláveis
        ],
        mlCode: "MLB430275",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
    {
        id: 3,
        name: "Colar",
        blingName: "COLARES",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            2, // Linha
            25, // Estilos
            37, // Com pedra
            38, // Tipo de pedra
            42, // Material
            43, // Formato de venda
            44, // Unidades por kit
            46, // Comprimento (mm)
            48, // Diâmetro
            50, // Altura (cm)
            53, // Desenho
            54, // Acabamento
            64, // Inclui caixa
        ],
        mlCode: "MLB7017",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
    {
        id: 4,
        name: "Brinco",
        blingName: "BRINCOS",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            25, // Estilos
            37, // Com pedra
            38, // Tipos de pedras
            42, // Material
            43, // Formato de venda
            44, // Unidades por kit
            45, // Tipo de brinco
            46, // Comprimento (mm)
            47, // Largura (mm)
            48, // Diâmetro (mm)
        ],
        mlCode: "MLB1432",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
    {
        id: 5,
        name: "Carteira",
        blingName: "CARTEIRAS",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            12, // Gênero
            41, // Largura (cm)
            42, // Material
            49, // Tipo de produto
            50, // Altura (cm)
            51, // Profundidade (cm)
            52, // Com porta moedas
        ],
        mlCode: "MLB28108",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
    {
        id: 6,
        name: "Óculos",
        blingName: "OCULOS",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            2, // Linha
            3, // Material da lente
            4, // Tratamento da lente
            5, // Com lente polarizada
            6, // Material da armação
            7, // Material da haste
            8, // Tamanho
            9, // Modelo detalhado
            10, // Com proteção UV
            11, // Forma da armação
            12, // Gênero
        ],
        mlCode: "MLB8378",
        attributes: {
            name: "Cor da haste",
            value_name: "Único",
        },
    },
    {
        id: 7,
        name: "Pulseira",
        blingName: "PULSEIRAS",
        ncm: 71179000,
        fields: [
            0, // Marca
            1, // Modelo
            2, // Linha
            8, // Tamanho
            9, // Modelo detalhado
            12, // Gênero
            15, // Tipo de fecho
            16, // Peso
            24, // Materiais
            25, // Estilos
            26, // Com berloque
            39, // Tipo de acessório
            42, // Material
            43, // Formato de venda
            44, // Unidades por kit
            54, // Acabamento
        ],
        mlCode: "MLB1434",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
    {
        id: 10,
        name: "Lousa mágica",
        blingName: "LOUSAS MAGICAS",
        ncm: 96100000,
        fields: [
            0, // Marca
            1, // Modelo
            24, // Materiais
            33, // Comprimento (cm)
            41, // Largura (cm)
            59, // Inclui caneta
            60, // Personagem
            61, // Idade minima recomendada
        ],
        mlCode: "MLB434462",
        attributes: {
            name: "Cor",
            value_name: "Único",
        },
    },
];

const allFields = [
    { id: 0, internalName: "BRAND", name: "Marca", type: 4 },
    { id: 1, internalName: "MODEL", name: "Modelo", type: 4 },
    { id: 2, internalName: "LINE", name: "Linha", type: 4 },
    { id: 3, internalName: "LENS_MATERIAL", name: "Material da lente", type: 4 },
    { id: 4, internalName: "LENS_TREATMENT", name: "Tratamento da lente", type: 4 },
    { id: 5, internalName: "WITH_POLARIZED_LENS", name: "Com lente polarizada", type: 6 },
    { id: 6, internalName: "FRAME_MATERIAL", name: "Material da armação", type: 4 },
    { id: 7, internalName: "TEMPLE_MATERIAL", name: "Material da haste", type: 4 },
    { id: 8, internalName: "SIZE", name: "Tamanho", type: 4 },
    { id: 9, internalName: "DETAILED_MODEL", name: "Modelo detalhado", type: 4 },
    { id: 10, internalName: "WITH_UV_PROTECTION", name: "Com proteção UV", type: 6 },
    { id: 11, internalName: "FRAME_SHAPE", name: "Forma da armação", type: 4 },
    {
        id: 12,
        internalName: "GENDER",
        name: "Gênero",
        type: 3,
        options: [
            { id: 0, name: "Meninas" },
            { id: 1, name: "Feminino" },
            { id: 2, name: "Masculino" },
            { id: 3, name: "Sem gênero" },
            { id: 4, name: "Meninos" },
            { id: 5, name: "Sem gênero infantil" },
            { id: 6, name: "N/A" },
        ],
    },
    { id: 13, internalName: "CASE_COLOR", name: "Cor da caixa", type: 4 },
    { id: 14, internalName: "STRAP_MATERIAL", name: "Material da correia", type: 4 },
    {
        id: 15,
        internalName: "CLASP_TYPE",
        name: "Tipo de fecho",
        type: 3,
        options: [
            { id: 0, name: "Velcro" },
            { id: 1, name: "Imã" },
            { id: 2, name: "Pulseira" },
            { id: 3, name: "Expansível" },
            { id: 4, name: "Dobrável" },
            { id: 5, name: "Fivela dupla" },
            { id: 6, name: "Borboleta" },
            { id: 7, name: "Fivela simples" },
            { id: 8, name: "Fivela de gancho" },
            { id: 9, name: "Fivela de liberação" },
            { id: 10, name: "N/A" },
        ],
    },
    { id: 16, internalName: "WEIGHT", name: "Peso (kg)", type: 4, unit: "kg" },
    { id: 17, internalName: "MOVEMENT_TYPE", name: "Tipo de movimento", type: 4 },
    { id: 18, internalName: "DISPLAY_TYPES", name: "Tipos de tela", type: 4 },
    { id: 19, internalName: "CASE_MATERIALS", name: "Materiais da caixa", type: 4 },
    { id: 20, internalName: "IS_WATER_RESISTANT", name: "É resistente à água", type: 6 },
    {
        id: 21,
        internalName: "IS_SUITABLE_FOR_DIVING",
        name: "É adequado para mergulho",
        type: 6,
    },
    {
        id: 22,
        internalName: "WATER_RESISTANCE_DEPTH",
        name: "Profund. de resistência à água (m)",
        type: 4,
        unit: "m",
    },
    { id: 23, internalName: "BATTERY_TYPE", name: "Tipo de bateria", type: 4 },
    { id: 24, internalName: "", name: "Materiais", type: 4 },
    { id: 25, internalName: "STYLES", name: "Estilos", type: 4 },
    { id: 26, internalName: "", name: "Com berloque", type: 6 },
    {
        id: 27,
        internalName: "",
        name: "Cor principal",
        type: 3,
        options: [
            { id: 0, name: "Preto" },
            { id: 1, name: "N/A" },
        ],
    },
    { id: 28, internalName: "", name: "Tipo de chapéu", type: 4 },
    {
        id: 29,
        internalName: "",
        name: "Temporada de lançamento",
        type: 3,
        options: [
            { id: 0, name: "Primavera/Verão" },
            { id: 1, name: "Outono/Inverno" },
            { id: 2, name: "N/A" },
        ],
    },
    { id: 30, internalName: "", name: "Ano de lançamento", type: 4 },
    {
        id: 31,
        internalName: "",
        name: "Com fechamento ajustável",
        type: 6,
    },
    { id: 32, internalName: "", name: "Material da cadeia", type: 4 },
    { id: 33, internalName: "LENGTH", name: "Comprimento (cm)", type: 4, unit: "cm" },
    {
        id: 34,
        internalName: "",
        name: "Unidades por embalagem",
        type: 4,
    },
    { id: 35, internalName: "", name: "With pendant", type: 6 },
    { id: 36, internalName: "", name: "Material do pingente", type: 4 },
    { id: 37, internalName: "WITH_GEMSTONE", name: "Com pedra", type: 6 },
    { id: 38, internalName: "GEMSTONE_TYPE", name: "Tipos de pedras", type: 4 },
    {
        id: 39,
        internalName: "",
        name: "Tipo de acessório",
        type: 3,
        options: [
            { id: 0, name: "Pulseira" },
            { id: 1, name: "Tornozeleira" },
            { id: 2, name: "Pulseira/Tornozeleira" },
        ],
    },
    { id: 40, internalName: "", name: "Cor da fivela", type: 4 },
    { id: 41, internalName: "", name: "Largura (cm)", type: 2 },
    { id: 42, internalName: "MATERIAL", name: "Material", type: 4 },
    {
        id: 43,
        internalName: "SALE_FORMAT",
        name: "Formato de venda",
        type: 3,
        options: [
            { id: 0, name: "Unidade" },
            { id: 1, name: "Kit" },
            { id: 2, name: "N/A" },
        ],
    },
    { id: 44, internalName: "UNITS_PER_PACK", name: "Unidades por kit", type: 4 },
    { id: 45, internalName: "", name: "Tipo de brinco", type: 4 },
    { id: 46, internalName: "LENGTH", name: "Comprimento (mm)", type: 4, unit: "mm" },
    { id: 47, internalName: "", name: "Largura (mm)", type: 2 },
    { id: 48, internalName: "DIAMETER", name: "Diâmetro (mm)", type: 4, unit: "mm" },
    {
        id: 49,
        internalName: "",
        name: "Tipo de produto",
        type: 3,
        options: [
            { id: 0, name: "Carteira" },
            { id: 1, name: "Porta-moedas" },
        ],
    },
    { id: 50, internalName: "HEIGHT", name: "Altura (cm)", type: 4, unit: "cm" },
    { id: 51, internalName: "", name: "Profundidade (cm)", type: 2 },
    { id: 52, internalName: "", name: "Com porta moedas", type: 6 },
    { id: 53, internalName: "DESIGN", name: "Desenho", type: 4 },
    { id: 54, internalName: "FINISH", name: "Acabamento", type: 4 },
    { id: 55, internalName: "NEEDLES_COLOR", name: "Cor das agulhas", type: 4 },
    { id: 56, internalName: "SUBDIALS_COLOR", name: "Cor dos subdials", type: 4 },
    { id: 57, internalName: "LIGHT_COLOR", name: "Cor da luz", type: 4 },
    { id: 58, internalName: "", name: "Com materiais recicláveis", type: 6 },
    { id: 59, internalName: "", name: "Inclui caneta", type: 6 },
    { id: 60, internalName: "", name: "Personagem", type: 4 },
    { id: 61, internalName: "RECOMMENDED_AGE_GROUP", name: "Idade minima recomendada", type: 4 },
    { id: 62, internalName: "DIAL_HOURS_COLOR", name: "Cor do dial de horas", type: 4 },
    {
        id: 63,
        internalName: "DIAL_MINUTES_SECONDS_COLOR",
        name: "Cor do dial de minutos/segundos",
        type: 4,
    },
    { id: 64, internalName: "INCLUDES_BOX", name: "Inclui caixa", type: 6 },
];

export { allCategories, allFields };
