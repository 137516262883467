import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";

import "./Login.css";
import Einstein from "../../../components/Einstein/Einstein";

export default function AdminLogin() {
    Cookies.remove("jwtAdmin");

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [modalTerms, setModalTerms] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false);

    const handleLogin = async (event) => {
        try {
            event.preventDefault();
            axios
                .post("/api/users/adminlogin", {
                    email: email,
                    password: password,
                })
                .then((res) => {
                    if (res.data.token) {
                        window.location.href = searchParams.get("redirect") || "/admin";
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        alert(err.response.data.message);
                    } else {
                        alert(err);
                    }
                });
            return;

            // const data = await response.json();
            // if (data.token) {
            //     document.cookie = `token=${data.token};max-age=604800;path=/admin`;
            //     navigate(searchParams.get('redirect') || '/', { replace: true });
            // } else {
            //     setOpen(true);
            //     alert(data.message);
            // }
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     ReactPixel.init("320984243594077", null, pixelOptions);
    //     ReactPixel.pageView();
    // }, []);

    return (
        <>
            <div className="login-container">
                {/* <div className="admin-login-video">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/UiYfeAPudXU?rel=0&color=white&iv_load_policy=3"
                        title="Orizom"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                        allowfullscreen={true}
                    ></iframe>
                </div> */}
                <div className={`admin-login-page`}>
                    {/* <img
                    className="drop-logo-login"
                    src="/img/logo-orizom.svg"
                    alt="Logo da Orizom Drop"
                /> */}

                    <Einstein className="login-einstein" type="login" />
                    <div className="admin-login-title">ADMIN</div>
                    <form onSubmit={handleLogin}>
                        <div className="admin-login-container">
                            <input
                                type="text"
                                placeholder="Insira o usuário"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />

                            <input
                                type="password"
                                placeholder="Insira a senha"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />

                            <button type="submit">Entrar</button>
                            {/*<div className="forgot-password">
                            Esqueci minha senha
            </div>*/}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
