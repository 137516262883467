import React, { useEffect, useState } from "react";
import "./Boxes.css";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

export default function Groups() {
    const { adminProducts, adminBoxes, adminGroups } = useContext(UserContext);

    const [groups, setGroups] = useState([]);
    const [modal, setModal] = useState(0);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [loadingPhoto, setLoadingPhoto] = useState(false);

    const [modalGroup, setModalGroup] = useState({});

    useEffect(() => {
        // const fetchGroups = async () => {
        //     try {
        //         const response = await fetch("/api/products/getboxes", {
        //             method: "GET",
        //         });
        //         const data = await response.json();
        //         setGroups(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchGroups();
        setGroups(adminBoxes);
    }, [adminBoxes]);

    const saveGroup = async () => {
        if (!modalGroup.name) return alert("insira o nome");
        if (!modalGroup.text) return alert("insira o texto");
        if (!modalGroup.price) return alert("insira o preço");
        if (!modalGroup.photo) return alert("insira a foto");
        if (!modalGroup.icon) return alert("insira o icone");

        const response = await fetch(
            `/api/products/${modal === 1 ? "addbox/" : "editbox/"}`,
            {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(modalGroup),
            }
        );
        setModal(0);
        const data = await response.json();
        setGroups(data);
    };

    const handleFileChange = async (e) => {
        e.preventDefault();
        // setLoadingPhoto(true);
        // Enviar a foto ao servidor
        const formData = new FormData();
        formData.append("photo", e.target.files[0]);

        fetch("/api/products/uploadimage", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.photo);
                setModalGroup({ ...modalGroup, photo: data.photo.filename });
                setLoadingPhoto(false);
                e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload da foto");
                setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    const handleFileChangeIcon = async (e) => {
        e.preventDefault();
        // setLoadingPhoto(true);
        // Enviar a foto ao servidor
        const formData = new FormData();
        formData.append("photo", e.target.files[0]);

        fetch("/api/products/uploadimage", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.photo);
                setModalGroup({ ...modalGroup, icon: data.photo.filename });
                setLoadingPhoto(false);
                e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload da foto");
                setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    return (
        <>
            <div
                className={`admin-modal-bg ${modalConfirm ? "shown" : ""}`}
                onClick={() => setModalConfirm(false)}
            >
                <div
                    className="admin-confirm-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <div className="admin-modal-title">Tem certeza?</div>
                    <div className="admin-modal-confirm-btns">
                        <div
                            className="admin-modal-confirm-btn"
                            onClick={() => setModalConfirm(false)}
                        >
                            Não
                        </div>
                        <div
                            className="admin-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                setModalConfirm(false);
                                const response = await fetch(
                                    "/api/products/removebox",
                                    {
                                        method: "post",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            id: modalGroup.id,
                                        }),
                                    }
                                );
                                const data = await response.json();
                                setGroups(data);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`new-group-modal-bg ${modal ? "shown" : ""}`}
                onMouseDown={() => {
                    setModal(0);
                    setModalGroup({});
                }}
            >
                <div
                    className="new-group-modal"
                    onMouseDown={(ev) => ev.stopPropagation()}
                >
                    <div className="new-group-name">
                        Nome:
                        <input
                            value={modalGroup.name || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    name: ev.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="new-group-name">
                        Texto explicativo:
                        <textarea
                            value={modalGroup.text || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    text: ev.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="new-group-name">
                        Preço:
                        <input
                            value={modalGroup.price || ""}
                            onChange={(ev) => {
                                setModalGroup({
                                    ...modalGroup,
                                    price: ev.target.value,
                                });
                            }}
                            type="number"
                        />
                    </div>

                    <label className={`admin-product-photo`}>
                        Foto
                        {modalGroup.photo ? (
                            <img
                                src={`/uploads/internal/${modalGroup.photo}`}
                            />
                        ) : (
                            <>
                                <span>+</span>Adicionar
                            </>
                        )}
                        <input
                            className="add-photo-btn"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>

                    <label className={`admin-product-photo`}>
                        Icone
                        {modalGroup.icon ? (
                            <img src={`/uploads/internal/${modalGroup.icon}`} />
                        ) : (
                            <>
                                <span>+</span>Adicionar
                            </>
                        )}
                        <input
                            className="add-photo-btn"
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChangeIcon}
                        />
                    </label>

                    <div className="add-group-btns">
                        <div className="save-group" onClick={saveGroup}>
                            {modal === 1 ? "Criar" : "Alterar"}
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin-groups-container">
                {groups.map((group) => {
                    console.log(group);
                    return (
                        <div className="admin-group" key={group.id}>
                            <div>{group.name}</div>
                            <img src={`/uploads/internal/${group.photo}`} />
                            <div
                                className="admin-group-edit"
                                onClick={() => {
                                    setModalGroup(group);
                                    setModal(2);
                                }}
                            >
                                Alterar
                            </div>
                            <div
                                className="admin-group-remove"
                                onClick={() => {
                                    setModalGroup(group);
                                    setModalConfirm(true);
                                }}
                            >
                                Remover
                            </div>
                        </div>
                    );
                })}
                <div
                    className="admin-group-add"
                    onClick={() => {
                        setModalGroup({});
                        setModal(1);
                    }}
                >
                    <div className="add-group-plus">+</div>
                    <div>Criar nova caixa</div>
                </div>
            </div>
        </>
    );
}
