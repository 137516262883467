const calcPrice = (marketplace, price, profit, tax, fixedProfit) => {
    let x = price;

    switch (marketplace) {
        case 0:
        case 1:
            x = (price * (1 + profit) + 6 + fixedProfit) / (1 - 0.19 - tax);
            if (x > 79) x = (price * (1 + profit) + 24 + fixedProfit) / (1 - 0.19 - tax);
            break;
        case 2:
            x = (price * (1 + profit) + 5 + fixedProfit) / (1 - 0.2 - tax);
            if (x > 79) x = (price * (1 + profit) + 30.9 + fixedProfit) / (1 - 0.2 - tax);
            break;
        case 3:
        case 4:
            x = (price * (1 + profit) + 5 + fixedProfit) / (1 - 0.2 - tax);
            break;
        case 5:
            x = (price * (1 + profit) + fixedProfit) / (1 - 0.28 - tax);
            if (x > 39) x = (price * (1 + profit) + 13 + fixedProfit) / (1 - 0.28 - tax);
            if (x > 89) x = (price * (1 + profit) + 38 + fixedProfit) / (1 - 0.28 - tax);
            break;
        case 6:
            x = (price * (1 + profit) + 3 + fixedProfit) / (1 - 0.2 - tax);
            break;
        case 7:
            x = (price * (1 + profit) + fixedProfit) / (1 - 0.3 - tax);
            break;
        default:
            x = (price * (1 + profit) + 5 + fixedProfit) / (1 - 0.2 - tax);
            break;
    }
    return x;
};

export default calcPrice;
