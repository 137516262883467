import { useState } from "react";

import "./Errors.css";
import Einstein from "../../components/Einstein/Einstein";

import { terms, privacy } from "../../config/terms.js";

export default function BlingError() {
    const [modalTerms, setModalTerms] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false);

    return (
        <>
            <div className={`terms-modal-bg ${modalTerms ? "shown" : ""}`} onClick={() => setModalTerms(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Termos de uso</div>
                    <div className="terms-modal-container">{terms}</div>
                </div>
            </div>
            <div className={`terms-modal-bg ${modalPrivacy ? "shown" : ""}`} onClick={() => setModalPrivacy(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Privacidade</div>
                    <div className="terms-modal-container">{privacy}</div>
                </div>
            </div>
            <div className="login-container">
                <div className={`admin-login-page`}>
                    <Einstein className="login-einstein" type="login" />

                    <div className="login-setbling error">
                        <img src="/img/orders/bling.svg" />
                        <div className="bling-error">Ocorreu um erro ao integrar a sua conta da Orizom com o Bling!</div>
                        Não foi possível integrar sua conta no momento. Por favor, tente novamente dentro de alguns minutos, ou
                        caso o erro persista, entre em contato com o suporte da Orizom clicando{" "}
                        <a
                            target="_blank"
                            href="https://wa.me/5511953983688?text=Carlos!%20Estou%20com%20dificuldades%20para%20integrar%20meu%20bling,%20esta%20mostrando%20uma%20tela%20de%20erro.%20Poderia%20me%20ajudar?"
                        >
                            aqui.
                        </a>
                    </div>

                    <div className="terms">
                        <div className="terms-of-use" onClick={() => setModalTerms(true)}>
                            Termos de uso
                        </div>
                        <div className="privacy-policy" onClick={() => setModalPrivacy(true)}>
                            Privacidade
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
