import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Dashboard.css';

import TextArea from '../../components/TextArea/TextArea.js';

import { TypeAnimation } from 'react-type-animation';
import CopyDiv from '../../components/CopyDiv/CopyDiv';

const metrics = [
    {id: 0, name: "Faturamento", value: 2548.9, percentage: 7, color: "#C2BC4B", type: 0},
    {id: 1, name: "Vendas", value: 18, percentage: -3, color: "#FF9631", type: 1},
    {id: 2, name: "Ticket Médio", value: 90.1, percentage: 7, color: "#0192F9", type: 0},
    {id: 3, name: "Lucro", value: 1250, percentage: 12, color: "#76C041", type: 0}
]

const marketplaces = [
    "/img/description/ml.svg",
    "/img/description/ml.svg",
    "/img/description/magalu.svg",
    "/img/description/amazon3.svg",
    "/img/description/olist.svg",
    "/img/description/b2w3.svg",
    "/img/description/shopee2.svg",
    "/img/description/outros4.svg",
]

const statuses = {
    0: {id: 0, text: 'Processando', color: '#212121', information: 'Seu pedido está sendo embalado na velocidade da luz e logo estará disponível para coleta/envio.'},
    1: {id: 1, text: 'Embalado', color: '#1567D2', information: 'Seu pedido já foi embalado. Acompanhe o envio pelo marketplace ou pelo site da transportadora.'},
    2: {id: 2, text: 'Cancelado', color: '#f00', information: 'Seu pedido foi cancelado.'},
}

export default function Dashboard(props) {

    const [modalStory, setModalStory] = useState(false);
    const [modalQuestion, setModalQuestion] = useState(false);
    const [modalSoldOut, setModalSoldOut] = useState(false);

    const [question, setQuestion] = useState(false);

    const [hideQuestion, setHideQuestion] = useState(false);

    const [old, setOld] = useState(true);
    const [period, setPeriod] = useState(0);
    const [metric, setMetric] = useState(0);
    const [chartValues, setChartValues] = useState(Array.from({length: 30}, () => Math.floor(Math.random() * 300)));
    
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
          try {
            const response = await fetch('/api/orders/mine', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt'),
              },
            });
            const data = await response.json();
            setOrders([...data]);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchOrders();
      }, []);

    useEffect(() => {
        setTimeout(() => {
            setChartValues(Array.from({length: 30}, () => Math.floor(Math.random() * 300) + 200));
            setOld(false);
        }, 1000)
      }, []);
      

    return (
        <>
        <div className={`dashboard-modal-story-bg ${modalQuestion ? 'shown' : ''}`} onClick={() => setModalQuestion(false)}>
            <div className='dashboard-modal-question' onClick={(ev) => ev.stopPropagation()}>
                {/* <textarea placeholder="Digite sua dúvida referente a qualquer coisa que a gente te responde, preferenciamente por vídeo explicativo!"/> */}
                <div className='question-container'>
                <div className={`question question-placeholder ${hideQuestion ? 'hidden' : ''}`}>
                    {modalQuestion ? <TypeAnimation
                        sequence={[
                        200,
                        "Digite sua dúvida que a gente te responde, e se necessário anexe imagens ou vídeos!\nEx: Faz um stories de como integrar o bling com magalu?",
                        2000,
                        "Digite sua dúvida que a gente te responde, e se necessário anexe imagens ou vídeos!\nEx: Como faço pra vender no Mercado Livre?",
                        2000,
                        "Digite sua dúvida que a gente te responde, e se necessário anexe imagens ou vídeos!\nEx: Como integro minha conta com o Bling?",
                        2000,
                        "Digite sua dúvida que a gente te responde, e se necessário anexe imagens ou vídeos!\nEx: Como consigo a etiqueta ZPL do pedido?",
                        2000,
                        ]}
                        repeat={Infinity}
                        speed={{ type: 'keyStrokeDelayInMs', value: 10 }}
                    /> : ''}
                    </div>
                    {modalQuestion ? <TextArea className='question' onFocus={() => setHideQuestion(true)} value={question} onChange={(ev) => {
                        setQuestion(ev.target.value.slice(0,350));
                    }}/> : ''}
                    <div className={`dashboard-question-length ${question.length > 349 ? 'question-red' : question.length > 299 ? 'question-yellow' : ''}`}>{350 - question.length}</div>
                </div>
                <div className='dashboard-question-buttons'>
                    <img src="/img/dashboard/clip.svg" className='question-attach'/>
                    <div className='dashboard-question-send'>Enviar</div>
                </div>
            </div>
        </div>
        <div className={`dashboard-modal-story-bg ${modalSoldOut ? 'shown' : ''}`} onClick={() => setModalSoldOut(false)}>
            <div className='dashboard-modal-sold-out' onClick={(ev) => ev.stopPropagation()}>
                Produtos esgotados nessa semana
                <div className='dashboard-modal-sold-out-container'>
                    {Array(20).fill('').map(e => {
                        return (
                            <CopyDiv className='dashboard-modal-sold-out-product'>
                                <div className='dashboard-modal-sold-out-sku'>OSM64</div>
                                <img src="/uploads/products/1690639441881.jpg"/>
                            </CopyDiv>
                        );
                    })}
                </div>
                <div className='dashboard-modal-sold-out-explanation'>
                    <img src="/img/dashboard/einstein.svg"/>
                    {modalSoldOut ? <TypeAnimation
                        sequence={[
                        1000,
                        `${props.user && props.user.name.split(' ')[0]}, fique tranquilo!\nJá pausei os anúncios contendo esses produtos acima em sua conta do Mercado Livre e zerei o estoque no Bling! `,
                        ]}
                        speed={{ type: 'keyStrokeDelayInMs', value: 25 }}
                    /> : ''}
                </div>
            </div>
        </div>
        <div className={`dashboard-modal-story-bg ${modalStory ? 'shown' : ''}`} onClick={() => setModalStory(false)}>
            <div className='dashboard-modal-story' onClick={(ev) => ev.stopPropagation()}>
                {modalStory ? <Video src="/img/teste/videogato.mp4" autoplay="true" key="1"/> : <Video src="/img/teste/videogato.mp4" key="2"/>}
            </div>
        </div>
        <div className='dashboard-lateral-menu'>
            <div className='dashboard-lateral-container'>
                <div className='dashboard-stories-container'>
                    <span className='dashboard-stories-new' onClick={() => {
                        setQuestion('');
                        setHideQuestion(false);
                        setModalQuestion(true);
                    }}>
                        <div className='dashboard-stories-new-text'>Qual sua dúvida hoje?</div>
                        <div className='dashboard-stories-plus'>+</div>
                    </span>
                    <div className='dashboard-stories'>
                        <Story img="/img/teste/img1.jpg" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img2.jpeg" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img3.png" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img4.jpg" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img5.jpeg" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img6.jpg" setModalStory={setModalStory}/>
                        <Story img="/img/teste/img7.jpg" setModalStory={setModalStory}/>
                    </div>
                </div>
                <div className='dashboard-sold-out-container' onClick={() => setModalSoldOut(true)}>
                    <div className='dashboard-sold-out-title'>Produtos esgotados</div>
                    <div className='dashboard-sold-out'>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                        <SoldOut img="/uploads/products/1690639441881.jpg"/>
                    </div>
                </div>
                <div className='dashboard-orders'>
                    <div className='dashboard-order-line'></div>
                    <Link to='/pedidos' className='dashboard-order-more'>Ver todos</Link>
                    {orders.slice(0,6).map(e => {
                        return (
                            <Link to='/pedidos' className='dashboard-order'>
                                <div className='dashboard-order-img'>
                                    <img src={`/uploads/products/${e.order_products[0] && e.order_products[0].product ? e.order_products[0].product.photos[0] : ''}`}/>
                                    <div className='dashboard-order-sku'>{e.order_products.length < 2 ? (e.order_products[0] && e.order_products[0].product ? e.order_products[0].product.sku : '') : 'Diversos'}</div>
                                </div>
                                <div className='dashboard-order-number'>
                                    {(e.id + '').padStart(6, 0)}
                                    <div className='dashboard-order-marketplace'>
                                        <img src={marketplaces[e.marketplace]}/>
                                        <div className={`dashboard-order-message ${Math.random() > 0.5 ? 'new-messages' : ''}`}>
                                            <img src='/img/orders/message.svg' className='dashboard-no-message'/>
                                            <div className='dashboard-with-message'>
                                                <img src='/img/orders/message-filled.svg'/>
                                                <span className='dashboard-message-qtty'>{Math.round(Math.random() * 9 + 1)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='dashboard-order-price'>
                                    Vendido
                                    <div className='dashboard-order-value'><small>R$ </small> {(e.price * 1.4).toFixed(2).replace('.', ',')}</div>
                                </div>
                                <div className='dashboard-order-status' style={{backgroundColor: statuses[0].color}}>{statuses[0].text}</div>
                            </Link>
                        );
                    })}
                    </div>
                </div>
            </div>
            <div className='dashboard-center'>
                <div className='dashboard-center-content'>
                    <div className='dashboard-period-select'>
                        <div className={`dashboard-period-option ${period == 0 ? 'selected' : ''}`} onClick={() => setPeriod(0)}>Hoje</div>
                        <div className={`dashboard-period-option ${period == 1 ? 'selected' : ''}`} onClick={() => setPeriod(1)}>Semana</div>
                        <div className={`dashboard-period-option ${period == 2 ? 'selected' : ''}`} onClick={() => setPeriod(2)}>Mês</div>
                        <div className={`dashboard-period-option ${period == 3 ? 'selected' : ''}`} onClick={() => setPeriod(3)}>Total</div>
                        <div className='dashboard-period-ball' style={{left: `${(period * 1.6 + 1) * 15}%`}}></div>
                    </div>
                    <div className='dashboard-metrics'>
                        {metrics.map(e => {
                            let color = e.percentage > 0 ? '#94E858' : '#ff5555';
                            return (<div className={`dashboard-metric ${metric == e.id ? 'selected' : ''}`} onClick={() => setMetric(e.id)}>
                                <div className='metric-row'>
                                    <div className='metric-name'>{e.name}</div>
                                    <div className='metric-percentage' style={{color: color}}>
                                        <svg style={{transform: `rotateZ(${e.percentage > 0 ? '0deg' : '180deg'})`}} fill={color} stroke={color} height="11px" width="11px" version="1.1" viewBox="0 0 356.40 356.40" stroke-width="16"><path id="XMLID_29_" d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394 C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75 c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"></path></svg>
                                        {e.percentage}%
                                    </div>
                                </div>
                                <div className='metric-row metric-value'>
                                    <span>{e.type == 0 ? <><small>R$ </small>{(e.value * 1.4).toFixed(2).replace('.', ',')}</> : e.value}</span>
                                </div>
                            </div>);
                        })}
                </div>
                <div className='chart-month-container'>{old ? <div className='chart-month' key='old'>Mês anterior</div> : <div className='chart-month'>Mês atual</div>}</div>
                <Chart
                    divisionsY={5}
                    values={chartValues}
                    old={old}
                    type='money'
                    onClick={() => {
                        setChartValues(Array.from({length: 30}, () => Math.floor(Math.random() * 200)));
                        setOld(!old);
                }}/>
                <div className='dashboard-details-title'>Detalhamento {period == 0 ? 'de hoje' : period == 1 ? 'da semana' : period == 2 ? 'do mês' : 'total'}</div>
                <div className='dashboard-details-container'>
                    <div className='dashboard-details'>
                        {Array(20).fill('').map((e, i) => {
                            return (<div className='dashboard-details-item'>
                                <div className='dashboard-details-photo'>
                                    <img src='/uploads/products/1690589333514.jpg'/>
                                    <div className='dashboard-details-sku'>RLA26C</div>
                                </div>
                                <div className='dashboard-details-col'><small>x </small>2</div>
                                <div className='dashboard-details-prices'>
                                    <div className='dashboard-details-price'>
                                        Pago <div className='dashboard-details-value'><small>R$ </small>39</div>
                                    </div>
                                    <div className='dashboard-details-price'>
                                        Vendido <div className='dashboard-details-value'><small>R$ </small>52,90</div>
                                    </div>
                                </div>
                                <div className='dashboard-details-prices'>
                                    <div className='dashboard-details-price dashboard-details-total'>
                                        Total <div className='dashboard-details-value'><small>R$ </small>299,90</div>
                                    </div>
                                </div>
                            </div>);
                        })}
                    </div>
                    <div className='pizza-container'>
                        <div className='pizza'>
                            <div
                                className='slice'
                                style={{
                                    clipPath: 'polygon(50% 0%, 55% 0, 50% 50%)',
                                    transform: 'rotateZ(0deg)'
                                }}
                            ></div>
                            <div
                                className='slice'
                                style={{
                                    backgroundColor: "#1567D2",
                                    clipPath: 'polygon(50% 0%, 55% 0, 50% 50%)',
                                    transform: 'rotateZ(5deg)'
                                }}
                            ></div>
                            <div
                                className='slice'
                                style={{
                                    backgroundColor: "#1567D2",
                                    clipPath: 'polygon(50% 0%, 55% 0, 50% 50%)',
                                    transform: 'rotateZ(10deg)'
                                }}
                            ></div>
                            <div
                                className='slice'
                                style={{
                                    backgroundColor: "#1567D2",
                                    clipPath: 'polygon(50% 0%, 55% 0, 50% 50%)',
                                    transform: 'rotateZ(15deg)'
                                }}
                            ></div>
                            <div
                                className='slice'
                                style={{
                                    backgroundColor: "#1567D2",
                                    clipPath: 'polygon(50% 0%, 100% 0, 50% 50%)',
                                    transform: 'rotateZ(20deg)'
                                }}
                            ></div>
                            <div
                                className='slice'
                                style={{
                                    clipPath: 'polygon(50% 0%, 100% 0, 50% 50%)',
                                    transform: 'rotateZ(65deg)'
                                }}
                            ></div>

                            <div
                                className='slice'
                                style={{
                                    backgroundColor: "#1567D2",
                                    clipPath: 'polygon(100% 100%, 100% 0, 50% 50%)',
                                    transform: 'rotateZ(65deg)'
                                }}
                            ></div>
                            {/* <div className='slice'></div> */}
                            <div className='pizza-value'>40%</div>
                            <div className='pizza-subtitle'>
                                Passe o cursor no produto para mostrá-lo no gráfico
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

function Chart(props) {
    let maxValue = Math.max(...props.values, 10);
    if (maxValue > 995) maxValue = Math.ceil(maxValue/100)*100;
    else if (maxValue > 95) maxValue = Math.ceil(maxValue/50)*50;
    else if (maxValue > 10) maxValue = Math.ceil(maxValue/5)*5;

    return (<div className='chart-container'>
        <div className='chart' onClick={props.onClick}>
            {props.values.map((e,i) => {
                let height = e * 180 / maxValue;
                const date = new Date();
                date.setDate(date.getDate() - (30 - i));

                return (<div className={`chart-column ${props.old ? 'old' : ''}`} style={{height: `${height}px`}}>
                    <div className='chart-column-value'>{e}</div>
                    <div className={`chart-column-date ${(i == props.values.length - 1 || i == Math.round(props.values.length / 2) || i == 0) ? 'visible' : ''}`}>{(i == props.values.length - 1) ? 'Hoje' : `${(date.getDate() + '').padStart(2, 0)}/${(date.getMonth() + 1 + '').padStart(2, 0)}`}</div>
                </div>);
            })}
            <span className='chart-scale-y'>
                    {Array(props.divisionsY + 1 || 4).fill('').map((e, i) => {
                        return <div className='chart-scale-y-point'>{Math.round(maxValue / ((props.divisionsY || 3)) * i)}</div>
                    })}
                {props.type == 'money' ? <span className='chart-scale-unit'>R$</span> : ''}
            </span>
            <span className='chart-scale-x'></span>
        </div>
    </div>);
}

function Story(props) {

    const storyRef = useRef();
    let storyTimeout;
    return (<div className='dashboard-story' ref={storyRef}
    onMouseEnter={() => {
        clearTimeout(storyTimeout);
        storyTimeout = setTimeout(() => storyRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' }), 400);
    }}
    onMouseLeave={() => {
        clearTimeout(storyTimeout);
    }}
    onClick={()=> {
        props.setModalStory(true);
    }}>
        <div className='story-background'></div>
        <img src={props.img}/>
    </div>);
}

function SoldOut(props) {
    const soldOutRef = useRef();
    let soldOutTimeout;

    return (<div className='dashboard-sold-out-product' ref={soldOutRef}
    onMouseEnter={() => {
        clearTimeout(soldOutTimeout);
        soldOutTimeout = setTimeout(() => soldOutRef.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' }), 400);
    }}
    onMouseLeave={() => {
        clearTimeout(soldOutTimeout);
    }}>
        <div className='dashboard-sold-out-sku'>OSM64</div>
        <img src={props.img}/>
    </div>)
}

function Video(props) {
    return (
        <video src={props.src} controls autoplay={props.autoplay || 'false'} loop muted key={props.key}></video>
    )
}