import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import Cookies from "js-cookie";

import axios from "axios";

import "./Login.css";
import ReactInputMask from "react-input-mask";
import Loader from "../../components/Loader/Loader";

import { terms, privacy } from "../../config/terms.js";

export default function AdminLogin() {
    Cookies.remove("jwt");

    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [doc, setDoc] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [modalCode, setModalCode] = useState(false);

    const [code, setCode] = useState("");

    const [codeLoading, setCodeLoading] = useState(false);

    const [modalTerms, setModalTerms] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        if (modalCode) return;
        try {
            if (!termsAccepted) {
                alert("Você precisa ler e aceitar os termos para continuar!");
                return;
            }

            if (password !== password2) {
                alert("Senhas não coincidem!");
                return;
            }

            if (doc.length !== 11 && doc.length !== 14) {
                alert("Insira um CPF/CNPJ válido!");
                return;
            }

            let response = await fetch("/api/users/generatecode", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: password,
                    phone: cellphone,
                    doc: doc,
                }),
            });

            const data = await response.json();
            if (response.status === 200) {
                setModalCode(true);
                // document.cookie = `token=${data.token};max-age=604800;path=/admin`;
                // navigate("/", { replace: true });
            } else {
                // setOpen(true);

                alert(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCode = async () => {
        setCodeLoading(true);
        try {
            if (password !== password2) {
                alert("Senhas não coincidem!");
                setCodeLoading(false);
                return;
            }

            if (!code) {
                alert("Preencha o código!");
                setCodeLoading(false);
                return;
            }

            let response = await fetch("/api/users/validatecode", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    password: password,
                    phone: cellphone,
                    doc: doc,
                    code: code,
                }),
            });

            const data = await response.json();

            if (data.token) {
                setModalCode(true);
                document.cookie = `token=${data.token};max-age=604800;path=/`;
                navigate("/pagamento", { replace: true });
            } else {
                alert(data.message);
            }
            setCodeLoading(false);
        } catch (error) {
            console.log(error);
            setCodeLoading(false);
        }
    };

    return (
        <>
            <div className={`terms-modal-bg ${modalTerms ? "shown" : ""}`} onClick={() => setModalTerms(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Termos de uso</div>
                    <div className="terms-modal-container">{terms}</div>
                </div>
            </div>
            <div className={`terms-modal-bg ${modalPrivacy ? "shown" : ""}`} onClick={() => setModalPrivacy(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Privacidade</div>
                    <div className="terms-modal-container">{privacy}</div>
                </div>
            </div>
            <div className="admin-login-page">
                <div
                    className={`sheet-export-modal-bg ${modalCode ? "shown" : ""}`}
                    onClick={() => {
                        setModalCode(false);
                    }}
                >
                    <div className="sheet-export-modal" onClick={(ev) => ev.stopPropagation()}>
                        <div className={`modal-code-loading ${codeLoading ? "shown" : ""}`}>
                            <Loader />
                        </div>
                        Digite o código de verificação que enviamos para seu WhatsApp:
                        <ReactInputMask
                            className="code-input"
                            mask="9 9 9 9 9 9"
                            value={code}
                            onChange={(ev) => setCode(ev.target.value)}
                        />
                        <div className="code-verify-btn" onClick={() => handleCode()}>
                            Verificar
                        </div>
                    </div>
                </div>

                <img className="drop-logo-login" src="/img/logo-orizom.svg" alt="Logo da Orizom Drop" />
                <form onSubmit={handleLogin}>
                    <div className="admin-login-container">
                        <input
                            type="text"
                            placeholder="Nome completo"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />

                        <input
                            type="text"
                            name="e-mail"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <ReactInputMask
                            mask="(99) 99999-9999"
                            value={cellphone}
                            placeholder="Número de celular"
                            onChange={(ev) => setCellphone(ev.target.value)}
                            required
                        />

                        <ReactInputMask
                            mask={doc.length < 12 ? "999.999.999-999" : "99.999.999/9999-99"}
                            maskChar={""}
                            value={doc}
                            placeholder="CPF / CNPJ"
                            onChange={(ev) => {
                                // alert(ev.target.value.replace(/\D/g, ""));
                                setDoc(ev.target.value.replace(/\D/g, ""));
                            }}
                            required
                        />

                        <input
                            type="password"
                            placeholder="Senha"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />

                        <input
                            type="password"
                            placeholder="Repita a senha"
                            name="password2"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            required
                        />

                        <div className="terms-accept">
                            <div className="terms-accept-button" onClick={() => setTermsAccepted(!termsAccepted)}>
                                <div className={`terms-accept-button-checked ${termsAccepted ? "checked" : ""}`}></div>
                            </div>
                            Declaro que li e aceito os{" "}
                            <span className="terms-accept-read" onClick={() => setModalTerms(true)}>
                                Termos de uso
                            </span>
                        </div>

                        <button type="submit" className={`${termsAccepted ? "" : "disabled"}`}>
                            Cadastrar
                        </button>
                        <Link to="/login" className="cadastrar">
                            Ir para o login
                        </Link>
                    </div>
                </form>
                <div className="terms">
                    <div className="terms-of-use" onClick={() => setModalTerms(true)}>
                        Termos de uso
                    </div>
                    <div className="privacy-policy" onClick={() => setModalPrivacy(true)}>
                        Privacidade
                    </div>
                </div>
            </div>
        </>
    );
}
