import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import "./LandingPage.css";

import ReactPixel from "react-facebook-pixel";

import { terms, privacy } from "../../config/terms.js";
import Einstein from "../../components/Einstein/Einstein.js";
import Loader from "../../components/Loader/Loader.js";
import { TypeAnimation } from "react-type-animation";
import SlotNumber from "../../components/SlotNumber/SlotNumber.js";

const pixelOptions = {
    autoConfig: false,
    debug: true,
};

export default function LandingPage() {
    const [showBtn, setShowBtn] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const [modalStarbox, setModalStarbox] = useState(false);
    const [modalTerms, setModalTerms] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(false);

    const [modalVideo, setModalVideo] = useState(false);

    const [scrollHeight, setScrollHeight] = useState(0);

    const containerRef = useRef(null);
    const landingJoin = useRef(null);

    useEffect(() => {
        ReactPixel.init("320984243594077", null, pixelOptions);
        ReactPixel.pageView();

        setTimeout(() => {
            setShowBtn(true);
            setTimeout(() => {
                setShowVideo(true);
            }, 200);
        }, 2000);
    }, []);

    const isVisible = scrollHeight > containerRef.current?.scrollHeight - document.body.scrollHeight - 200;

    return (
        <>
            <img
                src="/img/landingpage/whatsapp.svg"
                className="landing-whatsapp"
                onClick={() => {
                    window.open(
                        "https://wa.me/5511953983688?text=Ol%C3%A1,%20Carlos!%20Tenho%20algumas%20d%C3%BAvidas%20sobre%20a%20franquia%20ou%20preciso%20de%20ajuda%20com%20algo.",
                        "_blank"
                    );
                }}
            />
            <div className={`terms-modal-bg ${modalStarbox ? "shown" : ""}`} onClick={() => setModalStarbox(false)}>
                <div className={`terms-modal modal-starbox-container`} onClick={(ev) => ev.stopPropagation()}>
                    {/* <div className="terms-modal-title">S T A R B O X</div> */}
                    <div className="terms-modal-container modal-starbox">
                        A <img className="popup-starbox-icon" src="/img/landingpage/starbox.svg" /> é uma tecnologia 100%
                        desenvolvida pela Orizom, que tem por finalidade a automação total da operação logística, fazendo com que
                        um pedido feito por um franqueado seja finalizado em até 30 segundos nas dependências da Orizom!
                        <br />
                        <br />
                        Para isso, ocorre a junção do AI-Stem (criando 100% a oferta nos marketplaces e capturando o pedido quando
                        vendido, sem nenhuma intervenção humana) com o acionamento do robô da{" "}
                        <img className="popup-starbox-icon" src="/img/landingpage/starbox.svg" /> fazendo todo o processo de
                        embalação do pedido.
                        <br />
                        <br />A Orizom é a primeira empresa do Mundo a desenvolver uma tecnologia de automação 100% executada por
                        IA de uma operação logística no segmento de vendas!
                    </div>
                </div>
            </div>
            <div className={`terms-modal-bg ${modalTerms ? "shown" : ""}`} onClick={() => setModalTerms(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Termos de uso</div>
                    <div className="terms-modal-container">{terms}</div>
                </div>
            </div>
            <div className={`terms-modal-bg ${modalPrivacy ? "shown" : ""}`} onClick={() => setModalPrivacy(false)}>
                <div className={`terms-modal`} onClick={(ev) => ev.stopPropagation()}>
                    <div className="terms-modal-title">Privacidade</div>
                    <div className="terms-modal-container">{privacy}</div>
                </div>
            </div>
            <div className={`landing-modal-bg ${modalVideo ? "shown" : ""}`} onClick={() => setModalVideo(false)}>
                <div className="landing-modal-video">
                    <div className="modal-video-close">✕</div>
                    <div className="modal-video-loading">
                        <Loader />
                    </div>
                    {modalVideo ? (
                        <iframe
                            src="https://www.youtube.com/embed/IjVNVW4WEWk?rel=0&color=white&iv_load_policy=3"
                            title="Orizom"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                            allowfullscreen={true}
                            autoPlay
                            muted
                        ></iframe>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div
                className="landing-page-container"
                onScroll={(ev) => {
                    setScrollHeight(ev.target?.scrollTop);
                    // if (ev.target?.scrollTop > scrollHeight) window.scrollTo(0, 100);
                }}
                ref={containerRef}
            >
                <div className={`landing-page-navbar ${scrollHeight > 10 ? "scrolled" : ""}`}>
                    <picture>
                        <source media="(min-width: 700px)" srcset="/img/logo-orizom.svg" />
                        <source media="(max-width: 700px)" srcset="/img/landingpage/mini-logo.svg" />
                        <img className="lading-orizom-logo" src="/img/logo-orizom.svg" alt="Logo da Orizom" />
                    </picture>

                    <div
                        to="cadastro"
                        className="landing-btn signup"
                        onClick={() => {
                            landingJoin.current?.scrollIntoView();
                        }}
                    >
                        Seja um franqueado!
                    </div>
                    <Link to="login" className="landing-btn">
                        Entrar
                    </Link>
                    {/* <div>{scrollHeight}</div> */}
                </div>
                <div className={`landing-page-content ${scrollHeight > document.body.scrollHeight - 100 ? "visible" : ""}`}>
                    {showBtn ? (
                        window.innerWidth > 700 ? (
                            <video
                                className={`landing-background landing-video ${showVideo ? "" : "hidden"}`}
                                src="/img/landingpage/desktop.mp4"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <video
                                className={`landing-background landing-video ${showVideo ? "" : "hidden"}`}
                                src="/img/landingpage/mobile.mp4"
                                autoPlay
                                muted
                                loop
                            />
                        )
                    ) : (
                        <></>
                    )}
                    <div className="landing-content">
                        {/* <img className="landing-aistem" src="/img/landingpage/aistem.svg" /> */}
                        {/* <Einstein className="landing-aistem" type="landing" /> */}
                        <div className="landing-text landing-text-first">
                            A 1ª Inteligência Artificial Generativa de Dropshipping do Brasil!
                        </div>
                        {/* <TypeAnimation
                            key={1}
                            className={`landing-text`}
                            sequence={[
                                1500,
                                `A 1ª Inteligência Artificial Generativa de Dropshipping do Brasil!`,
                                200,
                                () => setShowBtn(true),
                                100,
                                () => setShowVideo(true),
                            ]}
                            speed={{
                                type: "keyStrokeDelayInMs",
                                value: 20,
                            }}
                        /> */}
                        <div className={`landing-watch-btn ${showBtn ? "" : "hidden"}`} onClick={() => setModalVideo(true)}>
                            Assista o lançamento!
                        </div>
                    </div>
                </div>
                <div
                    className={`landing-page-content landing-page-content-2 ${
                        scrollHeight > document.body.scrollHeight - 50 ? "visible" : ""
                    }`}
                >
                    <picture>
                        <source media="(min-width: 700px)" srcset="/img/landingpage/landing1.png" />
                        <source media="(max-width: 700px)" srcset="/img/landingpage/landing1mobile.png" />
                        <img className="landing-background" src="/img/landingpage/landing1.png" />
                    </picture>
                    <div className="landing-content landing-2">
                        <img className="landing-starbox" src="/img/landingpage/starbox.svg" />
                        <div className="landing-text">1ª Operação 100% automatizada (do mundo)!</div>
                        <div className="landing-watch-btn" onClick={() => setModalStarbox(true)}>
                            Conheça!
                        </div>
                    </div>
                </div>
                <div className={`landing-page-content visible`}>
                    <div className="landing-background-container">
                        <img className="landing-background" src="/img/landingpage/landing2.png" />
                    </div>
                    <div className="landing-content landing-3">
                        {scrollHeight > document.body.scrollHeight * 2 - 100 ? (
                            <TypeAnimation
                                key={1}
                                className={`landing-text`}
                                sequence={[500, `PRODUTOS EXCLUSIVOS`]}
                                speed={{
                                    type: "keyStrokeDelayInMs",
                                    value: 20,
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        {/* <div className="landing-text">PRODUTOS EXCLUSIVOS</div> */}
                    </div>
                </div>
                <div className="landing-page-content landing-content-4" ref={landingJoin}>
                    <div className="landing-content landing-4">
                        <div className="landing-text">
                            <div className="landing-price-title">Torne-se um franqueado e pare de brigar por preços!</div>
                            <div className="landing-price-container">
                                <div className="landing-price">
                                    <span className="landing-price-value">
                                        <small>R$</small> 1.599,90
                                    </span>
                                    <small> /ano</small>
                                    <div className="landing-installments">Parcele em até 12x</div>
                                    <Link to="cadastro" className="landing-signup-btn">
                                        Quero me tornar um franqueado!
                                    </Link>
                                </div>
                            </div>
                            <div className="landing-benefits">
                                <div className="landing-benefits-title">Principais vantagens:</div>
                                <div className="landing-benefits-text">
                                    Tenha o Al-stem trabalhando por você 24/7 sem parar!
                                    <br />
                                    Ele cria automaticamente seus anúncios, precifica, cria descrições altamente inteligentes, e
                                    quando você vender, ele captura automaticamente o pedido e o resto é com a Orizom!
                                    <br />
                                    <br />
                                    Tenha a <img className="popup-starbox-icon" src="/img/landingpage/starbox.svg" /> cuidando dos
                                    seus pedidos e deixando-os prontos em até 30 segundos (a menor taxa de preparo desse segmento
                                    do Mundo)!
                                    <br />
                                    <br />
                                    Tenha a menor taxa de concorrência do Mercado! Abrimos apenas 50 vagas! Aqui você concorre com
                                    apenas 49 franqueados (para sempre)!
                                    <br />
                                    <br />
                                    Revenda produtos exclusivos!
                                    <br />
                                    Somos fabricantes enquanto 99% desse segmento são revendedores da indústria! Você busca
                                    exclusividade em produtos ou será mais um dentro do time da "briga de preços"?
                                    <br />
                                    <br />
                                    <div className="landing-bonus">
                                        <div className="landing-bonus-title">Bônus por vendas realizadas:</div>
                                        <span>10 vendas - </span>
                                        <strong>
                                            Ganhe R$ {isVisible && <SlotNumber number={100} height={18} delay={500} />}
                                        </strong>
                                        <br />
                                        <span>100 vendas - </span>
                                        <strong>
                                            Ganhe R$ {isVisible && <SlotNumber number={500} height={18} delay={500} />}
                                        </strong>
                                        <br />
                                        <span>1000 vendas - </span>
                                        <strong>
                                            Ganhe R$ {isVisible && <SlotNumber number={2500} height={18} delay={500} />}
                                        </strong>
                                        <br />
                                        <span>5 mil vendas - </span>
                                        <strong>
                                            Ganhe R$ {isVisible && <SlotNumber number={5000} height={18} delay={500} />}
                                        </strong>
                                        <br />
                                        <span>10 mil vendas - </span>
                                        <strong>
                                            Ganhe R$ {isVisible && <SlotNumber number={10000} height={18} delay={500} />}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-footer">
                            Copyright © 2018 - {new Date().getFullYear()} Orizom Tecnologies Inc. (CNPJ 29.563.690/0001-79)
                            <div className="landing-footer-btns">
                                <span onClick={() => setModalTerms(true)}>Termos de uso</span>
                                <span onClick={() => setModalPrivacy(true)}>Privacidade</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
