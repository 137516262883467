import React, { useEffect, useState, useCallback } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import Cookies from "js-cookie";
import { Draggable } from "react-drag-reorder";
import CopyDiv from "../../CopyDiv/CopyDiv";
import "./AdminProductList.css";
import Loader from "../../Loader/Loader";

import RenderIfVisible from "react-render-if-visible";
import { TypeAnimation } from "react-type-animation";

import { allCategories, allFields } from "../../../config/categories.js";

import { useContext } from "react";
import { UserContext } from "../../../App.js";

const ProductList = () => {
    const { adminProducts, adminBoxes, adminGroups, adminMaterials } = useContext(UserContext);

    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState([]);

    const [productsSearch, setProductsSearch] = useState([]);

    const [search, setSearch] = useState("");

    const [modalConfirm, setModalConfirm] = useState(false);

    const [modalAdd, setModalAdd] = useState(0);
    const [modalAddLoading, setModalAddLoading] = useState(false);

    const [modalProduct, setModalProduct] = useState({});
    const [loadingPhoto, setLoadingPhoto] = useState(false);

    const [boxes, setBoxes] = useState([]);

    const [groups, setGroups] = useState([]);

    // const fetchProducts = async () => {
    //     try {
    //         const response = await fetch("/api/products/all", {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: "Bearer " + Cookies.get("jwt"),
    //             },
    //         });
    //         const data = await response.json();
    //         setProducts([...data]);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    useEffect(() => {
        setProducts([...adminProducts]);
        //     fetchProducts();
    }, [adminProducts]);

    useEffect(() => {
        // const fetchBoxes = async () => {
        //     try {
        //         const response = await fetch("/api/products/getboxes", {
        //             method: "GET",
        //         });
        //         const data = await response.json();
        //         setBoxes(data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchBoxes();
        setBoxes(adminBoxes);
    }, [adminBoxes]);

    // const fetchGroups = async () => {
    //     try {
    //         const response = await fetch("/api/products/getgroups", {
    //             method: "GET",
    //         });
    //         const data = await response.json();
    //         setGroups(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };
    useEffect(() => {
        // fetchGroups();
        setGroups(adminGroups);
    }, [adminGroups]);

    useEffect(() => {
        if (!search) return setProductsSearch(products);
        let newProducts = products.slice().filter((prod) => {
            let sku = prod?.sku?.toLowerCase();
            let query = search?.toLowerCase();
            let group = null;
            if (groups.length)
                group = groups
                    .filter((g) => g.products.includes(prod.id))
                    .map((e) => e.name)
                    .join("")
                    ?.toLowerCase();

            return (
                sku.includes(query) ||
                query.includes(sku) ||
                prod.tags.some((tag) => tag?.text?.toLowerCase().includes(query) || query.includes(tag?.text?.toLowerCase())) ||
                (group && (group.includes(query) || query.includes(group)))
            );
        });
        setProductsSearch(newProducts);
    }, [products, search, groups]);

    useEffect(() => {
        if (modalProduct && !modalProduct.groups) {
            let productGroups = groups.filter((g) => g.products.includes(modalProduct.id)).map((e) => e.id);
            console.log(productGroups);
            setModalProduct({ ...(modalProduct || {}), groups: productGroups });
        }
    }, [modalProduct, groups]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!modalProduct.sku) return alert("Preencha o SKU");
        if (!modalProduct.name) return alert("Preencha o nome");
        if (!modalProduct.title) return alert("Preencha o título");
        if (!modalProduct.tags.length) return alert("Insira ao menos uma tag!");
        if (!modalProduct.price || modalProduct.price < 1) return alert("Preencha um preço válido");
        if (!modalProduct.description) return alert("Preencha a descrição!");
        if (!modalProduct.photos || modalProduct.photos.length < 1) return alert("Insira ao menos uma foto!");

        setModalAddLoading(true);

        let url = "/api/products/add";

        if (modalAdd == 2) {
            url = "/api/products/edit";
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify(modalProduct),
            });

            const data = await response.json();

            if (response.status === 500) {
                alert(data.message);
                setModalAddLoading(false);
                return;
            }

            setModalAddLoading(false);
            setModalProduct(null);
            setModalAdd(0);
            // fetchProducts();
            // fetchGroups();
        } catch (error) {
            setModalAddLoading(false);
            alert(error.message);
            console.error(error);
        }
    };

    const handleFileChange = async (e) => {
        e.preventDefault();
        setLoadingPhoto(true);
        const file = Math.min(e.target.files.length, 10 - (modalProduct.photos ? modalProduct.photos.length : 0));
        // Enviar a foto ao servidor
        const formData = new FormData();
        for (var x = 0; x < file; x++) {
            formData.append("photo", e.target.files[x]);
        }
        fetch("/api/products/uploadphoto", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + Cookies.get("jwt"),
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                //alert(JSON.stringify(data));
                // Atualizar as informações da foto no estado
                console.log(data.photo);
                const updatedPhotos = [...(modalProduct.photos || []), ...data.photo.map((e) => e.filename)];
                setModalProduct({ ...modalProduct, photos: updatedPhotos });
                setLoadingPhoto(false);
                e.target.value = "";
            })
            .catch((error) => {
                console.error(error);
                alert("erro ao fazer upload das fotos");
                setLoadingPhoto(false);
                e.target.value = "";
            });
    };

    const DraggableRender = () => {
        // console.log(modalProduct.photos);
        return (
            <>
                <div className={`photos-loading ${loadingPhoto ? "shown" : ""}`}>
                    <Loader />
                </div>
                {modalProduct && modalProduct.photos && modalProduct.photos.length ? (
                    <Draggable
                        onPosChange={(currentPos, newPos) => {
                            let newProduct = { ...modalProduct };
                            let tempPhoto = newProduct.photos[currentPos];
                            newProduct.photos[currentPos] = newProduct.photos[newPos];
                            newProduct.photos[newPos] = tempPhoto;
                            setModalProduct(newProduct);
                            //console.log(currentPos, newPos);
                        }}
                    >
                        {modalProduct.photos.map((img) => (
                            <div className="admin-product-photo" key={img}>
                                <img src={"/uploads/products/" + img} />
                                <div
                                    className="product-photo-remove-btn"
                                    onClick={() => {
                                        setModalProduct({
                                            ...modalProduct,
                                            photos: modalProduct.photos.filter((e) => e != img),
                                        });
                                    }}
                                >
                                    Remover
                                </div>
                            </div>
                        ))}
                    </Draggable>
                ) : (
                    ""
                )}
                <label
                    className={`admin-product-photo ${modalProduct.photos && modalProduct.photos.length > 9 ? "disabled" : ""}`}
                >
                    <span>+</span>Adicionar foto
                    <input className="add-photo-btn" type="file" accept="image/*" multiple onChange={handleFileChange} />
                </label>
            </>
        );
    };

    return (
        <>
            <div className={`order-search-bar`}>
                <div className="order-search">
                    <input
                        placeholder="Pesquise por sku ou nome do produto"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchParams({'q': e.target.value});
                        }}
                    />
                </div>
                <div className="product-add-btn-container">
                    <div
                        className="product-add-btn"
                        onClick={() => {
                            setModalAdd(1);
                            setModalProduct({});
                        }}
                    >
                        Novo produto
                    </div>
                </div>
            </div>

            <div className={`admin-modal-bg ${modalAdd != 0 ? "shown" : ""}`} onMouseDown={() => setModalAdd(false)}>
                {modalAddLoading ? (
                    <div className="admin-modal-loading">
                        <Loader />
                    </div>
                ) : (
                    ""
                )}
                <div className="admin-add-modal" onMouseDown={(ev) => ev.stopPropagation()}>
                    {modalProduct && modalAdd != 0 ? (
                        <>
                            <form className="admin-product-form" onSubmit={handleSubmit} id="add-form">
                                <div className="product-data-section">
                                    <div className="product-data-title">SKU</div>
                                    <input
                                        type="text"
                                        placeholder="SKU do produto"
                                        value={modalProduct.sku}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                sku: e.target.value,
                                            })
                                        }
                                        maxLength={100}
                                        disabled={modalAdd === 2}
                                    />
                                </div>
                                <div className="product-data-section">
                                    <div className="product-data-title">Nome</div>
                                    <input
                                        type="text"
                                        placeholder="Nome do Produto"
                                        value={modalProduct.name}
                                        className="product-name"
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="product-data-section">
                                    <div className="product-data-title">Preço</div>
                                    <input
                                        type="number"
                                        step="1"
                                        placeholder="Preço"
                                        value={modalProduct.price}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                price: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="product-data-section" style={{ flexBasis: 1 }}>
                                    <div className="product-data-title">Estoque</div>
                                    <input
                                        type="number"
                                        step="1"
                                        placeholder="Estoque"
                                        value={modalProduct.stock}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                stock: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="product-data-section customfield">
                                    <div className="product-data-title">Personalizável</div>
                                    <div
                                        className={`switch ${modalProduct.custom ? "checked" : ""}`}
                                        onClick={() => {
                                            setModalProduct({
                                                ...modalProduct,
                                                custom: !modalProduct.custom,
                                            });
                                        }}
                                    >
                                        <span className="slider"></span>
                                    </div>
                                </div>

                                <div
                                    className={`product-data-section materials`}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div className="product-data-title">Materiais</div>
                                    <div className="product-data-groups">
                                        <div className="admin-whitelist">
                                            {(modalProduct?.materials || []).map((prod) => {
                                                let product = adminMaterials?.find((p) => p.id === prod);
                                                if (!product) return <></>;
                                                return (
                                                    <div
                                                        className="admin-whitelist-product"
                                                        onClick={() => {
                                                            setModalProduct({
                                                                ...modalProduct,
                                                                materials: modalProduct?.materials?.filter((e) => e !== prod),
                                                            });
                                                        }}
                                                    >
                                                        <img src={"/uploads/internal/" + product?.photo} />
                                                        <div className="admin-whitelist-sku">{product.name}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="admin-user-groups">
                                            <ReactTags
                                                classNames={{
                                                    // tags: "admin-product-tags",
                                                    tagInputField: "admin-product-tags-input",
                                                    selected: "admin-tag-container",
                                                    tag: "admin-product-tag",
                                                    remove: "admin-tag-remove",
                                                }}
                                                suggestions={adminMaterials.map((mat) => {
                                                    return {
                                                        id: mat.name,
                                                        text: mat.name,
                                                        _id: mat.id,
                                                    };
                                                })}
                                                tags={[]}
                                                delimiters={[188, 13]}
                                                handleAddition={(tag) => {
                                                    // alert(tag._id);

                                                    if (
                                                        !tag._id ||
                                                        !adminMaterials.find((e) => e.id === tag._id) ||
                                                        modalProduct?.materials?.includes(tag._id)
                                                    )
                                                        return;

                                                    setModalProduct({
                                                        ...modalProduct,
                                                        materials: [...(modalProduct?.materials || []), tag._id],
                                                    });
                                                }}
                                                handleDrag={(tag, currPos, newPos) => {}}
                                                inputFieldPosition="inline"
                                                placeholder="Pesquisar por material"
                                                autofocus={false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="product-data-section"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div className="product-data-title">Grupos</div>
                                    <div className="product-data-groups">
                                        {groups.map((e) => {
                                            return (
                                                <div
                                                    className={`product-data-group ${
                                                        modalProduct?.groups?.includes(e.id) ? "selected" : ""
                                                    }`}
                                                    onClick={() => {
                                                        if (!modalProduct?.groups?.includes(e.id)) {
                                                            setModalProduct({
                                                                ...modalProduct,
                                                                groups: [...(modalProduct?.groups || []), e.id],
                                                            });
                                                        } else {
                                                            let groupstemp = [...(modalProduct?.groups || [])];
                                                            if (groupstemp?.find((f) => f == e.id)) {
                                                                groupstemp?.splice(groupstemp?.indexOf(e.id), 1);
                                                                setModalProduct({
                                                                    ...modalProduct,
                                                                    groups: groupstemp,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {e.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div
                                    className="product-data-section"
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <div className="product-data-title">Título base</div>
                                    <div className="base-title-container">
                                        <input
                                            type="text"
                                            placeholder="Título base"
                                            value={modalProduct.title}
                                            className="base-title"
                                            onChange={(e) =>
                                                setModalProduct({
                                                    ...modalProduct,
                                                    title: e.target.value,
                                                })
                                            }
                                        />
                                        <div
                                            className="base-title-length"
                                            style={{
                                                color: modalProduct.title?.length > 60 ? "#f00" : "#1567d2",
                                            }}
                                        >
                                            {modalProduct.title?.length}
                                        </div>
                                    </div>
                                </div>
                                <div className="product-data-section">
                                    <div className="product-data-title">Geração de título</div>
                                    <ReactTags
                                        classNames={{
                                            tags: "admin-product-tags",
                                            tagInputField: "admin-product-tags-input",
                                            selected: "admin-tag-container",
                                            tag: "admin-product-tag",
                                            remove: "admin-tag-remove",
                                        }}
                                        tags={modalProduct.tags || []}
                                        delimiters={[188, 13]}
                                        handleDelete={(i) => {
                                            setModalProduct({
                                                ...modalProduct,
                                                tags: modalProduct.tags.filter((tag, index) => index !== i),
                                            });
                                        }}
                                        handleAddition={(tag) => {
                                            setModalProduct({
                                                ...modalProduct,
                                                tags: [...(modalProduct.tags || []), tag],
                                            });
                                        }}
                                        handleDrag={(tag, currPos, newPos) => {
                                            const newTags = modalProduct.tags.slice();
                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag);
                                            setModalProduct({
                                                ...modalProduct,
                                                tags: newTags,
                                            });
                                        }}
                                        inputFieldPosition="inline"
                                        placeholder="Tags"
                                        autofocus={false}
                                    />
                                </div>
                                <div className="product-data-section">
                                    <div className="product-data-title">Tags</div>
                                    <ReactTags
                                        classNames={{
                                            tags: "admin-product-tags",
                                            tagInputField: "admin-product-tags-input",
                                            selected: "admin-tag-container",
                                            tag: "admin-product-tag",
                                            remove: "admin-tag-remove",
                                        }}
                                        tags={modalProduct.tags2 || []}
                                        delimiters={[188, 13]}
                                        handleDelete={(i) => {
                                            setModalProduct({
                                                ...modalProduct,
                                                tags2: modalProduct.tags2.filter((tag, index) => index !== i),
                                            });
                                        }}
                                        handleAddition={(tag) => {
                                            setModalProduct({
                                                ...modalProduct,
                                                tags2: [...(modalProduct.tags2 || []), tag],
                                            });
                                        }}
                                        handleDrag={(tag, currPos, newPos) => {
                                            const newTags = modalProduct.tags2.slice();
                                            newTags.splice(currPos, 1);
                                            newTags.splice(newPos, 0, tag);
                                            setModalProduct({
                                                ...modalProduct,
                                                tags2: newTags,
                                            });
                                        }}
                                        inputFieldPosition="inline"
                                        placeholder="Tags"
                                        autofocus={false}
                                    />
                                </div>
                                <div className="product-data-section">
                                    <div className={`admin-titles-loading ${modalProduct.titleLoading ? "shown" : ""}`}>
                                        <Loader />
                                    </div>
                                    <div
                                        className="admin-gen-titles"
                                        onClick={async () => {
                                            if (modalProduct.titleLoading) return;
                                            setModalProduct({
                                                ...modalProduct,
                                                titleLoading: true,
                                            });
                                            try {
                                                const titles = await fetch("/api/products/titleadm", {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: JSON.stringify({
                                                        baseTitle: modalProduct.title,
                                                        tags: modalProduct.tags,
                                                    }),
                                                });

                                                setModalProduct({
                                                    ...modalProduct,
                                                    titleLoading: false,
                                                    titlesTest: await titles.json(),
                                                });
                                            } catch (err) {
                                                setModalProduct({
                                                    ...modalProduct,
                                                    titleLoading: false,
                                                    titlesTest: ["Erro na API da OpenAI, tente novamente"],
                                                });
                                            }
                                        }}
                                    >
                                        Gerar títulos de teste
                                    </div>
                                    <div className="admin-title-preview">
                                        {modalProduct.titlesTest?.map((e, i) => {
                                            if (modalProduct.titleLoading) return;
                                            return (
                                                <div className="admin-title-option" key={i}>
                                                    <span>{i + 1}</span>

                                                    <TypeAnimation
                                                        sequence={["", e]}
                                                        speed={{
                                                            type: "keyStrokeDelayInMs",
                                                            value: 5,
                                                        }}
                                                    />
                                                    <span className="admin-title-length">{e.length}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="product-data-section description-section">
                                    <div className="product-data-title">Descrição</div>
                                    <textarea
                                        placeholder="Descrição"
                                        value={modalProduct.description}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                description: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div>
                                <div className="product-data-section" style={{ width: "100%" }}>
                                    <div className="product-data-title">Fotos</div>
                                    <div className="admin-photos-container">
                                        <DraggableRender />
                                    </div>
                                </div>
                                <div className="product-data-section" style={{ width: "100%" }}>
                                    <div className="product-data-title">Caixa</div>
                                    <div className="product-box-container">
                                        <div
                                            className={`product-box-option ${modalProduct.box === null ? "selected" : ""}`}
                                            onClick={() =>
                                                setModalProduct({
                                                    ...modalProduct,
                                                    box: null,
                                                })
                                            }
                                        >
                                            Nenhuma
                                        </div>
                                        {boxes.map((box) => {
                                            return (
                                                <div
                                                    className={`product-box-option ${
                                                        modalProduct.box === box.id ? "selected" : ""
                                                    }`}
                                                    onClick={() =>
                                                        setModalProduct({
                                                            ...modalProduct,
                                                            box: box.id,
                                                        })
                                                    }
                                                >
                                                    <img src={`/uploads/internal/${box.photo}`} />
                                                    {box.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="product-data-section" style={{ width: "100%" }}>
                                    <div className="product-data-title">Tipo de produto {modalProduct.type}</div>
                                    <br />
                                    <select
                                        className="type-select"
                                        value={modalProduct.type}
                                        onChange={(e) =>
                                            setModalProduct({
                                                ...modalProduct,
                                                type: e.target.value,
                                            })
                                        }
                                    >
                                        {allCategories.map((e) => {
                                            return <option value={e.id}>{e.name}</option>;
                                        })}
                                    </select>
                                    <br />
                                    <br />
                                    <div className="product-data-title">Campos customizados</div>
                                    <div className="customfield-container">
                                        {allFields
                                            .filter((e) =>
                                                allCategories
                                                    .find((cat) => (modalProduct.type || 0) == cat.id)
                                                    ?.fields?.includes(e.id)
                                            )
                                            ?.map((field) => {
                                                return (
                                                    <label className="customfield">
                                                        {field.name}
                                                        {field.type === 4 || field.type === 2 ? (
                                                            <input
                                                                type={`${field.type === 4 ? "text" : "number"}`}
                                                                value={
                                                                    modalProduct.datasheet
                                                                        ? modalProduct?.datasheet[field.id]
                                                                        : ""
                                                                }
                                                                name={field}
                                                                onChange={(e) => {
                                                                    setModalProduct({
                                                                        ...modalProduct,
                                                                        datasheet: {
                                                                            ...(modalProduct?.datasheet || {}),
                                                                            [field.id]: e.target.value,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        ) : field.type === 3 ? (
                                                            <select
                                                                className="type-select"
                                                                value={modalProduct.datasheet && modalProduct.datasheet[field.id]}
                                                                onChange={
                                                                    (e) =>
                                                                        setModalProduct({
                                                                            ...modalProduct,
                                                                            datasheet: {
                                                                                ...(modalProduct?.datasheet || {}),
                                                                                [field.id]: e.target.value,
                                                                            },
                                                                        })
                                                                    // setModalProduct(
                                                                    //     {
                                                                    //         ...modalProduct,
                                                                    //         type: e
                                                                    //             .target
                                                                    //             .value,
                                                                    //     }
                                                                    // )
                                                                }
                                                            >
                                                                {field.options?.map((e) => {
                                                                    return <option value={e.id}>{e.name}</option>;
                                                                })}
                                                            </select>
                                                        ) : (
                                                            <div
                                                                className={`switch ${
                                                                    modalProduct.datasheet && modalProduct.datasheet[field.id]
                                                                        ? "checked"
                                                                        : ""
                                                                }`}
                                                                onClick={() => {
                                                                    setModalProduct({
                                                                        ...modalProduct,
                                                                        datasheet: {
                                                                            ...(modalProduct?.datasheet || {}),
                                                                            [field.id]: !modalProduct?.datasheet[field.id],
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <span className="slider"></span>
                                                            </div>
                                                        )}
                                                    </label>
                                                );
                                            })}
                                    </div>
                                </div>
                            </form>
                            <input
                                className="product-save-btn"
                                type="submit"
                                value={
                                    modalAdd == 1
                                        ? "Criar produto"
                                        : modalAdd == 2
                                        ? "Salvar alterações"
                                        : modalAdd == 3
                                        ? "Duplicar produto"
                                        : ""
                                }
                                form="add-form"
                            />
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className={`admin-modal-bg ${modalConfirm ? "shown" : ""}`} onClick={() => setModalConfirm(false)}>
                <div className="admin-confirm-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="admin-modal-title">Tem certeza?</div>
                    <div className="admin-modal-confirm-btns">
                        <div className="admin-modal-confirm-btn" onClick={() => setModalConfirm(false)}>
                            Não
                        </div>
                        <div
                            className="admin-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                fetch("/api/products/remove", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + Cookies.get("jwt"),
                                    },
                                    body: JSON.stringify({
                                        id: modalProduct.id,
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        //alert(JSON.stringify(products));
                                        setProducts(products.filter((e) => e.id != modalProduct.id));
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                                setModalConfirm(false);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin-product-list">
                {productsSearch.map((product, index) => {
                    return (
                        <RenderIfVisible defaultHeight={68} visibleOffset={2000}>
                            <Product
                                setModalAdd={setModalAdd}
                                setModalProduct={setModalProduct}
                                setModalConfirm={setModalConfirm}
                                product={product}
                                products={products}
                                selected={selected}
                                setSelected={setSelected}
                                key={product.id}
                            />
                        </RenderIfVisible>
                    );
                })}
            </div>
        </>
    );
};

function Product(props) {
    const product = props.product;

    const [tags, setTags] = useState(product.tags || []);

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
        setDidMount(true);
    }, []);

    useEffect(() => {
        if (!didMount) return;
        fetch("/api/products/updatetags", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: product.id,
                tags: tags,
            }),
        });
    }, [tags]);

    useEffect(() => {
        if (!didMount) return;
        setTags(product.tags);
    }, [product.tags]);

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
        // updateTags();
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
        // updateTags();
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
        // updateTags();
    };

    const thisFields = allFields
        .filter((e) => allCategories.find((cat) => (product.type || 0) == cat.id)?.fields?.includes(e.id) && e.type == 4)
        .map((e) => e.id);

    const thisFilledFields = Object.entries(product.datasheet).map((b) => Number(b[0]));

    // console.log(thisFilledFields);
    // console.log(thisFields);

    return (
        <div
            className={`admin-product ${product.stock < 1 ? "sold-out" : ""} ${
                !product.title || !product.tags || !product.tags.length || !product.tags2 || !product.tags2.length
                    ? "errored"
                    : thisFields.some((a) => {
                          return !thisFilledFields.includes(a);
                      })
                    ? "errored2"
                    : ""
            }`}
            style={{ animationDelay: `${props.index * 30}ms` }}
        >
            <div className="admin-product-img">
                <img src={"/uploads/thumbs/" + product.photos[0]} alt="" />
                <CopyDiv className="admin-product-sku" value={product.sku}>
                    {product.sku}
                </CopyDiv>
                <img
                    src={"/uploads/thumbs/" + product.photos[0]}
                    className="admin-product-img-large"
                    style={{
                        top: props.products.length - 1 === props.index ? "-120px" : "-20px",
                    }}
                    alt="produto"
                />
            </div>
            <div className="admin-product-names">
                <ReactTags
                    classNames={{
                        tags: "admin-product-tags",
                        // tagInput: 'admin-product-tags',
                        tagInputField: "admin-product-tags-input",
                        selected: "admin-tag-container",
                        tag: "admin-product-tag",
                        remove: "admin-tag-remove",
                        // suggestions: 'suggestionsClass',
                        // activeSuggestion: 'activeSuggestionClass',
                        // editTagInput: 'editTagInputClass',
                        // editTagInputField: 'editTagInputField',
                        // clearAll: 'clearAllClass',
                    }}
                    tags={tags}
                    delimiters={[188, 13]}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    inputFieldPosition="inline"
                    // autocomplete
                    placeholder="Tags"
                    autofocus={false}
                />
            </div>

            <span className="admin-product-stock">
                <div>Estoque</div>
                {product.stock}
            </span>

            <span className="admin-product-stock stock-ready">
                <div>Prontos</div>
                {product.stockReady}
                <div className="stock-ready-btns">
                    <div
                        className="stock-ready-minus"
                        onClick={async () => {
                            await fetch("/api/products/stockready", {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ id: product.id, stockReady: product.stockReady - 1 }),
                            });
                        }}
                    >
                        -
                    </div>
                    <div
                        className="stock-ready-plus"
                        onClick={async () => {
                            await fetch("/api/products/stockready", {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({ id: product.id, stockReady: product.stockReady + 1 }),
                            });
                        }}
                    >
                        +
                    </div>
                </div>
            </span>
            <div className="admin-product-price">
                <small>R$ </small>
                {Math.round(product.price)}
            </div>
            <div className="admin-product-btns">
                <div
                    className="admin-product-btn"
                    onClick={() => {
                        props.setModalProduct(product);
                        props.setModalAdd(2);
                    }}
                >
                    Alterar
                </div>
                <div
                    className="admin-product-btn"
                    onClick={() => {
                        props.setModalProduct(product);
                        props.setModalAdd(3);
                    }}
                >
                    Duplicar
                </div>
            </div>
            <span
                className="trash"
                onClick={() => {
                    props.setModalProduct(props.product);
                    props.setModalConfirm(true);
                }}
            >
                <img src="/img/tampalixeira.svg" />
                <img src="/img/lixeira.svg" />
            </span>
            <div
                className="admin-product-check"
                onClick={() => {
                    const newSelected = props.selected.includes(product.id)
                        ? props.selected.filter((e) => e != product.id)
                        : [...props.selected, product.id];
                    props.setSelected(newSelected);
                }}
            >
                <div className={`admin-product-checked ${props.selected.includes(product.id) ? "checked" : ""}`}></div>
            </div>
        </div>
    );
}

export default ProductList;
